
let user = sessionStorage.getItem("currentSession")
  ? JSON.parse(sessionStorage.getItem("currentSession")).user
  : "";
let token = sessionStorage.getItem("currentSession")
  ? JSON.parse(sessionStorage.getItem("currentSession")).jwt
  : "";

export const initialState = {
  user: "" || user,
  token: "" || token,
  loading: false,
  errorMessage: null
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "LOGIN_SUCCESS":
      console.log("ACTION", action)
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.jwt,
        loading: false
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: "",
        token: ""
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};