import React, { useEffect, useState } from "react";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";
import LoadingSpinner from "./LoadingSpinner";

const WhatsAppTicketsModal = (props) => {
    const { booking, showWhatsAppTicketsModal, setShowWhatsAppTicketsModal } = props;
    const [phone, setPhone] = useState("");
    const [sendingWhatsAppStatus, setSendingWhatsAppStatus] = useState("none")

    useEffect(() => {
        if (!showWhatsAppTicketsModal) return
        console.log("WHATSAPP MODAL", booking)
        setPhone(booking.phone_number)
        setSendingWhatsAppStatus("none")
    }, [showWhatsAppTicketsModal]) // eslint-disable-line

    const closeWhatsAppModal = () => {
        setShowWhatsAppTicketsModal(false)
    }

    const sendWhatsApp = async (booking) => {
        setSendingWhatsAppStatus("loading");
        const url = `${process.env.REACT_APP_SEF_LIVE_URL}/api/wamsg/waboardingpass`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_SEF_LIVE_TOKEN}`,
            },
            body: JSON.stringify({
                bookingId: booking.id,
                phone: phone,
                lang: booking.lang,
                localizador: booking.localizador,
                first_name: booking.firstName,
            })
        });
        if (response.status === 200) setSendingWhatsAppStatus("success");
        else setSendingWhatsAppStatus("fail")
    };



    return (
        <Modal show={showWhatsAppTicketsModal} onHide={closeWhatsAppModal}>
            <Modal.Header closeButton>
                <Modal.Title>Enviar tarjetas de embarque por WhatsApp:</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="d-flex flex-wrap mb-2">
                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Teléfono">
                        <Form.Control
                            type="tel"
                            id="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </FloatingLabel>
                </div>
                {sendingWhatsAppStatus === "success" ? <p className="text-success">✔ WhatsApp enviado exitosamente.</p> : sendingWhatsAppStatus === "fail" ? <p className="text-danger">✖ No se ha podido enviar el WhatsApp.</p> : sendingWhatsAppStatus === "loading" ? <LoadingSpinner /> : ""}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={closeWhatsAppModal} variant="secondary">
                    Cerrar
                </Button>
                <Button variant="danger" disabled={setSendingWhatsAppStatus === "success"} onClick={() => sendWhatsApp(booking)}>
                    Enviar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default WhatsAppTicketsModal;