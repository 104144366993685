import React from "react";
import LoadingSpinner from "./LoadingSpinner";

const Notification = ({ status, successMsg, failMsg }) => {
    return (
        <>
            {status === "loading" ? <LoadingSpinner /> : status === "success" ? <p className="text-success">✔ {successMsg}!</p> : status === "fail" ? <p className="text-danger">✖ {failMsg}!</p> : ""}
        </>
    )
}

export default Notification