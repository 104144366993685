import Login from "../Pages/Login";
import Dashboard from "../Pages/Dashboard";
import NotFound from "../Pages/NotFound";
import Bookings from "../Pages/Bookings";
import Booking from "../Pages/Booking";
import Factura from "../Pages/Factura";
import Facturas from "../Pages/Facturas";
import Reservar from "../Pages/Reservar";
import Transactions from "../Pages/Transactions";
import Transaction from "../Pages/Transaccion";
import Liquidacion from "../Pages/Liquidacion";
import Liquidacions from "../Pages/Liquidacions";
import Settings from "../Pages/Settings";
import Reset from "../Pages/Reset";
import NewPassword from "../Pages/Reset/NewPassword";
import Register from "../Pages/Register";

const routes = [
  {
    title: "none",
    path: "/login",
    component: Login,
    isPrivate: false,
  },
  {
    title: "none",
    path: "/register",
    component: Register,
    isPrivate: false,
  },
  {
    title: "none",
    path: "/reset-password",
    component: Reset,
    isPrivate: false,
  },
  {
    title: "none",
    path: "/new-password",
    component: NewPassword,
    isPrivate: false,
  },
  {
    title: "Dashboard",
    path: "/",
    component: Dashboard,
    isPrivate: true,
  },
  {
    title: "Reservas",
    path: "/bookings",
    component: Bookings,
    isPrivate: true,
  },
  {
    title: "Reservar",
    path: "/reservar",
    component: Reservar,
    isPrivate: true,
  },
  {
    title: "Facturas",
    path: "/facturas",
    component: Facturas,
    isPrivate: true,
  },
  {
    title: "Transacciones",
    path: "/transactions",
    component: Transactions,
    isPrivate: true,
  },

  {
    title: "Liquidaciones",
    path: "/liquidacions",
    component: Liquidacions,
    isPrivate: true,
  },
  {
    title: "none",
    path: "/booking/:id",
    component: Booking,
    isPrivate: true,
  },
  {
    title: "none",
    path: "/factura/:id",
    component: Factura,
    isPrivate: true,
  },
  {
    title: "none",
    path: "/transaction/:id",
    component: Transaction,
    isPrivate: true,
  },
  {
    title: "none",
    path: "/liquidacion/:id",
    component: Liquidacion,
    isPrivate: true,
  },
  {
    title: "Settings",
    path: "/settings",
    component: Settings,
    isPrivate: true,
  },
  {
    title: "none",
    path: "/*",
    component: NotFound,
    isPrivate: true,
  },
];

export default routes;
