import React from "react";
import {
    Table,
    Button,
} from "react-bootstrap";
import { format } from "date-fns";


const LiquidacionsTable = ({ liquidacions, sortWay, toggleSortWay, currentPage, setCurrentPage, doShowModal, PER_PAGE }) => {
    return (
        <Table responsive>
            <thead>
                <tr>
                    <th>Vendor</th>
                    <th style={{ cursor: "pointer" }} onClick={toggleSortWay}>Emisión {sortWay === "DESC" ? "▼" : "▲"}</th>
                    <th>Facturado</th>
                    <th>Comisión</th>
                    <th>Liquidado</th>
                </tr>
            </thead>
            <tbody>
                {liquidacions.map((liquidacion, index) => {
                    if (index < PER_PAGE)
                        return (
                            <tr key={index} onClick={() => doShowModal(index)} style={{ cursor: "pointer" }}>
                                <td data-cell="Vendor">
                                    {liquidacion.user.username}
                                </td>
                                <td data-cell="Emisión">
                                    {format(
                                        new Date(liquidacion.createdAt),
                                        "dd-MM-yyyy HH:mm"
                                    )}
                                </td>
                                <td data-cell="Facturado">
                                    {liquidacion.total.toFixed(2)}€
                                </td>
                                <td data-cell="Comisión">
                                    {liquidacion.comision.toFixed(2)}€
                                </td>
                                <td  data-cell="Liquidado">
                                    {liquidacion.importe_final.toFixed(2)}€
                                </td>

                            </tr>
                        )
                    else return null
                })}
            </tbody>

            <tfoot>
                <tr>
                    <td colSpan={12} style={{ textAlign: "center" }}>
                        <Button
                            disabled={currentPage === 0}
                            onClick={() => setCurrentPage(value => value - 1)}
                        >
                            {"<"}
                        </Button>
                        <Button disabled variant="secondary" className="mx-1">
                            {`Pag. ${currentPage + 1}`}
                        </Button>
                        <Button
                            onClick={() => setCurrentPage(value => value + 1)}
                            disabled={liquidacions.length <= PER_PAGE}
                        >
                            {">"}
                        </Button>
                    </td>
                </tr>
            </tfoot>
        </Table>
    )
}

export default LiquidacionsTable;