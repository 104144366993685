import React from "react";
import {
    Table,
    Button,
} from "react-bootstrap";
import { format } from "date-fns";


const TransactionsTable = ({ transactions, sortWay, toggleSortWay, currentPage, setCurrentPage, doShowModal, PER_PAGE }) => {
    return (
        <Table responsive>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Status</th>
                    <th style={{ cursor: "pointer" }} onClick={toggleSortWay}>Fecha {sortWay === "DESC" ? "▼" : "▲"}</th>
                    <th>Tipo</th>
                </tr>
            </thead>
            <tbody>
                {transactions.map((transaction, index) => {
                    if (index < PER_PAGE)
                        return (
                            <tr key={index} onClick={() => doShowModal(index)} style={{ cursor: "pointer" }}>
                                <td data-cell="ID">
                                    {transaction.transaction_id}
                                </td>
                                <td data-cell="Status">
                                    {transaction.booking?.status}
                                </td>
                                <td data-cell="Fecha">
                                    {format(
                                        new Date(transaction.createdAt),
                                        "dd-MM-yyyy 🕓 HH:mm"
                                    )}
                                </td>
                                <td data-cell="Tipo">
                                    {transaction.type}
                                </td>

                            </tr>
                        )
                    else return null
                })}
            </tbody>

            <tfoot>
                <tr>
                    <td colSpan={12} style={{ textAlign: "center" }}>
                        <Button
                            disabled={currentPage === 0}
                            onClick={() => setCurrentPage(value => value - 1)}
                        >
                            {"<"}
                        </Button>
                        <Button disabled variant="secondary" className="mx-1">
                            {`Pag. ${currentPage + 1}`}
                        </Button>
                        <Button
                            onClick={() => setCurrentPage(value => value + 1)}
                            disabled={transactions.length <= PER_PAGE}
                        >
                            {">"}
                        </Button>
                    </td>
                </tr>
            </tfoot>
        </Table>
    )
}

export default TransactionsTable;