import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { FaArrowRight, FaArrowsAltH, FaQrcode, FaSignInAlt, FaUpload } from "react-icons/fa";
import { useQuery } from "react-query";
import { useAuthState } from "../Context";
import Bubutton from "./Bubutton";
import LoadingSpinner from "./LoadingSpinner";
import Notification from "./Notification";
import { MdRestartAlt } from "react-icons/md";

const ModificationModal = (props) => {
    const userDetails = useAuthState()
    const { booking, showModificationModal, doHideModificationModal } = props;
    const [enviarMail, setEnviarMail] = useState(true)
    const [modificationStatus, setModificationStatus] = useState({ status: "ready" })
    const [idayvuelta, setIdayvuelta] = useState("iyv")
    const [origin, setOrigin] = useState()
    const [destination, setDestination] = useState()
    const [ida, setIda] = useState()
    const [vuelta, setVuelta] = useState()
    const [isBono, setIsBono] = useState(false)
    const [bono, setBono] = useState()
    const [outboundDate, setOutboundDate] = useState()
    const [returnDate, setReturnDate] = useState()
    const [newIda, setNewIda] = useState()
    const [newVuelta, setNewVuelta] = useState()


    useEffect(() => {
        if (!showModificationModal) return
        setNewIda()
        setNewVuelta()
        setEnviarMail(false)
        setIdayvuelta(booking.idayvuelta)
        setIsBono(booking.is_bono)
        if (booking.is_bono) setBono(booking.bono)
        if (booking.idayvuelta === "iyv") {
            setOrigin(booking.ida.details.origin.code)
            setDestination(booking.ida.details.destination.code)
            setOutboundDate(booking.ida.date)
            setReturnDate(booking.vuelta.date)
            setIda(booking.ida)
            setVuelta(booking.vuelta)
        }
        if (booking.idayvuelta === "si") {
            setOrigin(booking.ida.details.origin.code)
            setDestination(booking.ida.details.destination.code)
            setOutboundDate(booking.ida.date)
            setIda(booking.ida)
        }
        setModificationStatus({ status: "ready" })
    }, [showModificationModal])  // eslint-disable-line

    const resetear = () => {
        if (!showModificationModal) return
        setNewIda()
        setNewVuelta()
        setEnviarMail(false)
        setIdayvuelta(booking.idayvuelta)
        setIsBono(booking.is_bono)
        if (booking.is_bono) setBono(booking.bono)
        if (booking.idayvuelta === "iyv") {
            setOrigin(booking.ida.details.origin.code)
            setDestination(booking.ida.details.destination.code)
            setOutboundDate(booking.ida.date)
            setReturnDate(booking.vuelta.date)
            setIda(booking.ida)
            setVuelta(booking.vuelta)
        }
        if (booking.idayvuelta === "si") {
            setOrigin(booking.ida.details.origin.code)
            setDestination(booking.ida.details.destination.code)
            setOutboundDate(booking.ida.date)
            setIda(booking.ida)
        }
        setModificationStatus({ status: "ready" })
    }


    const posTrips = useQuery(['postrips', origin, destination, outboundDate, returnDate], () => fetchTrips(), { enabled: showModificationModal })
    const fetchTrips = async () => {
        const formatedOutboundDate = outboundDate ? format(new Date(outboundDate), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')
        const formatedReturnDate = returnDate ? format(new Date(returnDate), 'yyyy-MM-dd') : outboundDate ? format(new Date(outboundDate), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')
        const url = `${process.env.REACT_APP_BACKEND_URL}/postrips?origin=${origin}&destination=${destination}&date=${formatedOutboundDate}&returndate=${formatedReturnDate}`
        console.log('ModificationModal: fetch pos trips url:', url)
        const { data } = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${userDetails.token}`
            }
        });
        return data;
    }


    const doModification = async () => {
        setModificationStatus({ status: "loading" });
        if (!newIda && !newVuelta) {
            setModificationStatus({ status: "fail", msg: "Debes seleccionar almmenos 1 viaje de ida o vuelta" })
            return
        }
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/modification`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify({
                    bookingID: booking.id,
                    newIda: newIda?.id,
                    newVuelta: newVuelta?.id,
                    enviarMail,
                }),
            }
        );
        try {
            const data = await response.json()
            if (response.status === 200) {
                setModificationStatus({ status: "success" })
                console.log("Success Refund Response", data)
            }
            else {
                setModificationStatus({ status: "fail", msg: data.errorCode ? data.errorCode : JSON.stringify(data).substring(0, 200) })
                console.log("Failed Refund Response", data.errorCode ? data.errorCode : JSON.stringify(data))
            }
        } catch (err) {
            setModificationStatus({ status: "fail", msg: "Wrong sever response" })
        }
    };

    if (!booking) return <></>

    return (
        <Modal show={showModificationModal} onHide={doHideModificationModal}>
            <Modal.Header closeButton>
                <Modal.Title>Modificar Reserva</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ButtonGroup className="p-1">
                    <Bubutton
                        style={{ width: "6em" }}
                        disabled
                        onClick={() => { setIdayvuelta("si"); setIsBono(false) }}
                        text="Solo Ida"
                        variant={!isBono && idayvuelta === "si" ? "success" : "secondary"}
                    >
                        <FaArrowRight />
                    </Bubutton>
                    <Bubutton
                        style={{ width: "6em" }}
                        disabled
                        onClick={() => { setIdayvuelta("iyv"); setIsBono(false) }}
                        text="Ida y vuelta"
                        variant={!isBono && idayvuelta === "iyv" ? "success" : "secondary"}
                    >
                        <FaArrowsAltH />
                    </Bubutton>
                    <Bubutton
                        style={{ width: "6em" }}
                        disabled
                        onClick={() => setIsBono(true)}
                        text="Bono"
                        variant={isBono ? "success" : "secondary"}
                    >
                        <FaQrcode />
                    </Bubutton>
                </ButtonGroup>
                {isBono &&
                    <>
                        <div className="border-top" />
                        <h6>Bonos:</h6>
                        <div className="mt-1 mb-1">
                            {posTrips.isSuccess && posTrips.data.bonos.map((bon, index) => {
                                return (
                                    <Button
                                        key={index}
                                        disabled
                                        className="me-1 mb-1"
                                        variant={bon.id === bono?.id ? "success" : "secondary"}
                                        onClick={() => { setBono(bon); setIsBono(true) }}>
                                        {bon.slug}
                                    </Button>
                                )
                            })}
                        </div>
                    </>}
                {(!isBono || bono?.formentera) &&
                    <>
                        <div className="border-top m-1" />
                        <div className="d-flex">
                            <div className="w-50 p-1">
                                <Form.Select
                                    size="lg"
                                    disabled={modificationStatus.status === "success"}
                                    style={{ marginBottom: "4px" }}
                                    value={origin}
                                    onChange={(e) => {
                                        setOrigin(e.target.value)
                                    }}
                                >
                                    {combinations.map((comb, index) => <option key={index} value={comb.code}>{comb.name}</option>)}
                                </Form.Select>
                                <Form.Control
                                    size="lg"
                                    disabled={modificationStatus.status === "success"}
                                    type="date"
                                    placeholder="yyyy-mm-dd"
                                    value={outboundDate ? outboundDate : 'dd/mm/yyyy'}
                                    onChange={(e) => setOutboundDate(e.target.value)}
                                />
                                <div className="pt-1 pb-1">
                                    {posTrips.isLoading && <LoadingSpinner />}
                                    {posTrips.isSuccess && posTrips.data.outboundTrips.map((trip, index) => {
                                        return (
                                            <Button
                                                key={index}
                                                disabled={modificationStatus.status === "success"}
                                                className="me-1 mb-1"
                                                variant={newIda?.id === trip.id ? "warning" : ida?.id === trip.id ? "success" : "secondary"}
                                                onClick={() => setNewIda(trip)}>
                                                {trip.details.open ? "OPEN" : trip.details.departure.substring(0, 5)}
                                            </Button>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className=" w-50 p-1">
                                <Form.Select
                                    size="lg"
                                    disabled={modificationStatus.status === "success"}
                                    style={{ marginBottom: "4px" }}
                                    value={destination}
                                    onChange={(e) => setDestination(e.target.value)}
                                >
                                    {combinations.map((comb, index) => <option key={index} value={comb.code}>{comb.name}</option>)}
                                </Form.Select>
                                {idayvuelta === "iyv" && <>
                                    <Form.Control
                                        size="lg"
                                        disabled={modificationStatus.status === "success"}
                                        type="date"
                                        placeholder="yyyy-mm-dd"
                                        value={returnDate ? returnDate : 'dd/mm/yyyy'}
                                        min={outboundDate}
                                        onChange={(e) => setReturnDate(e.target.value)}
                                    />
                                    <div className="mt-1 mb-1">
                                        {posTrips.isLoading && <LoadingSpinner />}
                                        {posTrips.isSuccess && posTrips.data.returnTrips.map((trip, index) => {
                                            return (
                                                <Button
                                                    key={index}
                                                    disabled={modificationStatus.status === "success"}
                                                    className="me-1 mb-1"
                                                    variant={newVuelta?.id === trip.id ? "warning" : vuelta?.id === trip.id ? "success" : "secondary"}
                                                    onClick={() => setNewVuelta(trip)}>
                                                    {trip.details.open ? "OPEN" : trip.details.departure.substring(0, 5)}
                                                </Button>
                                            )
                                        })}
                                    </div>
                                </>}
                            </div>
                        </div>
                    </>}
                <div className="m-1 border-top pt-1">
                    <input
                        type="checkbox"
                        disabled={modificationStatus.status === "success" || !booking.email}
                        id="sendMail"
                        name="sendMail"
                        onChange={() => setEnviarMail(value => !value)}
                        checked={enviarMail}
                    ></input>{" "}
                    <label htmlFor="sendMail">
                        Enviar e-mail con tarjetas de embarque actualizadas
                    </label>
                </div>
                <div className="m-1 border-top">
                    {ida ? <div>{`⛴ ${ida.details.origin.name} - ${ida.details.destination.name} @ ${format(new Date(ida.date), "dd MMM yy")} ${ida.details.open ? "OPEN" : ida.details.departure.substring(0, 5)}`} <br /></div> : ""}
                    {newIda ? <div className="ps-3 text-warning">{`↪ ${newIda.details.origin.name} - ${newIda.details.destination.name} @ ${format(new Date(newIda.date), "dd MMM yy")} ${newIda.details.open ? "OPEN" : newIda.details.departure.substring(0, 5)}`} <br /></div> : ""}
                    {vuelta ? <div>{`⛴ ${vuelta.details.origin.name} - ${vuelta.details.destination.name} @ ${format(new Date(vuelta.date), "dd MMM yy")} ${vuelta.details.open ? "OPEN" : vuelta.details.departure.substring(0, 5)}`} <br /></div> : ""}
                    {newVuelta ? <div className="ps-3 text-warning">{`↪ ${newVuelta.details.origin.name} - ${newVuelta.details.destination.name} @ ${format(new Date(newVuelta.date), "dd MMM yy")} ${newVuelta.details.open ? "OPEN" : newVuelta.details.departure.substring(0, 5)}`} <br /></div> : ""}

                </div>

                <Notification status={modificationStatus.status} successMsg="Modificación realizada con éxito" failMsg={`Modificación fallida: ${modificationStatus.msg}`} />

            </Modal.Body>

            <Modal.Footer>
                <Bubutton
                    variant="danger"
                    onClick={doModification}
                    disabled={modificationStatus.status !== "ready"}
                    text={`Modificar ${enviarMail ? "y enviar" : ""}`}
                >
                    <FaUpload />
                </Bubutton>

                <Bubutton
                    onClick={doHideModificationModal}
                    variant="secondary"
                    text="Cerrar">
                    <FaSignInAlt />
                </Bubutton>

                <Bubutton
                    onClick={resetear}
                    variant="secondary"
                    text="Reset">
                    <MdRestartAlt />
                </Bubutton>
            </Modal.Footer>
        </Modal >
    )
}

export default ModificationModal;

const combinations = [
    {
        name: "Santa Eulallia",
        code: "STA",
        destinations: [
            {
                name: "Formentera",
                code: "FOR"
            },
            {
                name: "Ibiza",
                code: "IBZ"
            },
            {
                name: "Es Canar",
                code: "ESC"
            },
            {
                name: "Cala Llonga",
                code: "CLL"
            },
            {
                name: "Cala Pada",
                code: "CPD"
            },
            {
                name: "Es Figueral",
                code: "FIG"
            },
        ]
    },
    {
        name: "Es Canar",
        code: "ESC",
        destinations: [
            {
                name: "Formentera",
                code: "FOR"
            },
            {
                name: "Ibiza",
                code: "IBZ"
            },
            {
                name: "Santa Eulalia",
                code: "STA"
            },
            {
                name: "Cala Llonga",
                code: "CLL"
            },
            {
                name: "Cala Pada",
                code: "CPD"
            },
        ]
    },
    {
        name: "Cala Pada",
        code: "CPD",
        destinations: [
            {
                name: "Formentera",
                code: "FOR"
            },
            {
                name: "Ibiza",
                code: "IBZ"
            },
            {
                name: "Santa Eulalia",
                code: "STA"
            },
            {
                name: "Es Canar",
                code: "ESC"
            },
            {
                name: "Cala Llonga",
                code: "CLL"
            },
        ]
    },
    {
        name: "Cala Llonga",
        code: "CLL",
        destinations: [
            {
                name: "Formentera",
                code: "FOR"
            },
            {
                name: "Ibiza",
                code: "IBZ"
            },
            {
                name: "Santa Eulalia",
                code: "STA"
            },
            {
                name: "Es Canar",
                code: "ESC"
            },
            {
                name: "Cala Pada",
                code: "CPD"
            },
        ]
    },
    {
        name: "Ibiza",
        code: "IBZ",
        destinations: [
            {
                name: "Cala Llonga",
                code: "CLL"
            },
            {
                name: "Santa Eulalia",
                code: "STA"
            },
            {
                name: "Es Canar",
                code: "ESC"
            },
            {
                name: "Cala Pada",
                code: "CPD"
            },
            {
                name: "Es Figueral",
                code: "FIG"
            },
        ]
    },
    {
        name: "Formentera",
        code: "FOR",
        destinations: [
            {
                name: "Cala Llonga",
                code: "CLL"
            },
            {
                name: "Santa Eulalia",
                code: "STA"
            },
            {
                name: "Es Canar",
                code: "ESC"
            },
            {
                name: "Cala Pada",
                code: "CPD"
            },
            {
                name: "Es Figueral",
                code: "FIG"
            },
        ]
    },
    {
        name: "Es Figueral",
        code: "FIG",
        destinations: [
            {
                name: "Cala Llonga",
                code: "CLL"
            },
            {
                name: "Santa Eulalia",
                code: "STA"
            },
            {
                name: "Ibiza",
                code: "IBZ"
            },
            {
                name: "Formentera",
                code: "FOR"
            },
        ]
    },
]