import React, { useEffect, useState } from "react";
import Menu from "../../Components/Menu";
import { useAuthState } from "../../Context";
import styles from "./transaccion.module.css";
import {
  InputGroup,
  FormControl,
  Button,
  FormLabel,
  Form,
  Row,
  Col,
  Accordion,
} from "react-bootstrap";
import axios from "axios";
import { useQuery } from "react-query";
import LoadingSpinner from "../../Components/LoadingSpinner";
import TransactionsTable from "../../Components/TransactionsTable";
import TransactionModal from "../../Components/TransactionModal";
import useDebounce from "../../hooks/useDebounce";
import useSearchParams from "../../hooks/useSearchParams";

const PER_PAGE = 50;

function Transactions(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [sortWay, setSortWay] = useState("DESC");
  const debouncedSortWay = useDebounce(sortWay, 200)
  const userDetails = useAuthState();
  const [transactionIndex, setTransactionIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)

  const { pushSearchParam, getParam } = useSearchParams()

  const formadepago = getParam("formadepago")
  const createdAt = getParam("createdAt")
  const debouncedCreatedAt = useDebounce(createdAt, 800)
  const transactionId = getParam("transactionId")
  const debouncedTransId = useDebounce(transactionId, 800)

  const fetchTransactions = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/transactions?_sort=createdAt:${debouncedSortWay}&_start=${currentPage * PER_PAGE}&_limit=${PER_PAGE + 1}${debouncedCreatedAt ? `&createdAt_gte=${debouncedCreatedAt}T00:00:00.000Z&createdAt_lte=${debouncedCreatedAt}T23:59:59.999` : ""}${formadepago ? `&type=${formadepago}` : ""}${debouncedTransId ? `&transaction_id_contains=${debouncedTransId}` : ""}`;
    console.log("fetch transaction", url)
    const { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
    console.log("TRANSACTION", data)
    return data
  }


  const transactions = useQuery(['transactions', debouncedCreatedAt, formadepago, debouncedTransId, debouncedSortWay, currentPage], fetchTransactions)

  useEffect(() => {
    setCurrentPage(0)
  }, [formadepago, debouncedCreatedAt, transactionId])


  const toggleSortWay = () => {
    setSortWay(value => value === "DESC" ? "ASC" : "DESC")
  }

  const doHideModal = () => {
    setTransactionIndex(0)
    setShowModal(false)
  }
  const doShowModal = (index) => {
    console.log("SHOW MODAL")
    setTransactionIndex(index)
    setShowModal(true)
  }

  return (
    <>
      {transactions.isSuccess && <TransactionModal
        transaction={transactions.data[transactionIndex]}
        showModal={showModal}
        doHideModal={doHideModal} />}
      <Menu>
        <div className="container">
          <h1>Transacciones</h1>
          <br />
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Filtros</Accordion.Header>
              <Accordion.Body>
                <div className={styles.filters}>
                  <Row>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="compra-desde-control">
                          Emisión:
                        </FormLabel>

                        <FormControl
                          type="date"
                          id="compra-desde-control"
                          placeholder="yyyy-mm-dd"
                          value={createdAt}
                          name="createdAt"
                          onChange={pushSearchParam}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="type-select">
                          Forma de Pago:
                        </FormLabel>
                        <Form.Select
                          id="type-select"
                          value={formadepago}
                          name="formadepago"
                          onChange={pushSearchParam}
                          aria-label="type"
                        >
                          <option value="">Todos</option>
                          <option value="paypal">Paypal</option>
                          <option value="redsys">Redsys</option>
                          <option value="comercia">Comercia</option>
                          <option value="sumup">SumUp</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="trans-id-input">
                          Transaction ID:
                        </FormLabel>
                        <FormControl
                          id="trans-id-input"
                          placeholder="Id Transaccion"
                          value={transactionId}
                          name="transactionId"
                          onChange={pushSearchParam}
                        />
                        <Button
                          variant="outline-secondary"
                          id="button-addon1"
                          name="transactionId"
                          value=""
                          onClick={pushSearchParam}
                        >
                          x
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <br />
          {transactions.isLoading && <LoadingSpinner />}
          {transactions.isSuccess && <TransactionsTable transactions={transactions.data} sortWay={sortWay} toggleSortWay={toggleSortWay} currentPage={currentPage} setCurrentPage={setCurrentPage} doShowModal={doShowModal} PER_PAGE={PER_PAGE} />}
        </div>
      </Menu >
    </>
  );
}

export default Transactions;
