import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import { useAuthState, usePrinterContext, useTpvContext } from "../../Context";
import { Button, Spinner, Row } from "react-bootstrap";
import styles from "./booking.module.css";
import { format } from "date-fns";
import QRCode from "qrcode.react";
import { useQuery } from "react-query";
import axios from "axios";
import Bubutton from "../../Components/Bubutton";
import { FaCalendarDay, FaFilePdf, FaMailBulk, FaPrint, FaRegMoneyBillAlt, FaWhatsapp, FaTimesCircle } from "react-icons/fa";
import EmailTicketsModal from "../../Components/EmailTicketsModal";
import CancelModal from "../../Components/CancelModal";
import RefundModal from "../../Components/RefundModal";
import ModificationModal from "../../Components/ModificationModal";
import { BiQrScan } from "react-icons/bi";
import WhatsAppTicketsModal from "../../Components/WhatsAppTicketsModal";

function Booking() {
  const [isLoading, setLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false)
  const [showWhatsAppTicketsModal, setShowWhatsAppTicketsModal] = useState(false)
  const [showModificationModal, setShowModificationModal] = useState(false)
  const userDetails = useAuthState();
  const { printer, printTicket, printerMode, btPrintTicket } = usePrinterContext()
  const { proxyPrintTickets } = useTpvContext()

  const id = useParams().id;

  const fetchBooking = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/bookings/${id}`;
    const { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    });
    console.log("Booking", data)
    return data
  };

  const booking = useQuery(['booking', id], fetchBooking)


  const doShowMailModal = () => {
    setShowEmailModal(true)
  }

  const doShowWhatsAppTicketsModal = () => {
    setShowWhatsAppTicketsModal(true)
  }

  const doShowCancelModal = () => {
    setShowCancelModal(true)
  }

  const doHideCancelModal = () => {
    setShowCancelModal(false)
    booking.refetch()
  }

  const doShowRefundModal = () => {
    setShowRefundModal(true)
  }

  const doHideRefundModal = () => {
    setShowRefundModal(false)
    booking.refetch()
  }

  const doShowModificationModal = () => {
    setShowModificationModal(true)
  }

  const doHideModificationModal = () => {
    setShowModificationModal(false)
    booking.refetch()
  }

  const printTickets = () => {
    if (!printerMode || !booking.isSuccess) return
    if (printerMode === "disabled") return
    if (printerMode === "tpv") proxyPrintTickets(booking.data)
    if (printerMode === "usb" && printer) printTicket(booking.data)
    if (printerMode === "bt") btPrintTicket(booking.data)
  }


  const confirmaResidente = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/confirmaresidente/${id}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    });
    console.log("confirma residente", response.status);
    setLoading(false);
    booking.refetch()
  };

  return (
    <Menu>
      {(booking.isLoading || isLoading) && (
        <div
          style={{
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            position: "fixed",
            overflow: "hidden",
            zIndex: "3000",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <Spinner
            animation="border"
            role="status"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
            }}
          ></Spinner>
        </div>
      )}
      {(booking.isSuccess && booking.data.status) && (
        <div className={styles.container}>
          <EmailTicketsModal
            booking={booking.data}
            showEmailModal={showEmailModal}
            setShowEmailModal={setShowEmailModal} />
          <CancelModal
            booking={booking.data}
            showCancelModal={showCancelModal}
            doHideCancelModal={doHideCancelModal} />
          <RefundModal
            booking={booking.data}
            showRefundModal={showRefundModal}
            doHideRefundModal={doHideRefundModal} />
          <ModificationModal
            booking={booking.data}
            showModificationModal={showModificationModal}
            doHideModificationModal={doHideModificationModal} />
          <WhatsAppTicketsModal
            booking={booking.data}
            showWhatsAppTicketsModal={showWhatsAppTicketsModal}
            setShowWhatsAppTicketsModal={setShowWhatsAppTicketsModal} />

          <h1>RESERVA {booking.data.localizador} <Button disabled style={{ marginLeft: "1em", marginTop: "-5px" }} variant={booking.data.status === "confirmed" ? "success" : booking.data.status === "modified" ? "info" : booking.data.status === "failed" ? "secondary" : booking.data.status === "cancelled" ? "warning" : booking.data.status === "refunded" ? "danger" : "light"}>{booking.data.status.charAt(0).toUpperCase() + booking.data.status.slice(1)}</Button></h1>
          <Row>
            <div className="border p-2 col-lg-3 col-md-4 col-sm-6">
              <h4>Detalle:</h4>
              <div>
                <b>Localizador: </b>
                {booking.data.localizador}
              </div>
              <div>
                <b>👤 Cliente: </b>
                {` ${booking.data.firstName} ${booking.data.lastName}`}
              </div>
              <div>
                <b>📧 e-mail: </b>
                <a href={`mailto:${booking.data.email}`}>{booking.data.email} </a>
              </div>
              <div>
                📱 <b>Teléfono: </b>
                {booking.data.phone_number && `📱 ${booking.data.phone_number}`}
              </div>
              <div>
                🌍 <b>País: </b>
                {booking.data.country}
              </div>
              <div>
                <b>Idioma: </b>
                {booking.data.lang}
              </div>
              <div>
                <b>Emisión: </b>
                <div className="mx-3">
                  {booking.data.createdAt &&
                    format(new Date(booking.data.createdAt), "📆 dd MMM yyyy")}
                  <br></br>
                  {booking.data.createdAt &&
                    format(new Date(booking.data.createdAt), "🕓 HH:mm:ss")}
                </div>
              </div>
              <div>
                <b>Adultos: </b>
                {booking.data.adults}
              </div>
              <div>
                <b>Niños: </b>
                {booking.data.children}
              </div>
              <div>
                <b>Bebés: </b>
                {booking.data.babies}
              </div>
              <div>
                <b>Residente: </b>
                {booking.data.residente ? "Sí" : "No"}
              </div>
              {booking.data.residente && (
                <div>
                  <b>Confirmado: </b>
                  {booking.data.residenteconfirmado ? "Sí" : "No"}{" "}
                  <Button
                    variant="success"
                    onClick={confirmaResidente}
                    disabled={booking.data.residenteconfirmado}
                  >
                    Confirmar
                  </Button>
                </div>
              )}
            </div>
            <div className="border p-2 col-lg-3 col-md-4 col-sm-6">
              <h4>Enlace de tarjeta móvil:</h4>
              <QRCode
                value={`${process.env.REACT_APP_BACKEND_URL}\\boardingpass\\${booking.data.id}?media=mobile`}
              />
            </div>
            <div className="border p-2 col-lg-3 col-md-4 col-sm-6">
              <h4>Pago:</h4>
              <div>
                <b>Tarifas: </b>
                <div className="mx-3">

                  <div>Tarifa Adulto: {booking.data.tarifa_adult}€</div>
                  <div>Tarifa Niño: {booking.data.tarifa_child}€</div>

                </div>
              </div>
              <div>
                <b>Importe: </b>
                {booking.data.total} €
              </div>
              <div>
                <b>Descuento: </b>
                {booking.data.discount}%
              </div>

              <div>
                {booking.data.facturas.length ? <b>Facturas:</b> : ""}
                <div className="mx-2">{booking.data.facturas.map((factura, index) => (
                  <div key={index}>
                    <Link to={`/factura/${factura.id}`}>
                      {factura.serie}
                      {factura.numero}
                    </Link>
                    {` ${factura.formadepago.formadepago} ${factura.importe}€ ${factura.discount ? `(${factura.discount}% descuento)` : ""}`}
                  </div>
                ))}
                </div>
              </div>
            </div>
            {booking.data.ida && (
              <div className="border p-2 col-lg-3 col-md-4 col-sm-6">
                <h4>🚢 Viaje de ida:</h4>
                <div>
                  <b>Trayecto: </b>
                  {booking.data.ida.details.origin.name} -{" "}
                  {booking.data.ida.details.destination.name}
                </div>
                <div>
                  <b>Fecha: </b>
                  {booking.data.ida.date}
                </div>
                {!booking.data.ida.details.open ? (
                  <div>
                    <b>Salida: </b>
                    {booking.data.ida.details.departure.substring(0, 5)}
                  </div>
                ) : (
                  <div>OPEN</div>
                )}
                {booking.data.ida?.details?.stop?.length ?
                  <ul>
                    {booking.data.ida.details.stop.map(stop => {
                      return (
                        <li>
                          {stop.shuttle ? stop.pickup_info["es"] :
                            stop.swap ? `Trasbordo en ${stop.place.name} a las ${stop.time.substring(0, 5)}` :
                              `Parada en ${stop.place.name} a las ${stop.time.substring(0, 5)}`}
                        </li>
                      )
                    })}
                  </ul> : ""}
                {booking.data.checkinida ? (
                  <div>
                    <br />
                    <div>
                      <BiQrScan /> <b>Check-In de Ida Realizado</b>
                    </div>
                    <div>
                      <b>Escaneado Por: </b>{" "}
                      {booking.data.checkinida.user.username}
                    </div>
                    <div>
                      <b>Fecha de escaneo: </b>{" "}
                      {format(
                        new Date(booking.data.checkinida.time),
                        "dd MMM yyyy"
                      )}
                    </div>
                    <div>
                      <b>Hora de escaneo: </b>{" "}
                      {format(
                        new Date(booking.data.checkinida.time),
                        "HH:mm"
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <BiQrScan /> <b>Check-In de Ida NO realizado</b>
                  </div>
                )}
              </div>
            )}
            {booking.data.vuelta && (
              <div className="border p-2 col-lg-3 col-md-4 col-sm-6">
                <h4>🚢 Viaje de regreso:</h4>
                <div>
                  <b>Trayecto: </b>
                  {booking.data.vuelta.details.origin.name} -{" "}
                  {booking.data.vuelta.details.destination.name}
                </div>
                <div>
                  <b>Fecha: </b>
                  {booking.data.vuelta.date}
                </div>
                {booking.data.vuelta.details.open ? (
                  <div>OPEN</div>
                ) : (
                  <div>
                    <b>Salida: </b>
                    {booking.data.vuelta.details.departure.substring(0, 5)}
                  </div>
                )}

                {booking.data.vuelta?.details?.stop?.length ?
                  <ul>
                    {booking.data.vuelta.details.stop.map(stop => {
                      return (
                        <li>
                          {stop.shuttle ? stop.pickup_info["es"] :
                            stop.swap ? `Trasbordo en ${stop.place.name} a las ${stop.time.substring(0, 5)}` :
                              `Parada en ${stop.place.name} a las ${stop.time.substring(0, 5)}`}
                        </li>
                      )
                    })}
                  </ul> : ""}
                {booking.data.checkinvuelta ? (
                  <div>
                    <br />
                    <div>
                      <BiQrScan /> <b>Check-In de Regreso Realizado</b>
                    </div>
                    <div>
                      <b>Escaneado Por: </b>{" "}
                      {booking.data.checkinvuelta.user.username}
                    </div>
                    <div>
                      <b>Fecha de escaneo: </b>{" "}
                      {format(
                        new Date(booking.data.checkinvuelta.time),
                        "dd MMM yyyy"
                      )}
                    </div>
                    <div>
                      <b>Hora de escaneo: </b>{" "}
                      {format(
                        new Date(booking.data.checkinvuelta.time),
                        "HH:mm"
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <BiQrScan /> <b>Check-In de Regreso NO realizado</b>
                  </div>
                )}
              </div>
            )}
            {booking.data.is_bono && (
              <div className="border p-2 col-lg-3 col-md-4 col-sm-6">
                <h4>🚢 BONO {booking.data.bono.slug}: </h4>
                <div>
                  <b>Tipo: </b>
                  {booking.data.bono.type === "PLY" ? "PLAYAS" : "PLAYAS + IBIZA"}
                  {booking.data.bono.formentera ? " + FORMENTERA" : ""}                </div>
                <div>
                  <b>Días: </b>
                  {booking.data.bono.days}
                </div>
                <div>
                  <br />
                  <b><BiQrScan /> Check-In: </b>
                  {booking.data.bonocheckin.length ?
                    <ul>
                      {booking.data.bonocheckin.map((bcheck, idx) => {
                        return (
                          <li key={idx}>{`${bcheck.user.username} a las ${format(new Date(bcheck.time), "HH:mm")} el ${format(new Date(bcheck.time), "dd MMM yy")}`}</li>
                        )
                      })}
                    </ul> : "Bono no escaneado"}
                </div>

              </div>
            )}


            <div className="border p-2 col-lg-6 col-md-8 col-sm-12 d-flex flex-wrap">
              <Bubutton
                className="m-1 w-hh h-80"
                disabled={booking.data.status !== "confirmed"}
                onClick={doShowMailModal}
                text="E-Mail"
              >
                <FaMailBulk />
              </Bubutton>
              <Bubutton
                className="m-1 w-hh h-80"
                disabled={booking.data.status !== "confirmed"}
                onClick={doShowWhatsAppTicketsModal}
                text="WhatsApp"
              >
                <FaWhatsapp />
              </Bubutton>
              <Bubutton
                className="m-1 w-hh h-80"
                disabled={booking.data.status !== "confirmed" && booking.data.status !== "cancelled" && booking.data.status !== "refunded"}
                onClick={doShowModificationModal}
                text="Modificar"
              >
                <FaCalendarDay />
              </Bubutton>

              <Bubutton
                className="m-1 w-hh h-80"
                disabled={booking.data.status !== "confirmed"}
                onClick={doShowCancelModal}
                text="Cancelar"
              >
                <FaTimesCircle />
              </Bubutton>
              <Bubutton
                className="m-1 w-hh h-80"
                disabled={booking.data.status !== "cancelled" && booking.data.status !== "confirmed"}
                onClick={doShowRefundModal}
                text="Devolución"
              >
                <FaRegMoneyBillAlt />
              </Bubutton>
              <Bubutton
                className="m-1 w-hh h-80 pt-3"
                disabled={booking.data.status !== "confirmed"}
                href={`${process.env.REACT_APP_BACKEND_URL}/boardingpass/${booking.data.id}?media=print`}
                text="Descargar"
              >
                <FaFilePdf />
              </Bubutton>
              <Bubutton
                className="m-1 w-hh h-80"
                variant="primary"
                disabled={booking.data.status !== "confirmed" || printerMode === "disabled"}
                onClick={printTickets}
                text="Imprimir">
                <FaPrint />
              </Bubutton>
            </div>
          </Row>

        </div>

      )}
    </Menu>

  );
}

export default Booking;