import { format } from "date-fns";
import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FaDesktop, FaFilePdf, FaPrint, FaSignOutAlt, FaBalanceScaleLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useAuthState, usePrinterContext, useTpvContext } from "../Context";
import Bubutton from "./Bubutton";

const LiquidacionModal = (props) => {
    const { liquidacion, showModal, doHideModal, refetch } = props
    const history = useHistory();
    const { printer, printLiquidacion, printerMode, btPrintLiquidacion } = usePrinterContext();
    const { proxyPrintLiquidacion } = useTpvContext()
    const userDetails = useAuthState();
    const [loading, setLoading] = useState(false);

    const doContabilizar = async () => {
        setLoading(true)
        await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/contabilizar/${liquidacion.id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userDetails.token}`,
                }
            }
        );
        refetch()
        setLoading(false)
    };

    const doDesContabilizar = async () => {
        setLoading(true)
        await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/descontabilizar/${liquidacion.id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userDetails.token}`,
                }
            }
        );
        refetch()
        setLoading(false)
    };

    const print = () => {
        if (!printerMode) return
        if (printerMode === "disabled") return
        if (printerMode === "tpv") proxyPrintLiquidacion(liquidacion)
        if (printerMode === "usb" && printer) printLiquidacion(liquidacion)
        if (printerMode === "bt") btPrintLiquidacion(liquidacion)
    }

    if (!liquidacion) return <></>
    return (
        <Modal show={showModal} onHide={doHideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Liquidacion {format(new Date(liquidacion.createdAt), "dd MMM yyyy")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                Emisión: 📆 {format(new Date(liquidacion.createdAt), "dd-MM-yyyy 🕓 HH:mm")} <br />
                Vendedor: {liquidacion.user.username}<br />
                Contabilizada: {liquidacion.contabilizada ? "Si" : "No"}<br />
                Total: {liquidacion.total.toFixed(2)}€ <br />
                Comisión: {liquidacion.user.porcentaje_comision}% <br />
                Importe Comisión: {liquidacion.comision.toFixed(2)}€ <br />
                Importe liquidado: {liquidacion.importe_final.toFixed(2)}€ <br />
                Facturas liquidadas: {liquidacion.facturas.length}<br />
                {liquidacion.subliquidaciones.length &&
                    <>
                        {liquidacion.subliquidaciones.map((subliquidacion, index) =>
                            <div key={index}>
                                <b>{subliquidacion.formadepago}: </b> {(subliquidacion.simple + subliquidacion.completa).toFixed(2)}€ {subliquidacion.rectificativa !== 0 ? `( Devoluciones: ${(subliquidacion.rectificativa).toFixed(2)}€ )` : ""} {subliquidacion.rectificativa !== 0 ? ` = ${(subliquidacion.simple + subliquidacion.completa + subliquidacion.rectificativa).toFixed(2)}€` : ""}
                            </div>)}
                    </>
                }
                <br />

            </Modal.Body>
            <Modal.Footer className="d-flex w-100">
                <Bubutton
                    className="w-20 h-80 pt-3"
                    variant="primary"
                    href={`${process.env.REACT_APP_BACKEND_URL}/printliquidacion/${liquidacion.id}`}
                    text="Descargar"
                >
                    <FaFilePdf />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    variant="primary"
                    onClick={() => history.push(`/liquidacion/${liquidacion.id}`)}
                    text="Detalle">
                    <FaDesktop />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    variant="primary"
                    disabled={printerMode === "disabled"}
                    onClick={print}
                    text="Imprimir">
                    <FaPrint />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    variant={liquidacion.contabilizada ? "danger" : "primary"}
                    disabled={userDetails.user.username !== "toni" || loading}
                    onClick={liquidacion.contabilizada ? doDesContabilizar : doContabilizar}
                    text="Account">
                    <FaBalanceScaleLeft hidden={loading} /><Spinner animation="border" size="sm" hidden={!loading} />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    variant="secondary"
                    onClick={doHideModal}
                    text="Cerrar">
                    <FaSignOutAlt />
                </Bubutton>
            </Modal.Footer>
        </Modal>
    )
}

export default LiquidacionModal;