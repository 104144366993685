import React, { useState, useEffect } from "react";
import Menu from "../../Components/Menu";
import { CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar, Legend } from "recharts";
import { useAuthState } from "../../Context";
import { Alert, Button, Col, Container, Form, FormControl, FormLabel, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import { format } from "date-fns";

function Dashboard() {
  const userDetails = useAuthState()
  const [fecha, setFecha] = useState();
  const [trips, setTrips] = useState();
  const [notification, setNotification] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [paxes, setPaxes] = useState("0")
  const [tripIde, setTripIde] = useState()
  const [loading, setLoading] = useState(false)
  const [formentera, setFormentera] = useState()
  const [ibiza, setIbiza] = useState()
  const [playas, setPlayas] = useState()

  useEffect(() => {
    setFecha(format(new Date(), "yyyy-MM-dd"));
  }, [])

  const fetchTrips = async () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BACKEND_URL}/trips?date=${fecha}&_limit=1000`;
    console.log("URL: ", url);
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    });
    const trips = await response.json();
    console.log("trips", trips)
    setTrips(trips);
    setLoading(false)
  };

  useEffect(() => {
    if (!fecha) return;
    fetchTrips()
  }, [fecha]) //eslint-disable-line

  useEffect(() => {
    if (!trips || !trips.length) return
    let auxTrips = trips.filter((t) => {
      return t.details.destination.code === "FOR"
    }).sort((a, b) => {
      if (a.details.departure < b.details.departure) return -1
      if (a.details.departure > b.details.departure) return 1
      return 0
    })
    auxTrips.push(...trips.filter((t) => {
      return t.details.origin.code === "FOR"
    }).sort((a, b) => {
      if (a.details.departure < b.details.departure) return -1
      if (a.details.departure > b.details.departure) return 1
      return 0
    }))
    console.log("formentera aux trips", auxTrips)
    setFormentera(auxTrips)
    auxTrips = trips.filter((t) => {
      return t.details.destination.code === "IBZ"
    }).sort((a, b) => {
      if (a.details.departure < b.details.departure) return -1
      if (a.details.departure > b.details.departure) return 1
      return 0
    })
    auxTrips.push(...trips.filter((t) => {
      return t.details.origin.code === "IBZ"
    }).sort((a, b) => {
      if (a.details.departure < b.details.departure) return -1
      if (a.details.departure > b.details.departure) return 1
      return 0
    }))
    setIbiza(auxTrips)
    auxTrips = trips.filter((t) => {
      return t.details.destination.code !== "IBZ" && t.details.origin.code !== "IBZ" && t.details.origin.code !== "FOR" && t.details.destination.code !== "FOR"
    }).sort((a, b) => {
      if (a.details.departure < b.details.departure) return -1
      if (a.details.departure > b.details.departure) return 1
      return 0
    })
    setPlayas(auxTrips)
  }, [trips])

  const handleAddPax = (tripi) => {
    setTripIde(tripi)
    setShowModal(true)
  }


  useEffect(() => {
    if (tripIde)
      console.log("selected trip id", tripIde.id)
  }, [tripIde])


  const pushPax = async (tripId, pax) => {
    if (paxes === "0") {
      handleClose()
      return;
    }
    setLoading(true)
    handleClose()
    const url = `${process.env.REACT_APP_BACKEND_URL}/pushTickets?id=${tripId}&pax=${pax}`;
    console.log("pushpax url", url)
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    }).then((res) => res.json()
    ).then((data) => {
      if (data.details) {
        console.log("success update")
        setNotification({ status: "success", message: `Añadidos ${pax} pax, Total ${data.pax}` })
      }
      if (!data.data) {
        if (data.output?.payload?.message) {
          setNotification({ status: "warning", message: `Error ${data.output?.payload?.message}` })
        }
      } else if (data.data.errors) {
        if (data.output?.payload?.message) {
          setNotification({ status: "warning", message: `Error ${data.output?.payload?.message}` })
        } else {
          setNotification({ status: "danger", message: `Error` })
        }
      }

      console.log("data", data)
    }).catch((err) => {
      console.log(err)
      setNotification({ status: "danger", message: `Error` })
    }).then(() => {
      setLoading(false)
      fetchTrips()
    })
  }

  const handleClose = () => setShowModal(false)


  return (
    <Menu>
      {loading && <div
        style={{
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          position: "fixed",
          overflow: "hidden",
          zIndex: "3000",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
      >
        <Spinner
          animation="border"
          role="status"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
          }}
        ></Spinner>
      </div>}
      <Container>
        {notification && <Alert variant={notification.status}>{notification.message}</Alert>}
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Añadir Pasajeros con ticket</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div>{`Viaje: ${tripIde ? `${tripIde.details.slug}` : ""}`}</div>
              <Form.Label htmlFor="pax">Añadir tickets:</Form.Label>
              <Form.Control id="pax" type="number" value={paxes} onChange={(e) => setPaxes(`${e.target.value}`)}></Form.Control>
              <div>{`Reservas + Tickets = ${tripIde ? tripIde.bookings + tripIde.ticket + parseInt(paxes) : ""}`}</div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              CANCELAR
            </Button>
            <Button variant="primary" onClick={() => pushPax(tripIde.id, paxes)}>
              AÑADIR TICKETS
            </Button>
          </Modal.Footer>
        </Modal>
        <h1 className="text-center mt-4 mb-4">Capacidades diarias</h1>
        <InputGroup className="mb-4">
          <FormLabel className="m-2" htmlFor="compra-form-control">
            Fecha:
          </FormLabel>

          <FormControl
            type="date"
            id="compra-form-control"
            placeholder="yyyy-mm-dd"
            aria-label="compra"
            aria-describedby="basic-addon1"
            value={fecha ? fecha : "dd/mm/yyyy"}
            onChange={(e) => setFecha(e.target.value)}
          />
        </InputGroup>
        <Row>
          <Col lg={6} md={12}>
            <h3>Formentera</h3>
            <ul>
              {formentera && formentera.map((trip) => {
                return (
                  <li key={trip.id}> {`${trip.details.origin.name} - ${trip.details.destination.name} @ ${trip.details.open ? "Open" : trip.details.departure.substring(0, 5)} ||| Reservas: ${trip.bookings} ||| Tickets: ${trip.ticket} ||| Capacidad: ${trip.pax} / ${trip.details.quota}`} <Button size="sm" variant="outline-success" onClick={() => handleAddPax(trip)}>Añadir Pax</Button></li>
                )
              })}
            </ul>
          </Col>
          <Col lg={6} md={12} className="overflow-auto">
            <div width="100%" height="100%">
              <BarChart className="mt-4" width={540} height={400} data={formentera ? formentera : []} >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={(t) => t.details.slug.substring(0, 10)} angle={-75} height={130} tickMargin={50} interval={0} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="bookings" stackId="a" fill="#8884d8" />
                <Bar dataKey="ticket" stackId="a" fill="#82ca9d" />
              </BarChart>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            <h3>Ibiza</h3>
            <ul>
              {ibiza && ibiza.map((trip) => {
                return (
                  <li key={trip.id}> {`${trip.details.origin.name} - ${trip.details.destination.name} @ ${trip.details.open ? "Open" : trip.details.departure.substring(0, 5)} ||| Reservas: ${trip.bookings} ||| Tickets: ${trip.ticket} ||| Capacidad: ${trip.pax} / ${trip.details.quota}`} <Button size="sm" variant="outline-success" onClick={() => handleAddPax(trip)}>Añadir Pax</Button></li>
                )
              })}
            </ul>
          </Col>
          <Col lg={6} md={12} className="overflow-auto">
            <BarChart className="mt-4" width={540} height={400} data={ibiza ? ibiza : []}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={(t) => t.details.slug.substring(0, 10)} angle={-75} height={130} tickMargin={50} interval={0} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="bookings" stackId="a" fill="#8884d8" />
              <Bar dataKey="ticket" stackId="a" fill="#82ca9d" />
            </BarChart>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            <h3>Playas</h3>
            <ul>
              {playas && playas.map((trip) => {
                return (
                  <li key={trip.id}> {`${trip.details.origin.name} - ${trip.details.destination.name} @ ${trip.details.open ? "Open" : trip.details.departure.substring(0, 5)} ||| Reservas: ${trip.bookings} ||| Tickets: ${trip.ticket} ||| Capacidad: ${trip.pax} / ${trip.details.quota}`} <Button size="sm" variant="outline-success" onClick={() => handleAddPax(trip)}>Añadir Pax</Button></li>
                )
              })}
            </ul>
          </Col>
          <Col lg={6} md={12} className="overflow-auto">
            <BarChart className="mt-4" width={540} height={400} data={playas}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={(t) => t.details.slug.substring(0, 10)} angle={-75} height={130} tickMargin={50} interval={0} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="bookings" stackId="a" fill="#8884d8" />
              <Bar dataKey="ticket" stackId="a" fill="#82ca9d" />
            </BarChart>
          </Col>
        </Row>
      </Container>
    </Menu >
  );
}

export default Dashboard;
