import { format } from "date-fns";
import React from "react";
import { Modal } from "react-bootstrap";
import { FaDesktop, FaSignOutAlt } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import Bubutton from "./Bubutton";

const TransactionModal = (props) => {
    const { transaction, showModal, doHideModal } = props
    const history = useHistory();

    if (!transaction) return <></>
    return (
        <Modal show={showModal} onHide={doHideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Transaction {transaction.type} {transaction.transaction_id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                Emisión: 📆 {format(new Date(transaction.createdAt), "dd-MM-yyyy 🕓 HH:mm")} <br />
                ID: {transaction.transaction_id} <br />
                Type: {transaction.type} <br />
                {transaction.factura && <>
                    Factura: <Link to={`/factura/${transaction.factura.id}`}>{transaction.factura.serie}-{addZeros(transaction.factura.numero)}</Link><br />
                </>}
                {transaction.booking && <>
                    Reserva: <Link to={`/booking/${transaction.booking.id}`}>Booking {transaction.booking.localizador}</Link><br />
                </>}
                <br />

            </Modal.Body>
            <Modal.Footer className="d-flex w-100">
                <Bubutton
                    className="m-1 w-20 h-80"
                    variant="primary"
                    onClick={() => history.push(`/transaction/${transaction.id}`)}
                    text="Detalles">
                    <FaDesktop />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    variant="secondary"
                    onClick={doHideModal}
                    text="Cerrar">
                    <FaSignOutAlt />
                </Bubutton>
            </Modal.Footer>
        </Modal>
    )
}

export default TransactionModal;

const addZeros = (num) => {
    const zeros = new Array((4 - `${num}`.length) + 1).join("0")
    return `${zeros}${num}`
}