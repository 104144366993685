import React from "react";
import styles from "./reset.module.css";
import { Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import logo from "./logo.svg"
import axios from "axios";

function Reset(props) {
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isError, setIsError] = React.useState(false);
  const { history } = props;

  const resetPassword = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get("email");
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth/forgot-password`;
    setLoading(true);
    setErrorMessage(null);
    axios
      .post(url, { email })
      .then((response) => {
        console.log(response);
        setIsError(false);
        setErrorMessage("Se ha enviado un correo electrónico con instrucciones para restablecer tu contraseña.");
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
        setErrorMessage("El correo electrónico no existe en nuestra base de datos.");
      })
      .finally(() => { setLoading(false); })
  }

  return (
    <div style={{ minHeight: "100vh" }} className="d-flex justify-content-center align-items-center border">

      {loading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            position: "fixed",
            overflow: "hidden",
            zIndex: "3000",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <Spinner
            animation="border"
            role="status"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
            }}
          ></Spinner>
        </div>
      )}
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={logo} style={{ width: "56%" }} alt="logo" />
        <Form onSubmit={resetPassword}>
          <h3 className="text-center mb-4">Recuperar contraseña</h3>
          <Form.Group >
            <FloatingLabel className="mb-3" label="Correo Electrónico">
              <Form.Control
                type="email"
                placeholder="Correo electrónico"
                id="email"
                name="email"
                disabled={loading}
              />
            </FloatingLabel>
          </Form.Group>
          {errorMessage ? <p className={isError ? styles.error : styles.success}>{errorMessage}</p> : null}

          <Button className="w-100" type="submit" disabled={loading}>
            Enviar correo de recuperación
          </Button>
          <Button className="w-100 mt-2" variant="light" onClick={() => history.push("/login")} disabled={loading}>Volver</Button>
        </Form>
      </div>
    </div>
  );
}

export default Reset;
