import "react-bootstrap-drawer/lib/style.css";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import {
  FaChartBar,
  FaFileInvoiceDollar,
  FaCashRegister,
  FaDatabase,
  FaShip,
  FaTimes
} from "react-icons/fa";
import { BsPlugFill, BsPlug, BsWifi } from "react-icons/bs"
import { GiWindsock } from "react-icons/gi";
import { FiSettings } from "react-icons/fi"
import { GiHamburgerMenu } from "react-icons/gi";
import { BiBluetooth, BiListPlus, BiLogOut, BiScan, BiUsb } from "react-icons/bi";
import { logout, useAuthDispatch, useAuthState } from "../../Context";
import { Link, useHistory } from "react-router-dom";
import Bubutton from "../Bubutton";
import logo from "./logo.svg"
import { format } from "date-fns";
import { es } from 'date-fns/locale';
import { usePrinterContext, useTpvContext } from "../../Context/context";
import axios from "axios";
import { useQuery } from "react-query";


const Menu = (props) => {
  const { printerMode, printer, printCharacteristic } = usePrinterContext();
  const { status, scanComercia } = useTpvContext()
  const history = useHistory()
  const doScan = async () => {
    const scanresult = await scanComercia()
    if (scanresult) {
      const scanresultarray = scanresult.split('-')
      if (scanresultarray?.[0] === "pass" || scanresultarray?.[0] === "bono" || scanresultarray?.[0] === "trip")
        history.push(`/booking/${scanresultarray[1]}`)
    }
  }

  const fetchNotificaciones = async () => {
    const url = `${process.env.REACT_APP_SEF_LIVE_URL}/api/notifications?locale=es`;
    const { data } = await axios.get(url);
    return data
  };

  const notifications = useQuery('booking', fetchNotificaciones, { refetchInterval: 10000 })


  return (
    <Container fluid>
      <Row>
        <Col
          xs={12}
          md={2}
          lg={2}
          className="bg-primary d-flex flex-column align-items-center"

        >
          <AppDrawer />
        </Col>
        <Col xs={12} md={10} lg={10} className="py-4" style={{ position: "relative" }}>
          {notifications.isSuccess && notifications.data?.data?.length > 0 &&
            <div style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              maxWidth: "65%",
              marginLeft: "1.5em",
              paddingInline: "1em",
              borderRadius: ".4em",
              backgroundColor: "#b59d04",
              marginBottom: "1em"

            }}>
              <GiWindsock size={32} color="white" />
              <marquee style={{ borderRadius: ".4em", padding: ".6em", color: "white" }}>

                {notifications.data.data.map((notification, index) => (
                  <span key={index}><b> {notification.attributes.title} {format(new Date(notification.attributes.date), "dd MMMM", { locale: es })}:</b> {notification.attributes.description} {format(new Date(notification.attributes.date), "dd MMMM", { locale: es })} &emsp;&emsp;</span>
                ))}
              </marquee>
            </div>}
          <div style={{ position: "absolute", top: 0, right: 0, margin: "2em 4em", fontSize: ".75em", display: "flex", justifyContent: "flex-end" }}>
            {status === "initialized" &&
              <div className="me-3">
                <Bubutton variant="light" onClick={doScan}><BiScan /></Bubutton>
              </div>}
            <div>
              <div>
                {status === "initialized" ? <BsPlugFill color="green" /> : status === "loading" ? <Spinner animation="grow" size="sm" /> : <BsPlug color="red" />} TPV
              </div>
              <div>
                {printerMode === "usb" ? <BiUsb color={printer ? "green" : "red"} /> : printerMode === "bt" ? <BiBluetooth color={printCharacteristic ? "green" : "red"} /> : printerMode === "tpv" ? <BsWifi color={status === "initialized" ? "green" : "red"} /> : <FaTimes color="red" />} PRINTER
              </div>
            </div>
          </div>
          {props.children}
        </Col>
      </Row>
    </Container >
  );
};

const AppDrawer = (props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAuthDispatch();
  const userDetails = useAuthState()
  const handleToggle = () => setOpen(value => !value);

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  useEffect(() => {
    const resizeListener = () => {
      if (getWidth() > 767) setOpen(true);
      else setOpen(false);
    };
    resizeListener();
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <>
      <div className="py-3 d-md-none">
        <GiHamburgerMenu className="text-white" size={45} onClick={handleToggle} />
      </div>
      <div className="pt-3 d-xl-block d-none">
        <img src={logo} className="text-white" style={{ width: "100%" }} alt="logotipo" />
      </div>
      <div className="pt-3 d-xl-none d-md-block d-none">
        <img src="./onas_white.png" className="text-white" style={{ width: "100%" }} alt="logotipo" />
      </div>
      <div style={open ? { display: "block", height: "100vh" } : { display: "none" }}>
        <hr style={{ color: "white" }} />
        <ul className="list-unstyled p-0 m-0 d-flex flex-column align-items-center">
          <li>
            <Bubutton
              as={Link}
              to="/reservar"
              text="Nueva Reserva"
            >
              <FaCashRegister />
            </Bubutton>
          </li>
          <li>
            <Bubutton
              as={Link}
              to="/bookings"
              text="Reservas"
            >
              <FaShip />
            </Bubutton>
          </li>
          <li>
            <Bubutton
              as={Link}
              to="/facturas"
              text="Facturas"
            >
              <FaFileInvoiceDollar />
            </Bubutton>
          </li>
          <li>
            <Bubutton
              as={Link}
              to="/liquidacions"
              text="Liquidaciones"
            >
              <BiListPlus size={24} />
            </Bubutton>
          </li>
          {userDetails.user.role.type !== "hotel" &&
            <>
              <li>
                <Bubutton
                  as={Link}
                  to="/transactions"
                  text="Transacciones"
                >
                  <FaDatabase />
                </Bubutton>
              </li>
              <li>
                <Bubutton
                  as={Link}
                  to="/"
                  text="Estadística"
                >
                  <FaChartBar />
                </Bubutton>
              </li>
            </>}
          <li>
            <Bubutton
              as={Link}
              to="/Settings"
              text="Configuración"
            >
              <FiSettings />
            </Bubutton>
          </li>
          <li>
            <Bubutton
              onClick={() => logout(dispatch)}
              text="Logout"
            >
              <BiLogOut />
            </Bubutton>
          </li>
        </ul>
        <hr style={{ color: "white" }} />
        <div style={{ color: "white", fontSize: ".8em", textAlign: "center", marginBlock: "2em" }}>
          Usuario: <br /> <span style={{ fontWeight: 760, textTransform: "capitalize" }}>{userDetails.user.username}</span>
        </div>
      </div>
    </>
  );
};

export default Menu;
