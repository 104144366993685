import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaCoins, FaPaypal, FaRegCreditCard, FaSignInAlt } from "react-icons/fa";
import { useAuthState, useTpvContext } from "../Context";
import Bubutton from "./Bubutton";
import ComerciaLoading from "./ComerciaLoading";
import LoadingSpinner from "./LoadingSpinner";
import redsys_logo from "./redsys-01.svg"

const RefundModal = (props) => {
    const userDetails = useAuthState()
    const { comerciaRefund } = useTpvContext()
    const { booking, showRefundModal, doHideRefundModal } = props;
    const [enviarMail, setEnviarMail] = useState(false)
    const [refundStatus, setRefundStatus] = useState({ status: "none" })
    const [refundAmount, setRefundAmount] = useState(0);
    const [formadepago, setFormadepago] = useState("")
    const [comerciaLoading, setComerciaLoading] = useState(false)

    useEffect(() => {
        if (!showRefundModal) return
        console.log("REFUND MODAL", booking)
        setRefundStatus({ status: "ready" })
        setEnviarMail(false)
        setRefundAmount(0)
        setFormadepago(booking.facturas?.[0]?.formadepago.formadepago || "invitacion")
    }, [showRefundModal])  // eslint-disable-line

    const cashRefund = () => {
        doRefund(`${process.env.REACT_APP_BACKEND_URL}/cashrefund`)
    }

    const webRefund = () => {
        switch (formadepago) {
            case "paypal":
                doRefund(`${process.env.REACT_APP_BACKEND_URL}/paypalrefund`)
                break;
            case "redsys":
                doRefund(`${process.env.REACT_APP_BACKEND_URL}/redsysrefund`)
                break;
            default:
                setRefundStatus({ status: "fail", msg: `Forma de pago ${formadepago} no acepta devoluciones.` })
        }
    }

    const hotelRefund = () => {
        doRefund(`${process.env.REACT_APP_BACKEND_URL}/hotelrefund`)
    }


    const doRefund = async (url) => {
        setRefundStatus({ status: "loading" });
        const response = await fetch(
            url,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify({
                    bookingID: booking.id,
                    amount: refundAmount,
                    enviarMail,
                }),
            }
        );
        try {
            const data = await response.json()
            if (response.status === 200) {
                setRefundStatus({ status: "success" })
                console.log("Success Refund Response", data)
            } else if (data?.errorCode) {
                setRefundStatus({ status: "fail", msg: data.errorCode })
                console.log("Failed Refund Response", data.errorCode)
            } else {
                throw new Error(data.message)
            }
        } catch (err) {
            setRefundStatus({ status: "fail", msg: err.message })
        }
    };

    const doComerciaRefund = async () => {
        const transactionId = booking?.facturas?.[0]?.transaction?.transaction_id
        if (!transactionId) {
            setRefundStatus({ status: "fail", msg: "No comercia transacction available" })
            return;
        }
        setComerciaLoading(true)
        const tpvResponse = await comerciaRefund(transactionId, refundAmount, `${booking.facturas?.[0]?.serie}${booking.facturas?.[0]?.numero}`, booking.localizador)
        if (!tpvResponse.ok) {
            setRefundStatus({ status: "fail", msg: tpvResponse.transaction.resultMessage })
            setComerciaLoading(false)
            return;
        }
        setComerciaLoading(false)
        try {
            setRefundStatus({ status: "loading" });
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/refund`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userDetails.token}`,
                    },
                    body: JSON.stringify({
                        bookingID: booking.id,
                        amount: refundAmount,
                        comerciatransaction: tpvResponse.transaction,
                        enviarMail,
                    }),
                }
            );
            const data = await response.json()
            if (response.status === 200) {
                setRefundStatus({ status: "success" })
                console.log("Success Refund Response", data)
            }
            else if (data?.errorCode) {
                setRefundStatus({ status: "fail", msg: data.errorCode })
                console.log("Failed Refund Response", data.errorCode)
            } else throw new Error("FAILED")
        } catch (err) {
            setRefundStatus({ status: "fail", msg: "Wrong sever response" })
        }
    }


    if (!booking) return <></>

    return (
        <Modal show={showRefundModal} onHide={doHideRefundModal}>
            <Modal.Header closeButton>
                <Modal.Title>DEVOLUCIÓN :: {formadepago.toUpperCase()}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Importe total de reserva: {booking?.total?.toFixed(2)} €</p>
                <label htmlFor="refund-amount-input">Importe a devolver: </label>
                <input
                    type="number"
                    className="form-control d-inline"
                    id="refund-amount-input"
                    value={refundAmount}
                    onChange={(e) => setRefundAmount(e.target.value)}
                ></input>
                <div>
                    <input
                        type="checkbox"
                        id="genloc"
                        name="genloc"
                        onChange={() => setEnviarMail(value => !value)}
                        checked={enviarMail}
                        disabled={!booking.email}
                    ></input>{" "}
                    <label htmlFor="genloc">
                        Enviar e-mail de devolución
                    </label>
                </div>
                {refundStatus.status === "loading" ? <LoadingSpinner /> : refundStatus.status === "success" ? <p className="text-success">✔ Devolución realizada con éxito!</p> : refundStatus.status === "fail" ? <p className="text-danger">{`✖ Devolución fallida: ${refundStatus.msg}`}</p> : ""}
                {comerciaLoading && <ComerciaLoading setLoading={setComerciaLoading} />}
            </Modal.Body>

            <Modal.Footer>
                {userDetails.user.role.type === "hotel" ?
                    <>{(formadepago !== "partner") ? <Bubutton
                        variant="danger"
                        onClick={formadepago === "comercia" ? doComerciaRefund : webRefund}
                        disabled={refundStatus.status !== "ready" || refundAmount <= 0 || refundAmount > booking.total}
                        text={`Emitir devolución ${enviarMail ? "y enviar" : ""}`}
                    >
                        {formadepago === "paypal" ? <FaPaypal /> : formadepago === "redsys" ? <img src={redsys_logo} alt="redsys logo" width={48} height={21} /> : <FaRegCreditCard />}
                    </Bubutton>
                        :
                        <Bubutton
                            variant="danger"
                            onClick={hotelRefund}
                            disabled={refundStatus.status !== "ready" || refundAmount <= 0 || refundAmount > booking.total}
                            text="Emitir devolución"
                        >
                            <FaCoins />
                        </Bubutton>}
                    </>
                    : <>
                        <Bubutton
                            variant="danger"
                            onClick={cashRefund}
                            disabled={refundStatus.status !== "ready" || refundAmount <= 0 || refundAmount > booking.total}
                            text={`Emitir devolución en efectivo ${enviarMail ? "y enviar" : ""}`}
                        >
                            <FaCoins />
                        </Bubutton>
                        {formadepago !== "efectivo" && <Bubutton
                            variant="danger"
                            onClick={formadepago === "comercia" ? doComerciaRefund : webRefund}
                            disabled={refundStatus.status !== "ready" || refundAmount <= 0 || refundAmount > booking.total}
                            text={`Emitir devolución ${enviarMail ? "y enviar" : ""}`}
                        >
                            {formadepago === "paypal" ? <FaPaypal /> : formadepago === "redsys" ? <img src={redsys_logo} alt="redsys logo" width={48} height={21} /> : <FaRegCreditCard />}
                        </Bubutton>}
                    </>
                }
                <Bubutton
                    onClick={doHideRefundModal}
                    variant="secondary"
                    text="Cerrar">
                    <FaSignInAlt />
                </Bubutton>
            </Modal.Footer>
        </Modal>
    )
}

export default RefundModal;

