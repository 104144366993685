import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useAuthState } from "../Context";
import LoadingSpinner from "./LoadingSpinner";

const CancelModal = (props) => {
    const userDetails = useAuthState()
    const { booking, showCancelModal, doHideCancelModal } = props;
    const [enviarMail, setEnviarMail] = useState(true)
    const [cancelStatus, setCancelStatus] = useState({ status: "none" })
    const [cancelMsg, setCancelMsg] = useState("")

    useEffect(() => {
        if (!showCancelModal) return
        console.log("CANCEL MODAL", booking)
        setCancelStatus({ status: "ready" })
        setCancelMsg(weatherMsg(booking.lang))
        setEnviarMail(false)
    }, [showCancelModal]) // eslint-disable-line

    const doCancell = async () => {
        setCancelStatus({ status: "loading" });
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/cancellation`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify({
                    bookingIds: booking.id,
                    message: enviarMail ? cancelMsg : undefined,
                    enviarMail,
                }),
            }
        );
        console.log(response)
        if (response.status === 200) setCancelStatus({ status: "success" })
        else setCancelStatus({ status: "fail", msg: response.statusText || "No se pudo completar" })
    };
    if (!booking) return <></>

    return (
        <Modal show={showCancelModal} onHide={doHideCancelModal}>
            <Modal.Header closeButton>
                <Modal.Title>CANCELAR RESERVA</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {booking.lang === "es" ? (
                    <p>Hola {booking.firstName},</p>
                ) : booking.lang === "de" ? (
                    <p>Halo {booking.firstName},</p>
                ) : booking.lang === "fr" ? (
                    <p>Bonjour {booking.firstName},</p>
                ) : booking.lang === "it" ? (
                    <p>Ciao {booking.firstName},</p>
                ) : (
                    <p>Hola {booking.firstName},</p>
                )}
                <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    value={cancelMsg}
                    onChange={(e) => setCancelMsg(e.target.value)}
                ></textarea>
                <hr />
                {booking.lang === "es" ? (
                    <p>
                        Gracias por escojernos,
                        <br />
                        Departamento de reservas,
                        <br />
                        Santa Eulalia Ferry
                    </p>
                ) : booking.lang === "de" ? (
                    <p>
                        Vielen Dank, dass Sie sich für uns entschieden haben,
                        <br />
                        Reservierungsabteilung,
                        <br />
                        Santa Eulalia Ferry
                    </p>
                ) : booking.lang === "fr" ? (
                    <p>
                        Merci de nous avoir choisis,
                        <br />
                        Service des réservations,
                        <br />
                        Santa Eulalia Ferry{" "}
                    </p>
                ) : booking.lang === "it" ? (
                    <p>
                        Grazie per averci scelto,
                        <br />
                        Ufficio prenotazioni,
                        <br />
                        Santa Eulalia Ferry{" "}
                    </p>
                ) : (
                    <p>
                        Thank you for choosing us,
                        <br />
                        Booking department,
                        <br />
                        Santa Eulalia Ferry{" "}
                    </p>
                )}
                <br />
                <div>
                    <input
                        type="checkbox"
                        id="genloc"
                        name="genloc"
                        onChange={() => setEnviarMail(value => !value)}
                        checked={enviarMail}
                        disabled={!booking.email}
                    ></input>{" "}
                    <label htmlFor="genloc">
                        Enviar e-mail de cancelación
                    </label>
                </div>
                {cancelStatus.status === "loading" ? <LoadingSpinner /> : cancelStatus.status === "success" ? <p className="text-success">✔ Cancelación realizada con éxito!</p> : cancelStatus.status === "fail" ? <p className="text-danger">{`✖ Cancelación fallida: ${cancelStatus.msg}`}</p> : ""}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={doHideCancelModal} variant="secondary">
                    Cerrar
                </Button>
                <Button variant="danger" onClick={doCancell} disabled={cancelStatus.status !== "ready"}>
                    {`Cancelar reserva${enviarMail ? "y enviar" : ""}`}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CancelModal;

const weatherMsg = (lang) => {
    switch (lang) {
        case "es":
            return "Lamentamos comunicarle que su reserva ha sido cancelada debido a condiciones meteorológicas adversas. Por favor, indiquenos si prefiere el reembolso de su compra o la modificación de su viaje para otras fechas.";
        case "ca":
            return "Lamentem comunicar-li que la reserva ha estat cancel·lada a causa de condicions meteorològiques adverses. Si us plau, indiqueu-nos si preferiu el reemborsament de la vostra compra o la modificació del vostre viatge per a altres dates.";
        case "de":
            return "Wir bedauern Ihnen mitteilen zu müssen, dass Ihre Reservierung aufgrund widriger Wetterbedingungen storniert wurde. Bitte teilen Sie uns mit, ob Sie eine Rückerstattung Ihres Kaufs oder eine Änderung Ihrer Reise für andere Daten bevorzugen.";
        case "fr":
            return "Nous avons le regret de vous informer que votre réservation a été annulée en raison de conditions météorologiques défavorables. Merci de nous indiquer si vous préférez un remboursement de votre achat ou une modification de votre voyage pour d'autres dates.";
        case "it":
            return "Siamo spiacenti di informarvi che la vostra prenotazione è stata cancellata a causa di condizioni meteorologiche avverse. Per favore dicci se preferisci un rimborso del tuo acquisto o una modifica del tuo viaggio per altre date.";
        default:
            return "We regret to inform you that your reservation has been canceled due to adverse weather conditions. Please tell us if you prefer a refund of your purchase or modification of your trip for other dates.";
    }
};