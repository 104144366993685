import React from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { BiBluetooth, BiUsb } from "react-icons/bi";
import { BsWifi } from "react-icons/bs";
import { FaCheck, FaTimes } from "react-icons/fa";
import Menu from "../../Components/Menu";
import { usePrinterContext, useTpvContext } from "../../Context";
import { combinations } from "../../Config/combinations"
import { useState } from "react";
import { useEffect } from "react";

function Settings() {
    const { setPrinterMode, printerMode, printerState } = usePrinterContext();
    const { ipAddress, setIpAddress, status, doInitialization, port, setPort, printTrans, setPrintTrans } = useTpvContext()
    const [origin, setOrigin] = useState(combinations[0])

    useEffect(() => {
        const defaultOrigin = localStorage.getItem("default-origin")
        if (defaultOrigin) setOrigin(JSON.parse(defaultOrigin))
    }, [])

    const handleChangeDefaultOrigin = (event) => {
        event.preventDefault()
        setOrigin(event.target.value)
        localStorage.setItem("default-origin", event.target.value)
    }

    const togglePrinterMode = (e) => {
        setPrinterMode(e.target.value)
    }

    return (
        <Menu>
            <Container>
                <h1>Configuración </h1>
                <Row>
                    <div className="border p-3 col-md-6 col-sm-12">
                        <h5>Impresora Térmica:</h5>
                        <div>
                            <input id="disabled-printer-checkbox" type="radio" group="printers" value="disabled" checked={printerMode === "disabled"} onChange={togglePrinterMode} />
                            <label htmlFor="disabled-printer-checkbox" className="ms-1"><FaTimes /> Impresora térmica desactivada</label>
                        </div>
                        <div>
                            <input id="usb-printer-checkbox" type="radio" group="printers" value="usb" checked={printerMode === "usb"} onChange={togglePrinterMode} />
                            <label htmlFor="usb-printer-checkbox" className="ms-1"><BiUsb /> Impresora USB</label>
                        </div>
                        <div>
                            <input id="bt-printer-checkbox" type="radio" group="printers" value="bt" checked={printerMode === "bt"} onChange={togglePrinterMode} />
                            <label htmlFor="bt-printer-checkbox" className="ms-1"><BiBluetooth /> Impresora Bluetooth</label>
                        </div>
                        <div>
                            <input id="tpv-printer-checkbox" type="radio" group="printers" value="tpv" checked={printerMode === "tpv"} disabled={status !== "initialized"} onChange={togglePrinterMode} />
                            <label htmlFor="tpv-printer-checkbox" className="ms-1"><BsWifi /> Impresora TPV</label>
                        </div>
                        <div className={["me-1", printerMode === "disabled" && "d-none"].join(' ')}>
                            <p>Impresora: {printerState}</p>
                        </div>
                    </div>
                    <div className="border p-3 col-md-6 col-sm-12">
                        <h5>Comercia Global Payments Proxy TPV:</h5>
                        <Row className="pb-1">
                            <label htmlFor="ip" className="col-4 text-end">IP: </label>{" "}
                            <input id="ip" type="text" value={ipAddress || ""} onChange={(e) => setIpAddress(e.target.value)} minLength="7" size="15" className="col-6" />
                        </Row>
                        <Row>
                            <label htmlFor="port" className="col-4 text-end">Port: </label>{" "}
                            <input id="port" type="text" value={port || ""} onChange={(e) => setPort(e.target.value)} minLength="7" size="5" className="col-4" />
                        </Row>
                        <Row className="my-1">
                            <div className="col-4 text-end">Status:</div>
                            <div className="col-6">{status === "initialized" ? <FaCheck color="green" /> : status === "loading" ? <Spinner animation="grow" size="sm" /> : <FaTimes color="red" />} {status}</div>
                        </Row>
                        <Row>
                            <div className="col-4" />
                            <button className="my-1 col-6" onClick={doInitialization}>Connect</button>
                        </Row>
                        <Row>
                            <label htmlFor="print" className="col-4 text-end">Print Trans.: </label>{" "}
                            <div className="col-4 d-flex align-items-center justify-content-start p-0" >
                                <input id="print" type="checkbox" checked={printTrans} onChange={(e) => setPrintTrans(val => !val)} />
                            </div>
                        </Row>
                    </div>

                </Row>
                <Row>

                    <Col sm={6} className="border">
                        <h5 className="py-3">Preferencias</h5>
                        <div style={{ fontSize: ".8em", color: "grey", fontWeight: 700 }}>Origen preferido:</div>
                        <Form.Select
                            size="lg"
                            style={{ marginBottom: "14px" }}
                            onChange={handleChangeDefaultOrigin}
                        >
                            {combinations.map((comb, index) => {
                                const combination = JSON.stringify(comb)
                                return (
                                    <option key={index} value={combination} selected={origin.code === comb.code}>{comb.name}</option>
                                )
                            })}
                        </Form.Select>
                    </Col>
                </Row>
            </Container>
        </Menu>
    );
}

export default Settings;

