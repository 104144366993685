import React, { useEffect, useState } from "react";
import Menu from "../../Components/Menu";
import { useAuthState } from "../../Context";
import {
  InputGroup,
  FormControl,
  FormLabel,
  Form,
  Row,
  Col,
  Accordion,
  Button,
  FormCheck
} from "react-bootstrap";
import axios from "axios";
import { useQuery } from "react-query";
import LoadingSpinner from "../../Components/LoadingSpinner";
import LiquidacionModal from "../../Components/LiquidacionModal";
import LiquidacionsTable from "../../Components/LiquidacionsTable";
import NewLiquidacionModal from "../../Components/NewLiquidacionModal";
import useDebounce from "../../hooks/useDebounce";
import useSearchParams from "../../hooks/useSearchParams";

const PER_PAGE = 50;

function Liquidacions() {
  const [currentPage, setCurrentPage] = useState(0);
  const [sortWay, setSortWay] = useState("DESC");
  const debouncedSortWay = useDebounce(sortWay, 200)
  const userDetails = useAuthState();
  const [liquidacionIndex, setLiquidacionIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [showNewLiquidacionModal, setShowNewLiquidacionModal] = useState(false)

  const { pushSearchParam, getParam } = useSearchParams()

  const contabilizadas = getParam("contabilizadas")
  const createdAt = getParam("createdAt")
  const debouncedCreatedAt = useDebounce(createdAt, 800)
  const vendedor = getParam("vendedor")


  const fetchTransactions = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/liquidacions?_sort=createdAt:${debouncedSortWay}&_start=${currentPage * PER_PAGE}&_limit=${PER_PAGE + 1}${debouncedCreatedAt ? `&createdAt_gte=${debouncedCreatedAt}T00:00:00.000Z&createdAt_lte=${debouncedCreatedAt}T23:59:59.999` : ""}${userDetails.user.role.type !== "authenticated" ? `&user=${userDetails.user.id}` : vendedor ? `&user=${vendedor}` : ""}${contabilizadas ? `&contabilizada=${contabilizadas}` : ""}`;
    console.log("fetch liquidacions", url)
    const { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
    console.log("liquidacions", data)
    return data
  }

  const liquidacions = useQuery(['liquidacions', debouncedCreatedAt, vendedor, debouncedSortWay, currentPage, contabilizadas], fetchTransactions)
  const users = useQuery('users', async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
    return data
  }, { enabled: userDetails.user.role.type === "authenticated" })

  useEffect(() => {
    setCurrentPage(0)
  }, [vendedor, debouncedCreatedAt, contabilizadas])


  const toggleSortWay = () => {
    setSortWay(value => value === "DESC" ? "ASC" : "DESC")
  }

  const doHideModal = () => {
    setLiquidacionIndex(0)
    setShowModal(false)
  }
  const doShowModal = (index) => {
    console.log("SHOW MODAL")
    setLiquidacionIndex(index)
    setShowModal(true)
  }

  const refetch = () => {
    liquidacions.refetch()
  }

  const doShowNewLiquidacionModal = () => {
    setShowNewLiquidacionModal(true)
  }

  const doHideNewLiquidacionModal = () => {
    setShowNewLiquidacionModal(false)
  }

  return (
    <>
      {liquidacions.isSuccess &&
        <>
          <LiquidacionModal
            liquidacion={liquidacions.data[liquidacionIndex]}
            showModal={showModal}
            refetch={refetch}
            doHideModal={doHideModal} />
          <NewLiquidacionModal
            showModal={showNewLiquidacionModal}
            doHideModal={doHideNewLiquidacionModal}
            refetch={refetch}
            users={users.isSuccess && users.data}
          />
        </>}
      <Menu>
        <div className="container">
          <div className="d-flex justify-content-between"><h1>Liquidaciones</h1>

          </div>
          <br />
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Filtros</Accordion.Header>
              <Accordion.Body>
                <div className="p-2">
                  <Row>
                    <Col md={3} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="compra-desde-control">
                          Emisión:
                        </FormLabel>

                        <FormControl
                          type="date"
                          id="compra-desde-control"
                          placeholder="yyyy-mm-dd"
                          value={createdAt}
                          name="createdAt"
                          onChange={pushSearchParam}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={3} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="vendedor-select">
                          Vendedor:
                        </FormLabel>
                        <Form.Select
                          id="vendedor-select"
                          name="vendedor"
                          onChange={pushSearchParam}
                          aria-label="Vendedor select"
                          disabled={userDetails.user.role.type !== "authenticated"}
                        >
                          {userDetails.user.role.type !== "authenticated" ?
                            <>
                              <option value={userDetails.user.id}>{userDetails.user.username}</option>
                            </>
                            :
                            <>
                              <option value="">Todos</option>
                              {users.isSuccess && users.data.map(user => {
                                return <option value={user.id} key={user.id}>{user.username}</option>
                              })}
                            </>}
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col md={3} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="contabilizadas_checkbox">
                          Contabilizadas:
                        </FormLabel>
                        <Form.Select
                          id="contabilizadas_checkbox"
                          name="contabilizadas"
                          onChange={pushSearchParam}
                          disabled={userDetails.user.username !== "toni"}
                        >
                          <option value="">Todas</option>
                          <option value="true">Contabilizadas</option>
                          <option value="false">No contabilizadas</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col md={2} sm={12}>
                      <Button
                        className="m-3"
                        onClick={doShowNewLiquidacionModal}>
                        Liquidación
                      </Button></Col>
                  </Row>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <br />
          {liquidacions.isLoading && <LoadingSpinner />}
          {liquidacions.isSuccess && <LiquidacionsTable liquidacions={liquidacions.data} sortWay={sortWay} toggleSortWay={toggleSortWay} currentPage={currentPage} setCurrentPage={setCurrentPage} doShowModal={doShowModal} PER_PAGE={PER_PAGE} />}
        </div>
      </Menu >
    </>
  );
}

export default Liquidacions;
