import React from "react";
import {
    Table,
    Button,
} from "react-bootstrap";
import { format } from "date-fns";


const FacturasTable = ({ facturas, sorting, sortWay, sortBy, currentPage, setCurrentPage, doShowModal, PER_PAGE }) => {
    return (
        <Table responsive>
            <thead>
                <tr>
                    <th>Número</th>
                    <th>Amount</th>
                    <th style={{ cursor: "pointer" }} onClick={() => sortBy("createdAt")}>Compra {sorting === "createdAt" ? sortWay === "DESC" ? "▼" : "▲" : ""}</th>
                    <th>F.Pago</th>
                    <th>Vendor</th>
                    <th>Ruta</th>
                    <th>👪</th>
                </tr>
            </thead>
            <tbody>
                {facturas.map((factura, index) => {
                    if (index < PER_PAGE)
                        return (
                            <tr key={index} onClick={() => doShowModal(index)} style={{ cursor: "pointer" }}>
                                <td data-cell="Status">
                                    <Button disabled variant={factura.importe > 0 ? "success" : "danger"} style={{ paddingBlock: "0px" }}>{`${factura.serie}-${addZeros(factura.numero)}`}</Button>
                                </td>
                                <td data-cell="Importe">
                                    {factura.importe}€
                                </td>
                                <td data-cell="Fecha">
                                    {format(
                                        new Date(factura.createdAt),
                                        "dd-MM-yyyy"
                                    )}
                                </td>
                                <td data-cell="F.Pago">
                                    {factura.formadepago.formadepago}
                                </td>
                                <td data-cell="Vendor" className="mail-data-cell">
                                    {factura.user ? factura.user.username : factura.booking?.email ? factura.booking.email : ""}
                                </td>
                                <td data-cell="Ruta">
                                    {factura.booking?.is_bono ? "BONO" : factura.booking?.ida ? `${factura.booking.ida.details.origin.code} - ${factura.booking.ida.details.destination.code}` : ""}
                                </td >
                                <td data-cell="Pax">{factura.booking ? factura.booking.adults + factura.booking.children + factura.booking.babies : ""}</td>
                            </tr>
                        )
                    else return null
                })}
            </tbody>

            <tfoot>
                <tr>
                    <td colSpan={12} style={{ textAlign: "center" }}>
                        <Button
                            disabled={currentPage === 0}
                            onClick={() => setCurrentPage(value => value - 1)}
                        >
                            {"<"}
                        </Button>
                        <Button disabled variant="secondary" className="mx-1">
                            {`Pag. ${currentPage + 1}`}
                        </Button>
                        <Button
                            onClick={() => setCurrentPage(value => value + 1)}
                            disabled={facturas.length <= PER_PAGE}
                        >
                            {">"}
                        </Button>
                    </td>
                </tr>
            </tfoot>
        </Table>
    )
}

export default FacturasTable;

const addZeros = (num) => {
    const zeros = new Array((4 - `${num}`.length) + 1).join("0")
    return `${zeros}${num}`
}