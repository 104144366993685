import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import routes from "./Config/routes.js";
import { AuthProvider, PrinterProvider } from "./Context";
import AppRoute from "./Components/AppRoute";
import { QueryClient, QueryClientProvider } from 'react-query'
import { TpvProvider } from "./Context/context.js";

const queryClient = new QueryClient()

function App() {
  return (
    <AuthProvider>
      <PrinterProvider>
        <TpvProvider>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Switch>
                {routes.map((route) => {
                  return (
                    <AppRoute
                      exact
                      key={route.path}
                      path={route.path}
                      component={route.component}
                      isPrivate={route.isPrivate}
                    />
                  );
                })}
              </Switch>
            </Router>
          </QueryClientProvider>
        </TpvProvider>
      </PrinterProvider>
    </AuthProvider>
  );
}

export default App;
