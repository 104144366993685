export async function loginUser(dispatch, loginPayload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(loginPayload),
  };

  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/local`, requestOptions);
    let data = await response.json();

    if (data.user) {
      if (data.user.confirmed === false) {
        dispatch({ type: 'LOGIN_ERROR', error: "Debes confirmar tu correo electrónico, si necesitas ayuda escribenos a info@santaeulaliaferry.com " });
        return;
      }
      console.log(data.user.role.type)
      if (data.user.role.type !== "authenticated" && data.user.role.type !== "hotel") {
        dispatch({ type: 'LOGIN_ERROR', error: "Tu cuenta debe ser verificada manualmente por el equipo de Santa Eulalia Ferry, si necesitas ayuda escribenos a info@santaeulaliaferry.com o llama al +34644723802" });
        return;
      }
      dispatch({ type: 'LOGIN_SUCCESS', payload: data });
      sessionStorage.setItem('currentSession', JSON.stringify(data));
      return data
    }

    dispatch({ type: 'LOGIN_ERROR', error: data.message[0].messages[0].message });
    return;
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: "Error inesperado" });
  }
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('currentUser');
  localStorage.removeItem('token');
}