import React, { useRef } from "react";
import styles from "./reset.module.css";
import { Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import logo from "./logo.svg"
import axios from "axios";

function NewPassword(props) {

  const { history } = props;
  const query = new URLSearchParams(props.location.search);
  const code = query.get('code')
  const performed = useRef(false);

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isError, setIsError] = React.useState(false);

  const doResetPassword = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const password = formData.get("password");
    const passwordConfirmation = formData.get("passwordConfirmation");
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth/reset-password`;
    setLoading(true);
    setErrorMessage(null);
    axios
      .post(url, { code, password, passwordConfirmation })
      .then((response) => {
        setIsError(false);
        setErrorMessage("Se ha restablecido tu contraseña correctamente. Puedes cerrar esta ventana.");
        performed.current = true;
        setLoading(false);
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage("Error: ", error);
        setLoading(false);
      });
  }

  if (!code) history.push("/login");

  return (
    <div style={{ minHeight: "100vh" }} className="d-flex justify-content-center align-items-center border">
      {loading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            position: "fixed",
            overflow: "hidden",
            zIndex: "3000",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <Spinner
            animation="border"
            role="status"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
            }}
          ></Spinner>
        </div>
      )}
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={logo} style={{ width: "56%" }} alt="logo" />
        <Form onSubmit={doResetPassword}>
          <Form.Group >
            <FloatingLabel className="mb-3" label="Nueva Contraseña">
              <Form.Control
                type="password"
                placeholder="Contraseña"
                id="password"
                name="password"
                disabled={loading}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group >
            <FloatingLabel className="mb-3" label="Repite nueva contraseña">
              <Form.Control
                type="password"
                placeholder="Contraseña"
                id="passwordConfirmation"
                name="passwordConfirmation"
                disabled={loading}
              />
            </FloatingLabel>
          </Form.Group>
          {errorMessage ? <p className={isError ? styles.error : styles.success}>{errorMessage}</p> : null}

          <Button className="w-100" type="submit" disabled={loading || performed.current}>
            Restablecer Contraseña
          </Button>
          <Button className="w-100 mt-2" variant="light" onClick={() => history.push("/login")} disabled={loading}>Volver</Button>
        </Form>
      </div>
    </div>
  );
}

export default NewPassword;
