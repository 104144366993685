import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingSpinner = () => {
    return (
        <div style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: "fixed",
            overflow: "hidden",
            zIndex: "3000",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}>
            <Spinner
                animation="border"
                role="status"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                }}
            ></Spinner>
        </div>
    )
}

export default LoadingSpinner;