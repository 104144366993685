import React, { useEffect, useState } from "react";
import { Form, FormLabel, InputGroup, Modal, Spinner } from "react-bootstrap";
import { BiSkipNextCircle } from "react-icons/bi";
import { FaSignOutAlt } from "react-icons/fa";
import { useAuthState, usePrinterContext, useTpvContext } from "../Context";
import Bubutton from "./Bubutton";
import LoadingSpinner from "./LoadingSpinner";

const NewLiquidacionModal = (props) => {
    const { showModal, doHideModal, refetch, users } = props
    const userDetails = useAuthState();
    const [preLiquidacion, setPreLiquidacion] = useState()
    const [vendor, setVendedor] = useState()
    const [preLiquidacionStatus, setPreLiquidacionStatus] = useState({ status: "ready" })
    const [liquidacionStatus, setLiquidacionStatus] = useState({ status: "ready" })
    const { printer, printLiquidacion, printerMode, btPrintLiquidacion } = usePrinterContext();
    const { proxyPrintLiquidacion } = useTpvContext()

    const print = async (data) => {
        if (!printerMode) return
        if (printerMode === "disabled") return
        if (printerMode === "tpv") await proxyPrintLiquidacion(data)
        if (printerMode === "usb" && printer) await printLiquidacion(data)
        if (printerMode === "bt") btPrintLiquidacion(data)
    }

    useEffect(() => {
        console.log("showmodal setvendor", showModal, userDetails.user.id)
        if (showModal) setVendedor(userDetails.user)
    }, [showModal]) //eslint-disable-line

    useEffect(() => {
        if (!showModal) return
        fetchPreLiquidacion()
    }, [vendor]) //eslint-disable-line

    const fetchPreLiquidacion = async () => {
        if (!vendor) return
        setPreLiquidacionStatus({ status: "loading" })
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/preliquidacion?user=${userDetails.user.role.type === "authenticated" ? vendor.id : userDetails.user.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userDetails.token}`,
                }
            });
            if (response.status === 200) {
                const data = await response.json()
                console.log("Preliquidacion", data)
                setPreLiquidacion(data)
                setPreLiquidacionStatus({ status: "success" })
            } else {
                const error = await response.json()
                console.log("Preliquidacion error", error.errorCode)
                setPreLiquidacion(undefined)
                setPreLiquidacionStatus({ status: "error", msg: error.errorCode })
            }

        } catch (err) {
            console.log("Preliquidacion ERROR", err)
            setPreLiquidacion(undefined)
            setPreLiquidacionStatus({ status: "error", msg: err })
        }
    }

    const liquidacion = async () => {
        setLiquidacionStatus({ status: "loading" })
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/liquidacion`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userDetails.token}`,
                },
                body: JSON.stringify({
                    user: vendor.id
                })
            });
            if (response.status === 200) {
                const data = await response.json()
                console.log("Liquidacion", data)
                setLiquidacionStatus({ status: "success" })
                await print(data)
                fetchPreLiquidacion()
                refetch()
            } else {
                const error = await response.json()
                console.log("Liquidacions error", error.errorCode)
                setLiquidacionStatus({ status: "error", msg: error.errorCode })
            }
        } catch (err) {
            console.log("Liquidacion ERROR", err)
            setLiquidacionStatus({ status: "error", msg: err })
        }
    }

    return (
        <Modal show={showModal} onHide={doHideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Emitir Nueva Liquidacion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="mb-4">
                    <FormLabel
                        className="mx-2 my-2"
                        htmlFor="vendedor-select">
                        Vendedor:
                    </FormLabel>
                    <Form.Select
                        disabled={userDetails.user.role.type !== "authenticated"}
                        id="vendedor-select"
                        onChange={(e) => setVendedor(JSON.parse(e.target.value))}
                    >
                        {userDetails.user.role.type !== "authenticated" ?
                            <option value={userDetails.user.id} key={userDetails.user.id} selected>{userDetails.user.username}</option> // eslint-disable-line
                            :
                            <>
                                {users && users.map(user => {
                                    return <option value={JSON.stringify(user)} key={user.id} selected={user.id === vendor?.id}>{user.username}</option> // eslint-disable-line
                                })}
                            </>
                        }
                    </Form.Select>
                </InputGroup>
                {preLiquidacionStatus.status === "error" && <div className="text-danger">{preLiquidacionStatus.msg}</div>}
                {preLiquidacionStatus.status === "loading" && <LoadingSpinner />}
                {preLiquidacion && <>
                    {preLiquidacion.subliquidaciones.length &&
                        <>
                            {preLiquidacion.subliquidaciones.map((subliquidacion, index) =>
                                <div key={index}>
                                    <b>{subliquidacion.formadepago}: </b> {(subliquidacion.simple + subliquidacion.completa).toFixed(2)}€ {subliquidacion.rectificativa !== 0 ? `( Devoluciones: ${(subliquidacion.rectificativa).toFixed(2)}€ )` : ""} {subliquidacion.rectificativa !== 0 ? ` = ${(subliquidacion.simple + subliquidacion.completa + subliquidacion.rectificativa).toFixed(2)}€` : ""}
                                </div>)}
                        </>
                    }
                    Total: {preLiquidacion.total.toFixed(2)}€ <br />
                    Comisión: {vendor.porcentaje_comision}% <br />
                    Importe Comisión: {preLiquidacion.comision.toFixed(2)}€ <br />
                    Importe a liquidar: {preLiquidacion.importe_final.toFixed(2)}€ <br />
                    Facturas a liquidadar: {preLiquidacion.facturas.length}<br />
                    {liquidacionStatus.status === "success" ? <div className="text-success">✔ Liquidación emitida exitosamente!</div> : liquidacionStatus.status === "error" ? <div className="text-danger">✖ Error en la liquidación</div> : <></>}
                </>}
            </Modal.Body>
            <Modal.Footer className="d-flex w-100">
                <Bubutton
                    className="m-1 w-20 h-80"
                    variant="primary"
                    disabled={preLiquidacionStatus.status !== "success" || userDetails.user.role.type === "hotel"}
                    onClick={() => liquidacion()}
                    text="Emitir">
                    {liquidacionStatus.status === "loading" ? <Spinner animation="border" size="sm" /> : <BiSkipNextCircle size={23} />}
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    disabled={liquidacionStatus.status === "loading"}
                    variant="secondary"
                    onClick={doHideModal}
                    text="Close">
                    <FaSignOutAlt />
                </Bubutton>
            </Modal.Footer>
        </Modal >
    )
}

export default NewLiquidacionModal;