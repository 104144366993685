import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Menu from "../../Components/Menu";
import { useAuthState, usePrinterContext, useTpvContext } from "../../Context";
import {
  Accordion,
  Button,
  ButtonGroup,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import styles from "./reservar.module.css";
import { format } from "date-fns";
import QRCode from "qrcode.react";
import { GoCreditCard } from "react-icons/go";
import { RiVipLine } from "react-icons/ri";
import { GiIsland, GiThreeFriends } from "react-icons/gi";
import { FaArrowRight, FaArrowsAltH, FaCoins, FaMailBulk, FaQrcode, FaSms, FaStop, FaTractor, FaTrashAlt, FaWhatsapp } from "react-icons/fa";
import { RiShoppingCart2Fill, RiSendPlaneFill } from "react-icons/ri";
import { MdElderly } from "react-icons/md";
import { useQuery } from 'react-query'
import axios from "axios";
import Bubutton from "../../Components/Bubutton";
import EmailTicketsModal from "../../Components/EmailTicketsModal";
import CancelModal from "../../Components/CancelModal";
import RefundModal from "../../Components/RefundModal";
import ModificationModal from "../../Components/ModificationModal";
import BookingModal from "../../Components/BookingModal";
import ComerciaLoading from "../../Components/ComerciaLoading";
import useDebounce from "../../hooks/useDebounce";
import WhatsAppTicketsModal from "../../Components/WhatsAppTicketsModal";
import { combinations } from "../../Config/combinations"

function Reservar() {
  const [showModal, setShowModal] = useState(false);
  const [showHotelEmitModal, setHotelEmitShowModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false)
  const [bookingStatus, setBookingStatus] = useState({ status: "pending" })
  const [emitError, setEmitError] = useState({ error: undefined })
  const [origin, setOrigin] = useState(combinations[0].code)
  const [destination, setDestination] = useState(combinations[0].destinations[0].code)
  const [destinations, setDestinations] = useState(combinations[0].destinations)
  const [bono, setBono] = useState()
  const [fechaIda, setFechaIda] = useState(format(new Date(), "yyyy-MM-dd"));
  const debouncedFechaIda = useDebounce(fechaIda, 1000)
  const [ida, setIda] = useState();
  const [fechaRegreso, setFechaRegreso] = useState(format(new Date(), "yyyy-MM-dd"));
  const debouncedFechaRegreso = useDebounce(fechaRegreso, 1000)
  const [regreso, setRegreso] = useState();
  const [idayvuelta, setIdayvuelta] = useState("iyv")
  const [formadepago, setFormadepago] = useState("efectivo")
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [babies, setBabies] = useState(0);
  const [discount, setDiscount] = useState();
  const [totales, setTotales] = useState()
  const [residente, setResidente] = useState(false);
  const [enviarMail, setEnviarMail] = useState(false);
  const [generarLoc, setGenerarLoc] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sendWA, setSendWA] = useState(false)
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [localizador, setLocalizador] = useState("");
  const [lang, setLang] = useState("en");
  const [generaFactura, setGeneraFactura] = useState(true);
  const [facturacompleta, setFacturacompleta] = useState(false);
  const [razonFiscal, setRazonFiscal] = useState("");
  const [cif, setCif] = useState("");
  const [direccion, setDireccion] = useState("");
  const [poblacion, setPoblacion] = useState("");
  const [cp, setCp] = useState("");
  const [provincia, setProvincia] = useState("");
  const [pais, setPais] = useState("");
  const [booking, setBooking] = useState();
  const [showResultModal, setShowResultModal] = useState(false);
  const { printer, printTicket, printFactura, printerMode, btPrintTicket, btPrintFactura } = usePrinterContext();
  const { emitirComercia, proxyPrintTickets, proxyPrintFactura, comerciaLastTrans } = useTpvContext()
  const [comerciaLoading, setComerciaLoading] = useState(false)
  const userDetails = useAuthState();
  let history = useHistory();
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showWhatsAppTicketsModal, setShowWhatsAppTicketsModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false)
  const [showModificationModal, setShowModificationModal] = useState(false)
  const [senior, setSenior] = useState(false)

  useEffect(() => {
    console.log(ida?.details.tarifa?.return_adult)
    if (!ida) {
      setEmitError({ error: "ida", msg: "Selecciona primero un viaje." })
      setSenior(false)
      setDiscount(0)
      return
    }
    if (senior && ida?.details.destination.code === "FOR" && idayvuelta === "iyv") setDiscount(28.57)
    else {
      setEmitError({ error: "ida", msg: "Solo aplicable a Formentera Ida y Vuelta mismo día." })
      setSenior(false)
      setDiscount(0)
    }
  }, [senior])

  const posTrips = useQuery(['postrips', origin, destination, debouncedFechaIda, debouncedFechaRegreso], () => fetchTrips())
  const fetchTrips = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/postrips?origin=${origin}&destination=${destination}&date=${format(new Date(debouncedFechaIda), 'yyyy-MM-dd')}&returndate=${format(new Date(debouncedFechaRegreso), 'yyyy-MM-dd')}`
    console.log('fetch pos trips url:', url)
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${userDetails.token}`
      }
    });
    return data;
  }

  useEffect(() => {
    setIda(undefined)
    setRegreso(undefined)
  }, [bono, origin, destination])

  useEffect(() => {
    if (senior) setSenior(false)
  }, [ida, bono])

  useEffect(() => {
    setIda(undefined)
    setRegreso(undefined)
    setBono(undefined)
    if (senior) setSenior(false)
    if (idayvuelta === "si" && userDetails.user.role.type === "hotel" && destination === "FOR") {
      const destino = destinations.find(dest => dest.code !== "FOR").code
      console.log("DESTINO", destino)
      setDestination(destino)
    }
    if (idayvuelta === "bono") setDestination("FOR")

  }, [idayvuelta])


  useEffect(() => {
    setEmitError({ error: undefined })
    if (idayvuelta !== "bono" && !ida) return
    if (idayvuelta === "bono" && !bono) return
    const t = totalizacion()
    console.log('Totales: ', t)
    setTotales(t)
  }, [ida, idayvuelta, adults, children, residente, discount, bono]) //eslint-disable-line

  const totalizacion = () => {
    let tarifa_adult
    let tarifa_child
    if (idayvuelta === "bono") {
      tarifa_adult = bono.tarifa.return_adult
      tarifa_child = bono.tarifa.return_child
    } else {
      tarifa_adult = idayvuelta === "iyv" ? residente ? ida.details.tarifa.res_return_adult : ida.details.tarifa.return_adult : residente ? ida.details.tarifa.res_oneway_adult : ida.details.tarifa.oneway_adult
      tarifa_child = idayvuelta === "iyv" ? residente ? ida.details.tarifa.res_return_child : ida.details.tarifa.return_child : residente ? ida.details.tarifa.res_oneway_child : ida.details.tarifa.oneway_child
    }
    const total_adult = tarifa_adult * adults
    const total_child = tarifa_child * children
    const subtotal = total_adult + total_child
    const total_discount = subtotal * parseFloat(discount || 0) / 100
    const total = subtotal - total_discount
    return {
      total_adult,
      total_child,
      total_discount,
      tarifa_adult,
      tarifa_child,
      total
    }
  }

  const setDefaultOrigin = () => {
    const defaultOrigin = localStorage.getItem("default-origin")
    if (defaultOrigin) {
      console.log("DEF ORIGIN", defaultOrigin)
      setOrigin(JSON.parse(defaultOrigin).code)
      setDestinations(JSON.parse(defaultOrigin).destinations)
      setDestination(JSON.parse(defaultOrigin).destinations[0].code)
    } else {
      setOrigin(combinations[0].code)
      setDestinations(combinations[0].destinations)
      setDestination(combinations[0].destinations[0].code)
    }
  }

  useEffect(() => {
    setDestination(destinations[0].code)
  }, [destinations])


  useEffect(() => {
    setDefaultOrigin()
    if (senior) setSenior(false)
    setDiscount(userDetails.user.discount)
    setEmitError({ error: undefined })
    if (ida) {
      setFechaIda(format(new Date(ida.date), "yyyy-MM-dd"));
      setOrigin(ida.details.origin.code)
      setDestination(ida.details.destination.code)
    } else setFechaIda(format(new Date(), "yyyy-MM-dd"));
    if (regreso) setFechaRegreso(format(new Date(regreso.date), "yyyy-MM-dd"));
    else setFechaRegreso(format(new Date(), "yyyy-MM-dd"));
  }, []); // eslint-disable-line

  const doShowModal = () => {
    setEmitError({ error: undefined })
    if (!formadepago) {
      setEmitError({ error: "formadepago", msg: "Selecciona una forma de pago." })
      return
    }
    switch (idayvuelta) {
      case "bono":
        if (!bono) setEmitError({ error: "bono", msg: "Selecciona un bono" })
        else if (bono && !bono.formentera) setShowModal(true)
        else if (bono && bono.formentera) {
          if (!ida) setEmitError({ error: "ida", msg: "Selecciona un viaje de ida" })
          else if (!regreso) setEmitError({ error: "regreso", msg: "Selecciona un viaje de regreso" })
          else setShowModal(true)
        }
        break;
      case "si":
        if (ida) setShowModal(true)
        else setEmitError({ error: "ida", msg: "Selecciona un viaje de ida" })
        break;
      case "iyv":
        if (!ida) setEmitError({ error: "ida", msg: "Selecciona un viaje de ida" })
        else if (!regreso) setEmitError({ error: "regreso", msg: "Selecciona un viaje de regreso" })
        else setShowModal(true)
        break;
      default:
        setEmitError({ error: "unsuported", msg: "Configuración incorrecta" })
    }
  }
  const doShowHotelEmitModal = () => {
    setEmitError({ error: undefined })
    switch (idayvuelta) {
      case "bono":
        if (!bono) setEmitError({ error: "bono", msg: "Selecciona un bono" })
        else if (bono && !bono.formentera) setHotelEmitShowModal(true)
        else if (bono && bono.formentera) {
          if (!ida) setEmitError({ error: "ida", msg: "Selecciona un viaje de ida" })
          else if (!regreso) setEmitError({ error: "regreso", msg: "Selecciona un viaje de regreso" })
          else setHotelEmitShowModal(true)
        }
        break;
      case "si":
        if (ida) setHotelEmitShowModal(true)
        else setEmitError({ error: "ida", msg: "Selecciona un viaje de ida" })
        break;
      case "iyv":
        if (!ida) setEmitError({ error: "ida", msg: "Selecciona un viaje de ida" })
        else if (!regreso) setEmitError({ error: "regreso", msg: "Selecciona un viaje de regreso" })
        else setHotelEmitShowModal(true)
        break;
      default:
        setEmitError({ error: "unsuported", msg: "Configuración incorrecta" })
    }
  }

  const doShowLinkModal = () => {
    setEmitError({ error: undefined })
    setEnviarMail(true)
    switch (idayvuelta) {
      case "bono":
        if (!bono) setEmitError({ error: "bono", msg: "Selecciona un bono" })
        else if (bono && !bono.formentera) setShowLinkModal(true)
        else if (bono && bono.formentera) {
          if (!ida) setEmitError({ error: "ida", msg: "Selecciona un viaje de ida" })
          else if (!regreso) setEmitError({ error: "regreso", msg: "Selecciona un viaje de regreso" })
          else setShowLinkModal(true)
        }
        break;
      case "si":
        if (ida) setShowLinkModal(true)
        else setEmitError({ error: "ida", msg: "Selecciona un viaje de ida" })
        break;
      case "iyv":
        if (!ida) setEmitError({ error: "ida", msg: "Selecciona un viaje de ida" })
        else if (!regreso) setEmitError({ error: "regreso", msg: "Selecciona un viaje de regreso" })
        else setShowLinkModal(true)
        break;
      default:
        setEmitError({ error: "unsuported", msg: "Configuración incorrecta" })
    }
  }

  const closeModal = () => {
    setShowModal(false);
  };
  const closeHotelEmitModal = () => {
    setHotelEmitShowModal(false);
  };
  const closeResultModal = () => {
    setShowResultModal(false);
  };
  const closeLinkModal = () => {
    setShowLinkModal(false);
  };

  const emitirEfectivo = () => {
    setGenerarLoc(true)
    setLocalizador("")
    if (enviarMail)
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {//eslint-disable-line
        setEmitError({ error: "email", msg: "Introduce un email válido" })
        return;
      }
    if (sendWA && !phone.startsWith("+")) {
      setEmitError({ error: "phone", msg: "Incluye signo '+', código del país y/o área y número de teléfono a continuación" })
      return
    }
    const regExp = /^\+\d{8,}$/
    if (sendWA && !regExp.test(phone)) {
      setEmitError({ error: "phone", msg: "Introduce un número de teléfono válido" })
      return
    }
    setEmitError({ error: undefined, msg: "" })
    setBookingStatus({ status: "pending" })
    setFormadepago("efectivo")
    if (!window.confirm(`1º Realiza el cobro de ${totales.total.toFixed(2)}€\n2º Pulsa aceptar para emitir la reserva.`)) return
    closeModal();
    closeHotelEmitModal()
    crearReserva(undefined, "efectivo", true);
  };

  const emitirTarjeta = () => {
    if (enviarMail)
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {//eslint-disable-line
        setEmitError({ error: "email", msg: "Introduce un email válido" })
        return;
      }
    if (sendWA && !phone.startsWith("+")) {
      setEmitError({ error: "phone", msg: "Incluye signo '+', código del país y/o área y número de teléfono a continuación" })
      return
    }
    const regExp = /^\+\d{8,}$/
    if (sendWA && !regExp.test(phone)) {
      setEmitError({ error: "phone", msg: "Introduce un número de teléfono válido" })
      return
    }
    setEmitError({ error: undefined, msg: "" })
    setBookingStatus({ status: "pending" })
    setFormadepago("comercia")
    doComercia()
  };

  const printTickets = (data) => {
    if (!printerMode) return
    if (printerMode === "disabled") return
    if (printerMode === "tpv") proxyPrintTickets(data)
    if (printerMode === "usb" && printer) printTicket(data)
    if (printerMode === "bt") btPrintTicket(data)
  }


  const printFacturas = (b, f) => {
    if (!printerMode) return
    if (printerMode === "disabled") return
    if (printerMode === "tpv") proxyPrintFactura(f)
    if (printerMode === "usb" && printer) printFactura(b, f)
    if (printerMode === "bt") btPrintFactura(b, f)
  }

  const doComercia = async () => {
    setComerciaLoading(true)
    setGenerarLoc(false)
    const loc = makeLocalizador(6)
    setLocalizador(loc)
    const comerciaPayment = await emitirComercia(totales, loc)
    if (comerciaPayment.ok) {
      crearReserva(comerciaPayment.transaction, "comercia", false)
    } else {
      setBookingStatus({ status: "error", msg: comerciaPayment.transaction.resultMessage })
    }
    setComerciaLoading(false)
  }

  const retryComercia = async () => {
    setGenerarLoc(false)
    const loc = makeLocalizador(6)
    setLocalizador(loc)
    const comerciaPayment = await comerciaLastTrans(totales, loc)
    if (comerciaPayment.ok) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/transactions?transaction_id=${comerciaPayment.transaction.ticket.Id}`
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${userDetails.token}`
        }
      });
      if (data.length) {
        window.alert(`La transacción ya está en el sistema \n ${data[0]}`)
      } else {
        if (!window.confirm(`Deseas emitir reserva con esta transacción? \n ${comerciaPayment.transaction.ticket}`)) return
        crearReserva(comerciaPayment.transaction, "comercia", false)
      }
    } else {
      window.alert(`No se ha podido recuperar la última transacción.`)
    }
  }

  const emitirHotel = () => {
    if (!firstName) {
      setEmitError({ error: "firstName", msg: "Introduce el nombre del cliente" })
      return;
    }
    if (!lastName) {
      setEmitError({ error: "lastName", msg: "Introduce el apellido del cliente" })
      return;
    }
    if (enviarMail)
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {//eslint-disable-line
        setEmitError({ error: "email", msg: "Introduce un email válido" })
        return;
      }
    if (sendWA && !phone.startsWith("+")) {
      setEmitError({ error: "phone", msg: "Incluye signo '+', código del país y/o área y número de teléfono a continuación" })
      return
    }
    const regExp = /^\+\d{8,}$/
    if (sendWA && !regExp.test(phone)) {
      setEmitError({ error: "phone", msg: "Introduce un número de teléfono válido" })
      return
    }

    setEmitError({ error: undefined, msg: "" })
    crearReserva(undefined, undefined, true)
  }

  const crearReserva = async (comerciatransaction, fpd, genloc) => {
    setBookingStatus({ status: "loading" })
    const body = JSON.stringify({
      adults,
      children,
      babies,
      formadepago: fpd || formadepago,
      idayvuelta: idayvuelta === "bono" ? !bono.formentera ? "other" : "iyv" : idayvuelta,
      email: enviarMail ? email : "",
      phone_number: sendWA ? phone : "",
      lang,
      total: totales.total.toFixed(2),
      tarifa_adult: totales.tarifa_adult.toFixed(2),
      tarifa_child: totales.tarifa_child.toFixed(2),
      ida: idayvuelta === "bono" ? bono?.formentera ? ida.id : undefined : ida.id,
      vuelta: idayvuelta === "bono" ? bono?.formentera ? regreso.id : undefined : regreso?.id,
      bono: idayvuelta === "bono" ? bono.id : undefined,
      is_bono: idayvuelta === "bono",
      firstName,
      lastName: lastName,
      enviarMail,
      sendWA,
      discount: discount || 0,
      residente,
      facturacompleta,
      razonFiscal,
      cif,
      direccion,
      cp,
      poblacion,
      provincia,
      pais,
      generarLoc: genloc || generarLoc,
      localizador,
      comerciatransaction
    })
    console.log("NEW BOOKING BODY", body)
    const url = `${process.env.REACT_APP_BACKEND_URL}/${userDetails.user.role.type === "hotel" ? "hotelbooking" : "posbooking"}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userDetails.token}`,
        },
        body,
      });
      const data = await response.json();
      console.log("NUEVA RESERVA", data);
      if (response.status === 200) {
        setShowModal(false)
        setHotelEmitShowModal(false)
        setBookingStatus({ status: "success" })
        setBooking(data);
        setShowResultModal(true);
        printTickets(data)
      } else {
        setBookingStatus({ status: "error", msg: data.errorCode || "Error al generar la reserva" })
      }
    } catch (err) {
      setBookingStatus({ status: "error", msg: err })
    } finally {
      setLocalizador("")
      setGenerarLoc(true)
    }
  };

  const handleSendLink = () => {
    if (!firstName) {
      setEmitError({ error: "firstName", msg: "Introduce el nombre del cliente" })
      return;
    }
    if (!lastName) {
      setEmitError({ error: "lastName", msg: "Introduce el apellido del cliente" })
      return;
    }
    if (enviarMail)
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) { //eslint-disable-line
        setEmitError({ error: "email", msg: "Introduce un email válido" })
        return;
      }
    if (sendWA && !phone.startsWith("+")) {
      setEmitError({ error: "phone", msg: "Incluye signo '+', código del país y/o área y número de teléfono a continuación" })
      return
    }
    const regExp = /^\+\d{8,}$/
    if (sendWA && !regExp.test(phone)) {
      setEmitError({ error: "phone", msg: "Introduce un número de teléfono válido" })
      return
    }

    setEmitError({ error: undefined, msg: "" })
    sendLink()
  }

  const sendLink = async () => {
    setBookingStatus({ status: "loading" })
    const body = JSON.stringify({
      adults,
      children,
      babies,
      idayvuelta: idayvuelta === "bono" ? !bono.formentera ? "other" : "iyv" : idayvuelta,
      email: enviarMail ? email : "",
      phone_number: sendWA ? phone : "",
      lang,
      total: totales.total.toFixed(2),
      tarifa_adult: totales.tarifa_adult.toFixed(2),
      tarifa_child: totales.tarifa_child.toFixed(2),
      ida: idayvuelta === "bono" ? bono?.formentera ? ida.id : undefined : ida.id,
      vuelta: idayvuelta === "bono" ? bono?.formentera ? regreso.id : undefined : regreso?.id,
      bono: idayvuelta === "bono" ? bono.id : undefined,
      is_bono: idayvuelta === "bono",
      enviarMail,
      sendWA,
      firstName,
      lastName,
      discount: discount || 0,
      residente,
      facturacompleta,
      razonFiscal,
      cif,
      direccion,
      cp,
      poblacion,
      provincia,
      pais,
      user: userDetails.user.id
    })
    const url = `${process.env.REACT_APP_BACKEND_URL}/prereserva`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userDetails.token}`,
        },
        body,
      });
      const data = await response.json();
      console.log("ENLACE DE RESERVA", data);
      if (response.status === 200) {
        setShowLinkModal(false)
        setBookingStatus({ status: "success" })
        setBooking(data);
        setShowResultModal(true);
      } else {
        setBookingStatus({ status: "error", msg: data.errorCode || "Error al generar la reserva" })
      }
    } catch (err) {
      setBookingStatus({ status: "error", msg: err })
    }
  }

  const clean = () => {
    setFechaIda(format(new Date(), "yyyy-MM-dd"));
    setIda(undefined);
    setFechaRegreso(format(new Date(), "yyyy-MM-dd"));
    setRegreso(undefined);
    setIdayvuelta("iyv");
    setFormadepago("efectivo");
    setAdults(1);
    setChildren(0);
    setBabies(0);
    setDiscount(0);
    setTotales(undefined)
    setResidente(false);
    setEnviarMail(false);
    setGenerarLoc(true);
    setEmail("");
    setFirstName("");
    setLastName("");
    setLocalizador("");
    setPhone("")
    setSendWA(false)
    setLang("en");
    setGeneraFactura(true);
    setFacturacompleta(false);
    setRazonFiscal("");
    setCif("");
    setDireccion("");
    setPoblacion("");
    setCp("");
    setProvincia("");
    setPais("");
    setEmitError({ error: undefined })
    setBookingStatus({ status: "pending" })
    setSenior(false)
  };


  const doShowMailModal = () => {
    setShowEmailModal(true)
  }
  const doShowWhatsAppTicketsModal = () => {
    setShowWhatsAppTicketsModal(true)
  }

  const doShowCancelModal = () => {
    setShowCancelModal(true)
  }

  const doHideCancelModal = () => {
    setShowCancelModal(false)
  }

  const doShowRefundModal = () => {
    setShowRefundModal(true)
  }

  const doHideRefundModal = () => {
    setShowRefundModal(false)
  }

  const doShowModificationModal = () => {
    setShowModificationModal(true)
  }

  const doHideModificationModal = () => {
    setShowModificationModal(false)
  }

  const lastTrans = async () => {

  }

  return (
    <>
      {/** EMIT MODAL */}
      <Modal show={showModal} onHide={closeModal} style={{ fontSize: ".8em", fontWeight: 700, color: "#555555" }}>
        <Modal.Header closeButton >
          <Modal.Title>Emitir Reserva</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {bookingStatus.status === "error" && <div className="text-danger">{bookingStatus.msg}</div>}

          <div className="d-flex flex-wrap mb-2">
            <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Nombre">
              <Form.Control
                className={emitError.error === "firstName" ? "border-danger" : ""}
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FloatingLabel>
            <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Apellidos">
              <Form.Control
                className={emitError.error === "lastName" ? "border-danger" : ""}
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FloatingLabel>
          </div>
          <div className="d-flex flex-wrap mb-2">
            <Bubutton
              className="mb-2 mx-2"
              style={{ minWidth: "100px" }}
              onClick={() => setEnviarMail(val => !val)}
              text="e-mail"
              variant={enviarMail ? "success" : "outline-secondary"}>
              <FaMailBulk />
            </Bubutton>
            <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="e-mail">
              <Form.Control
                className={emitError.error === "email" ? "border-danger" : ""}
                type="email"
                id="email"
                disabled={!enviarMail}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FloatingLabel>
          </div>
          <div className="d-flex flex-wrap mb-2">
            <Bubutton
              className="mb-2 ms-2 me-3"
              style={{ minWidth: "100px" }}
              onClick={() => setSendWA(value => !value)}
              text="WhatsApp"
              variant={sendWA ? "success" : "outline-secondary"}>
              <FaSms />
            </Bubutton>
            <FloatingLabel className="mb-2" label="Teléfono">
              <Form.Control
                type="tel"
                id="phone"
                className={emitError.error === "phone" ? "border-danger" : ""}
                disabled={!sendWA}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </FloatingLabel>

          </div>
          <div className="pb-2 mx-2" >
            {idayvuelta === "bono" && bono && (
              <div>
                <b>BONO: </b>{" "}
                {`${bono.days} días ${bono.type === "PLY" ? "Playas" : "Playas + Ibiza"} ${bono.formentera ? "+ Formentera" : ""}`}
              </div>
            )}
            {(idayvuelta !== "bono" || (bono && bono.formentera)) && ida && (
              <>
                <div>
                  <b>IDA: </b>{" "}
                  {ida.details.open
                    ? `${ida.details.origin.name} - ${ida.details.destination.name} OPEN`
                    : `${ida.details.origin.name} - ${ida.details.destination.name
                    } ${ida.details.departure.substring(
                      0,
                      5
                    )} ${format(new Date(ida.date), "dd MMM yyyy")}`}
                </div>
                {ida?.details?.stop?.length ?
                  <ul>
                    {ida.details.stop.map(stop => {
                      return (
                        <li>
                          {stop.shuttle ? stop.pickup_info["es"] :
                            stop.swap ? `Trasbordo en ${stop.place.name} a las ${stop.time.substring(0, 5)}` :
                              `Parada en ${stop.place.name} a las ${stop.time.substring(0, 5)}`}
                        </li>
                      )
                    })}
                  </ul> : ""}
              </>
            )}
            {((idayvuelta !== "si" || (bono && bono.formentera))) && regreso && (
              <>
                <div>
                  <b>REGRESO: </b>{" "}
                  {regreso.details.open
                    ? `${regreso.details.origin.name} - ${regreso.details.destination.name} OPEN`
                    : `${regreso.details.origin.name} - ${regreso.details.destination.name
                    } ${regreso.details.departure.substring(
                      0,
                      5
                    )} ${format(new Date(regreso.date), "dd MMM yyyy")}`}
                </div>
                {regreso?.details?.stop?.length ?
                  <ul>
                    {regreso.details.stop.map(stop => {
                      return (
                        <li>
                          {stop.shuttle ? stop.pickup_info["es"] :
                            stop.swap ? `Trasbordo en ${stop.place.name} a las ${stop.time.substring(0, 5)}` :
                              `Parada en ${stop.place.name} a las ${stop.time.substring(0, 5)}`}
                        </li>
                      )
                    })}
                  </ul> : ""}
              </>
            )}
          </div>
          {generaFactura && formadepago !== "invitacion" && (
            <>
              {totales && (
                <div className="mx-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      {`${residente ? "Adultos (Residente)" : "Adultos"}`}
                    </div>
                    {`${adults} x ${totales.tarifa_adult}€ = ${totales.total_adult.toFixed(2)}€`}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      {`${residente ? "Niños (Residente)" : "Niños"}`}
                    </div>
                    {`${children} x ${totales.tarifa_child}€ = ${totales.total_child.toFixed(2)}€`}
                  </div>
                  {babies > 0 ?
                    <div className="d-flex justify-content-between">
                      <div>Bebés</div> {`${babies} x 0€`}
                    </div> : ""}
                  {discount ?
                    <div className="d-flex justify-content-between">
                      <div>Descuento</div> {`${discount}% = -${totales.total_discount.toFixed(2)}€`}
                    </div> : ""}
                  <h3 className="border-top d-flex justify-content-between">
                    <div>TOTAL</div> {`${totales.total.toFixed(2)} €`}
                  </h3>
                </div>
              )}
            </>
          )}
          {(!generarLoc && localizador) && (
            <div>
              Localizador <b>{localizador}</b>
            </div>
          )}
          {firstName && (
            <>
              <div>Nombre: {firstName}</div>
              <div>Apellidos: {lastName}</div>
              <div>Lang: {lang}</div>
            </>
          )}
          {!generaFactura && <div> <b>No se generará Factura</b> </div>}
          {emitError && <div className="text-end text-danger mb-0 mt-2">{emitError.msg}</div>}
        </Modal.Body>

        <Modal.Footer>
          <Bubutton
            onClick={retryComercia}
            style={{ marginLeft: 0, marginRight: 0, borderRadius: "6px 0px 0px 6px" }}
            variant="secondary"
            text="Last Trans">
            <FaTractor />
          </Bubutton>
          <Bubutton
            style={{ minWidth: "120px", marginLeft: 0, marginRight: 0, borderRadius: "6px 0px 0px 6px" }}
            onClick={closeModal}
            variant="secondary"
            text="Cancelar">
            <FaStop />
          </Bubutton>
          <Bubutton
            style={{ minWidth: "120px", marginLeft: 0, marginRight: 0, borderRadius: "0px 0px 0px 0px" }}
            variant="danger"
            onClick={emitirEfectivo}
            disabled={bookingStatus === "loading"}
            text="Emisión Efectivo">
            <FaCoins />
          </Bubutton>
          <Bubutton
            style={{ minWidth: "120px", marginLeft: 0, borderRadius: "0px 6px 6px 0px" }}
            variant="warning"
            onClick={emitirTarjeta}
            disabled={bookingStatus === "loading"}
            text="Emisión Tarjeta">
            <GoCreditCard />
          </Bubutton>
        </Modal.Footer>
      </Modal >

      {/**EMITIR MODAL HOTEL */}
      < Modal show={showHotelEmitModal} onHide={closeHotelEmitModal} style={{ fontSize: ".8em", fontWeight: 700, color: "#555555" }
      }>
        <Modal.Header closeButton >
          <Modal.Title>Emitir Reserva</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {bookingStatus.status === "error" && <div className="text-danger">{bookingStatus.msg}</div>}

          <div className="d-flex flex-wrap mb-2">
            <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Nombre">
              <Form.Control
                className={emitError.error === "firstName" ? "border-danger" : ""}
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FloatingLabel>
            <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Apellidos">
              <Form.Control
                className={emitError.error === "lastName" ? "border-danger" : ""}
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FloatingLabel>
          </div>
          <div className="d-flex flex-wrap mb-2">
            <Bubutton
              className="mb-2 mx-2"
              style={{ minWidth: "100px" }}
              onClick={() => setEnviarMail(val => !val)}
              text="e-mail"
              variant={enviarMail ? "success" : "outline-secondary"}>
              <FaMailBulk />
            </Bubutton>
            <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="e-mail">
              <Form.Control
                className={emitError.error === "email" ? "border-danger" : ""}
                type="email"
                id="email"
                disabled={!enviarMail}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FloatingLabel>
          </div>
          <div className="d-flex flex-wrap mb-2">
            <Bubutton
              className="mb-2 ms-2 me-3"
              style={{ minWidth: "100px" }}
              onClick={() => setSendWA(value => !value)}
              text="WhatsApp"
              variant={sendWA ? "success" : "outline-secondary"}>
              <FaSms />
            </Bubutton>
            <FloatingLabel className="mb-2" label="Teléfono">
              <Form.Control
                type="tel"
                id="phone"
                className={emitError.error === "phone" ? "border-danger" : ""}
                disabled={!sendWA}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </FloatingLabel>

          </div>
          <div className="pb-2 mx-2" >
            {idayvuelta === "bono" && bono && (
              <div>
                <b>BONO: </b>{" "}
                {`${bono.days} días ${bono.type === "PLY" ? "Playas" : "Playas + Ibiza"} ${bono.formentera ? "+ Formentera" : ""}`}
              </div>
            )}
            {(idayvuelta !== "bono" || (bono && bono.formentera)) && ida && (
              <>
                <div>
                  <b>IDA: </b>{" "}
                  {ida.details.open
                    ? `${ida.details.origin.name} - ${ida.details.destination.name} OPEN`
                    : `${ida.details.origin.name} - ${ida.details.destination.name
                    } ${ida.details.departure.substring(
                      0,
                      5
                    )} ${format(new Date(ida.date), "dd MMM yyyy")}`}
                </div>
                {ida?.details?.stop?.length ?
                  <ul>
                    {ida.details.stop.map(stop => {
                      return (
                        <li>
                          {stop.shuttle ? stop.pickup_info["es"] :
                            stop.swap ? `Trasbordo en ${stop.place.name} a las ${stop.time.substring(0, 5)}` :
                              `Parada en ${stop.place.name} a las ${stop.time.substring(0, 5)}`}
                        </li>
                      )
                    })}
                  </ul> : ""}
              </>
            )}
            {((idayvuelta !== "si" || (bono && bono.formentera))) && regreso && (
              <>
                <div>
                  <b>REGRESO: </b>{" "}
                  {regreso.details.open
                    ? `${regreso.details.origin.name} - ${regreso.details.destination.name} OPEN`
                    : `${regreso.details.origin.name} - ${regreso.details.destination.name
                    } ${regreso.details.departure.substring(
                      0,
                      5
                    )} ${format(new Date(regreso.date), "dd MMM yyyy")}`}
                </div>
                {regreso?.details?.stop?.length ?
                  <ul>
                    {regreso.details.stop.map(stop => {
                      return (
                        <li>
                          {stop.shuttle ? stop.pickup_info["es"] :
                            stop.swap ? `Trasbordo en ${stop.place.name} a las ${stop.time.substring(0, 5)}` :
                              `Parada en ${stop.place.name} a las ${stop.time.substring(0, 5)}`}
                        </li>
                      )
                    })}
                  </ul> : ""}
              </>
            )}
          </div>
          {generaFactura && formadepago !== "invitacion" && (
            <>
              {totales && (
                <div className="mx-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      {`${residente ? "Adultos (Residente)" : "Adultos"}`}
                    </div>
                    {`${adults} x ${totales.tarifa_adult}€ = ${totales.total_adult.toFixed(2)}€`}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      {`${residente ? "Niños (Residente)" : "Niños"}`}
                    </div>
                    {`${children} x ${totales.tarifa_child}€ = ${totales.total_child.toFixed(2)}€`}
                  </div>
                  {babies > 0 ?
                    <div className="d-flex justify-content-between">
                      <div>Bebés</div> {`${babies} x 0€`}
                    </div> : ""}
                  {discount > 0 ?
                    <div className="d-flex justify-content-between">
                      <div>Descuento</div> {`${discount}% = -${totales.total_discount.toFixed(2)}€`}
                    </div> : ""}
                  <h3 className="border-top d-flex justify-content-between">
                    <div>TOTAL</div> {`${totales.total.toFixed(2)} €`}
                  </h3>
                </div>
              )}
            </>
          )}
          {(!generarLoc && localizador) && (
            <div>
              Localizador <b>{localizador}</b>
            </div>
          )}
          {firstName && (
            <>
              <div>Nombre: {firstName}</div>
              <div>Apellidos: {lastName}</div>
              <div>Lang: {lang}</div>
            </>
          )}
          {!generaFactura && <div> <b>No se generará Factura</b> </div>}
          {emitError && <div className="text-end text-danger mb-0 mt-2">{emitError.msg}</div>}
        </Modal.Body>

        <Modal.Footer>
          <Bubutton
            style={{ minWidth: "120px", marginLeft: 0, marginRight: 0, borderRadius: "6px 0px 0px 6px" }}
            onClick={closeHotelEmitModal}
            variant="secondary"
            disabled={bookingStatus === "loading"}
            text="Cancelar">
            <FaStop />
          </Bubutton>

          <Bubutton
            style={{ minWidth: "120px", marginLeft: 0, borderRadius: "0px 6px 6px 0px" }}
            variant="primary"
            onClick={emitirHotel}
            disabled={bookingStatus === "loading"}
            text="Emitir Reserva">
            <RiSendPlaneFill />
          </Bubutton>
        </Modal.Footer>
      </Modal >

      {/** LINK MODAL */}
      < Modal show={showLinkModal} onHide={closeLinkModal} style={{ fontSize: ".8em", fontWeight: 700, color: "#555555" }}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar enlace de pago</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-wrap mb-2">
            <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Nombre">
              <Form.Control
                className={emitError.error === "firstName" ? "border-danger" : ""}
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FloatingLabel>
            <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Apellidos">
              <Form.Control
                className={emitError.error === "lastName" ? "border-danger" : ""}
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FloatingLabel>
          </div>
          <div className="d-flex flex-wrap mb-2">
            <Bubutton
              className="mb-2 mx-2"
              style={{ minWidth: "100px" }}
              onClick={() => setEnviarMail(val => !val)}
              text="e-mail"
              variant={enviarMail ? "success" : "outline-secondary"}>
              <FaMailBulk />
            </Bubutton>
            <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="e-mail">
              <Form.Control
                className={emitError.error === "email" ? "border-danger" : ""}
                type="email"
                id="email"
                disabled={!enviarMail}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FloatingLabel>
          </div>
          <div className="d-flex flex-wrap mb-2">
            <Bubutton
              className="mb-2 ms-2 me-3"
              style={{ minWidth: "100px" }}
              onClick={() => setSendWA(value => !value)}
              text="WhatsApp"
              variant={sendWA ? "success" : "outline-secondary"}>
              <FaSms />
            </Bubutton>
            <FloatingLabel className="mb-2" label="Teléfono">
              <Form.Control
                type="tel"
                id="phone"
                className={emitError.error === "phone" ? "border-danger" : ""}
                disabled={!sendWA}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </FloatingLabel>

          </div>
          {bookingStatus.status === "error" && <div className="text-danger">{bookingStatus.msg}</div>}
          <div className="pb-2 mx-2" >
            {idayvuelta === "bono" && bono && (
              <div>
                <b>BONO: </b>{" "}
                {`${bono.days} días ${bono.type === "PLY" ? "Playas" : "Playas + Ibiza"} ${bono.formentera ? "+ Formentera" : ""}`}
              </div>
            )}
            {(idayvuelta !== "bono" || (bono && bono.formentera)) && ida && (
              <>
                <div>
                  <b>IDA: </b>{" "}
                  {ida.details.open
                    ? `${ida.details.origin.name} - ${ida.details.destination.name} OPEN`
                    : `${ida.details.origin.name} - ${ida.details.destination.name
                    } ${ida.details.departure.substring(
                      0,
                      5
                    )} ${format(new Date(ida.date), "dd MMM yyyy")}`}
                </div>
                {ida?.details?.stop?.length ?
                  <ul>
                    {ida.details.stop.map(stop => {
                      return (
                        <li>
                          {stop.shuttle ? stop.pickup_info["es"] :
                            stop.swap ? `Trasbordo en ${stop.place.name} a las ${stop.time.substring(0, 5)}` :
                              `Parada en ${stop.place.name} a las ${stop.time.substring(0, 5)}`}
                        </li>
                      )
                    })}
                  </ul> : ""}
              </>
            )}
            {((idayvuelta !== "si" || (bono && bono.formentera))) && regreso && (
              <>
                <div>
                  <b>REGRESO: </b>{" "}
                  {regreso.details.open
                    ? `${regreso.details.origin.name} - ${regreso.details.destination.name} OPEN`
                    : `${regreso.details.origin.name} - ${regreso.details.destination.name
                    } ${regreso.details.departure.substring(
                      0,
                      5
                    )} ${format(new Date(regreso.date), "dd MMM yyyy")}`}
                </div>
                {ida?.details?.stop?.length ?
                  <ul>
                    {ida.details.stop.map(stop => {
                      return (
                        <li>
                          {stop.shuttle ? stop.pickup_info["es"] :
                            stop.swap ? `Trasbordo en ${stop.place.name} a las ${stop.time.substring(0, 5)}` :
                              `Parada en ${stop.place.name} a las ${stop.time.substring(0, 5)}`}
                        </li>
                      )
                    })}
                  </ul> : ""}
              </>
            )}
          </div>
          {generaFactura && formadepago !== "invitacion" && (
            <div className="mx-2">
              {totales && (
                <div>
                  <div className="d-flex justify-content-between">
                    <div>
                      {`${residente ? "Adultos (Residente)" : "Adultos"}`}
                    </div>
                    {`${adults} x ${totales.tarifa_adult}€ = ${totales.total_adult.toFixed(2)}€`}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      {`${residente ? "Niños (Residente)" : "Niños"}`}
                    </div>
                    {`${children} x ${totales.tarifa_child}€ = ${totales.total_child.toFixed(2)}€`}
                  </div>
                  {babies > 0 ?
                    <div className="d-flex justify-content-between">
                      <div>Bebés</div> {`${babies} x 0€`}
                    </div> : ""}
                  {discount ?
                    <div className="d-flex justify-content-between">
                      <div>Descuento</div> {`${discount}% = -${totales.total_discount.toFixed(2)}€`}
                    </div> : ""}
                  <h3 className="border-top d-flex justify-content-between">
                    <div>TOTAL</div> {`${totales.total.toFixed(2)} €`}
                  </h3>
                </div>
              )}
            </div>
          )}

          {emitError && <div className="text-end text-danger mb-0 mt-2">{emitError.msg}</div>}
        </Modal.Body>

        <Modal.Footer>

          <Bubutton
            style={{ minWidth: "120px", marginLeft: 0, marginRight: 0, borderRadius: "6px 0px 0px 6px" }}
            onClick={closeLinkModal}
            variant="secondary"
            text="Cancelar">
            <FaStop />
          </Bubutton>
          <Bubutton
            style={{ minWidth: "120px", marginLeft: 0, borderRadius: "0px 6px 6px 0px" }}
            variant="success"
            disabled={!enviarMail && !sendWA}
            onClick={handleSendLink}
            text="Enviar enlace">
            <RiSendPlaneFill />
          </Bubutton>
        </Modal.Footer>
      </Modal >

      {/** RESULT MODAL */}
      < Modal show={showResultModal} onHide={closeResultModal} style={{ fontSize: ".8em", fontWeight: 700, color: "#555555" }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {booking ? "RESERVA CONFIRMADA" : "RESERVA FALLIDA"}
          </Modal.Title>
        </Modal.Header>
        {
          booking && (
            <Modal.Body>
              <h3>
                Localizador: <b>{booking.localizador}</b>
              </h3>
              <div>
                <h5>Descarga t.embarque en el móvil:</h5>
                <QRCode
                  value={`${process.env.REACT_APP_BACKEND_URL}/boardingpass/${booking.id}?media=mobile`}
                />
              </div>
            </Modal.Body>
          )
        }

        <Modal.Footer>
          <Button
            onClick={() => {
              closeResultModal();
              clean();
            }}
            variant="success"
          >
            Siguiente
          </Button>
          <Button
            disabled={!printer}
            variant="warning"
            onClick={() => {
              printTicket(booking);
            }}
          >
            Print Ticket
          </Button>
          <Button
            variant="warning"
            disabled={!printer}
            onClick={() => {
              printFacturas(booking, booking.facturas[0]);
            }}
          >
            Print Receipt
          </Button>
          <Button
            onClick={() => {
              history.push(`/booking/${booking.id}`);
            }}
          >
            Ver Reserva
          </Button>
        </Modal.Footer>
      </Modal >



      {booking &&
        <>
          <BookingModal
            booking={booking}
            showModal={showResultModal}
            doHideModal={closeResultModal}
            doShowMailModal={doShowMailModal}
            doShowCancelModal={doShowCancelModal}
            doShowRefundModal={doShowRefundModal}
            doShowModificationModal={doShowModificationModal}
            doShowWhatsAppTicketsModal={doShowWhatsAppTicketsModal} />
          <EmailTicketsModal
            booking={booking}
            showEmailModal={showEmailModal}
            setShowEmailModal={setShowEmailModal} />
          <WhatsAppTicketsModal
            booking={booking}
            showWhatsAppTicketsModal={showWhatsAppTicketsModal}
            setShowWhatsAppTicketsModal={setShowWhatsAppTicketsModal} />
          <CancelModal
            booking={booking}
            showCancelModal={showCancelModal}
            doHideCancelModal={doHideCancelModal} />
          <RefundModal
            booking={booking}
            showRefundModal={showRefundModal}
            doHideRefundModal={doHideRefundModal} />
          <ModificationModal
            booking={booking}
            showModificationModal={showModificationModal}
            doHideModificationModal={doHideModificationModal} />
        </>}

      <Menu>
        {
          comerciaLoading && (
            <ComerciaLoading setLoading={setComerciaLoading} />
          )
        }
        {bookingStatus.status === "loading" ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              position: "fixed",
              overflow: "hidden",
              zIndex: "3000",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
              }}
            ></Spinner>
          </div>
        ) : (
          <div className={styles.container}>
            <h3 style={{ fontWeight: 700, color: "#555555" }}>Nueva Reserva</h3>
            <div className="p-3 d-flex flex-wrap justify-content-start align-items-center border-top border-end border-start">
              <ButtonGroup className="me-2 mb-2 flex-wrap">
                <Bubutton
                  style={{ width: "6em" }}
                  onClick={() => setIdayvuelta("si")}
                  text="Solo Ida"
                  variant={idayvuelta === "si" ? "success" : "secondary"}
                >
                  <FaArrowRight />
                </Bubutton>
                <Bubutton
                  style={{ width: "6em" }}
                  onClick={() => setIdayvuelta("iyv")}
                  text="Ida y vuelta"
                  variant={idayvuelta === "iyv" ? "success" : "secondary"}
                >
                  <FaArrowsAltH />
                </Bubutton>
                <Bubutton
                  style={{ width: "6em" }}
                  onClick={() => setIdayvuelta("bono")}
                  text="Bono"
                  variant={idayvuelta === "bono" ? "success" : "secondary"}
                >
                  <FaQrcode />
                </Bubutton>
              </ButtonGroup>
              {userDetails.user.role.type !== "hotel" && <Bubutton
                className="mb-2 me-2"
                style={{ width: "7em", marginRight: ".5em", minWidth: "100px" }}
                onClick={() => setResidente(value => !value)}
                text={residente ? "Residente" : "No Residente"}
                variant={residente ? "success" : "outline-success"}>
                <GiIsland />
              </Bubutton>}
              <div className="me-2 mb-2">
                <Form.Select
                  size="lg"
                  style={{ width: "100px", height: "58px" }}
                  name="language"
                  className="border-success"
                  value={lang}
                  onChange={(e) => setLang(e.target.value)}
                >
                  <option value="es">🇪🇸 es</option>
                  <option value="en">🇬🇧 en</option>
                  <option value="de">🇩🇪 de</option>
                  <option value="it">🇮🇹 it</option>
                  <option value="fr">🇫🇷 fr</option>
                  <option value="ca">🇵🇱 ca</option>
                </Form.Select>
              </div>
              <FloatingLabel className="me-2 mb-2" label="% Desc.">
                <Form.Control
                  type="number"
                  style={{ width: "120px" }}
                  placeholder="%"
                  id="discount"
                  disabled={userDetails.user.role.type === "hotel"}
                  className={userDetails.user.role.type === "hotel" ? "bg-light border-secondary" : "border-success"}
                  value={discount}
                  min={0}
                  max={100}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </FloatingLabel>
              <div className="me-2 mb-2">
                <Bubutton
                  style={{ width: "6em" }}
                  onClick={() => setSenior(value => !value)}
                  text="Senior"
                  variant={senior ? "success" : "secondary"}
                >
                  <MdElderly />
                </Bubutton>
              </div>
              <div className="d-flex flex-wrap border border-success me-2 mb-2" style={{ borderRadius: ".3em", padding: "1px 12px 0 12px" }}>
                <div className="mb-2 me-4">
                  <div htmlFor="adult-btngroup" style={{ fontSize: ".8em", color: "grey", fontWeight: 700, margin: 0 }}>Adult (12+) </div>
                  <ButtonGroup id="adult-btngroup" >
                    <Button variant="success" className="pb-0 pt-0" onClick={() => setAdults(val => val === 0 ? val : val - 1)}>-</Button>
                    <Button variant="success" className="pb-0 pt-0" disabled>{adults}</Button>
                    <Button variant="success" className="pb-0 pt-0" onClick={() => setAdults(val => val + 1)}>+</Button>
                  </ButtonGroup>
                </div>
                <div className="mb-2 me-4">
                  <div htmlFor="child-btngroup" style={{ fontSize: ".8em", color: "grey", fontWeight: 700 }}>Child (4-11) </div>
                  <ButtonGroup id="child-btngroup">
                    <Button variant="success" className="pb-0 pt-0" onClick={() => setChildren(val => val === 0 ? val : val - 1)}>-</Button>
                    <Button variant="success" className="pb-0 pt-0" disabled>{children}</Button>
                    <Button variant="success" className="pb-0 pt-0" onClick={() => setChildren(val => val + 1)}>+</Button>
                  </ButtonGroup>
                </div>
                <div className="mb-2">
                  <div htmlFor="baby-btngroup" style={{ fontSize: ".8em", color: "grey", fontWeight: 700 }}>Infant (3-) </div>
                  <ButtonGroup id="baby-btngroup">
                    <Button variant="success" className="pb-0 pt-0" onClick={() => setBabies(val => val === 0 ? val : val - 1)}>-</Button>
                    <Button variant="success" className="pb-0 pt-0" disabled>{babies}</Button>
                    <Button variant="success" className="pb-0 pt-0" onClick={() => setBabies(val => val + 1)}>+</Button>
                  </ButtonGroup>
                </div>
              </div>

            </div>
            {idayvuelta === "bono" &&
              <div className={`p-3 border-top border-start border-end ${emitError.error === "bono" ? "border-danger  border-bottom rounded" : ""}`}>
                <h5 className={emitError.error === "bono" ? "text-danger" : ""}>Bonos:</h5>
                <div >
                  {posTrips.isSuccess && posTrips.data.bonos.map((b, index) => {
                    return (
                      <Button
                        key={index}
                        className="me-1 mb-1"
                        variant={b.id === bono?.id ? "success" : "secondary"}
                        onClick={() => setBono(b)}>
                        {b.slug}
                      </Button>
                    )
                  })}
                </div>
              </div>}
            {(idayvuelta !== "bono" || (idayvuelta === "bono" && bono && bono.formentera)) &&
              <div className="p-3 border-top border-start border-end">
                <div className="">
                  <Row>
                    <Col sm={6}>
                      <div style={{ fontSize: ".8em", color: "grey", fontWeight: 700 }}>Origen:</div>
                      <Form.Select
                        size="lg"
                        style={{ marginBottom: "14px" }}
                        value={origin}
                        onChange={(e) => {
                          setOrigin(e.target.value)
                          setDestinations(combinations.find(comb => comb.code === e.target.value).destinations)
                        }}
                      >
                        {userDetails.user.role.type === "hotel" ?
                          idayvuelta === "bono" ? combinations.filter(dest => bono ? bono.formentera ? (dest.code === "IBZ" || dest.code === "FOR") ? undefined : dest : dest : dest).map((comb, index) => <option key={index} value={comb.code}>{comb.name}</option>)
                            : combinations.filter(dest => dest.code === "FOR" ? undefined : dest).map((comb, index) => <option key={index} value={comb.code}>{comb.name}</option>) :
                          combinations.filter(dest => bono ? bono.formentera ? (dest.code === "IBZ" || dest.code === "FOR") ? undefined : dest : dest : dest).map((comb, index) => <option key={index} value={comb.code}>{comb.name}</option>)}
                      </Form.Select>
                    </Col>
                    <Col sm={6}>
                      <div style={{ fontSize: ".8em", color: "grey", fontWeight: 700 }}>Destino:</div>
                      <Form.Select
                        size="lg"
                        style={{ marginBottom: "4px" }}
                        value={destination}
                        onChange={(e) => setDestination(e.target.value)}
                      >
                        {userDetails.user.role.type === "hotel" ?
                          idayvuelta === "bono" ? destinations.filter(dest => bono ? bono.formentera ? dest.code === "FOR" ? dest : undefined : dest : dest).map((comb, index) => <option key={index} value={comb.code}>{comb.name}</option>) :
                            idayvuelta === "si" ? destinations.filter(dest => dest.code === "FOR" ? undefined : dest).map((comb, index) => <option key={index} value={comb.code}>{comb.name}</option>) :
                              destinations.map((comb, index) => <option key={index} value={comb.code}>{comb.name}</option>) :
                          destinations.filter(dest => bono ? bono.formentera ? dest.code === "FOR" ? dest : undefined : dest : dest).map((comb, index) => <option key={index} value={comb.code}>{comb.name}</option>)}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <div style={{ fontSize: ".8em", color: "grey", fontWeight: 700 }}>Viaje de ida:</div>
                      <Form.Control
                        size="lg"
                        type="date"
                        style={{ borderRadius: ".2em .2em 0 0", borderBottom: "none" }}
                        placeholder="yyyy-mm-dd"
                        value={fechaIda ? fechaIda : 'dd/mm/yyyy'}
                        onChange={(e) => {
                          setFechaIda(e.target.value)
                          setFechaRegreso(e.target.value)
                        }} />
                      <div className={`border pt-2 px-2 mb-2 ${emitError.error === "ida" ? "border-danger" : ""}`} style={{ borderRadius: "0 0 .2em .2em", minHeight: "3em" }}>
                        {posTrips.isLoading && <Spinner animation="border" variant="success" style={{ margin: "auto" }} />}
                        {posTrips.isSuccess && posTrips.data.outboundTrips.sort(
                          (a, b) => a.details.departure > b.details.departure ? 1 : -1
                        ).map((trip, index) => {
                          return (
                            <Button
                              key={index}
                              className="me-2 mb-2"
                              variant={ida?.id === trip.id ? "success" : "secondary"}
                              disabled={trip.cancelado || trip.completo || trip.pax + adults + children + babies >= trip.details.quota}
                              onClick={() => setIda(trip)}>
                              <div className="h5">{trip.details.open ? "Open" : trip.details.departure.substring(0, 5)}</div>
                              {trip.pax + adults + children + babies >= trip.details.quota && <div className="text-sm">Insuficientes plazas!</div>}
                              {trip.cancelado ? <div className="text-sm">Cancelado</div> :
                                trip.completo ? <div className="text-sm">Completo</div> :
                                  <div className="text-small">{trip.pax}/{trip.details.quota}</div>}
                            </Button>
                          )
                        })}
                      </div>
                    </Col>
                    {(idayvuelta === "iyv" || (idayvuelta === "bono" && bono && bono.formentera)) &&
                      <Col sm={6}>
                        <div style={{ fontSize: ".8em", color: "grey", fontWeight: 700 }}>Viaje de regreso:</div>
                        <Form.Control
                          size="lg"
                          type="date"
                          style={{ borderRadius: ".2em .2em 0 0", borderBottom: "none" }}
                          placeholder="yyyy-mm-dd"
                          value={fechaRegreso ? fechaRegreso : 'dd/mm/yyyy'}
                          min={fechaIda}
                          onChange={(e) => setFechaRegreso(e.target.value)}
                          disabled={userDetails.user.role.type === "hotel" && destination === "FOR"}
                        />
                        <div className={`border pt-2 px-2 mb-2 ${emitError.error === "regreso" ? "border-danger" : ""}`} style={{ borderRadius: "0 0 .2em .2em", minHeight: "3em" }}>
                          {posTrips.isLoading && <Spinner animation="border" variant="success" />}
                          {posTrips.isSuccess && posTrips.data.returnTrips.sort(
                            (a, b) => a.details.departure > b.details.departure ? 1 : -1
                          ).map((trip, index) => {
                            return (
                              <Button
                                key={index}
                                className="me-2 mb-2"
                                variant={regreso?.id === trip.id ? "success" : "secondary"}
                                disabled={trip.cancelado || trip.completo || trip.pax + adults + children + babies >= trip.details.quota}
                                onClick={() => setRegreso(trip)}>
                                <div className="h5">{trip.details.open ? "Open" : trip.details.departure.substring(0, 5)}</div>
                                {trip.pax + adults + children + babies >= trip.details.quota && <div className="text-sm">Insuficientes plazas!</div>}
                                {trip.cancelado ? <div className="text-sm">Cancelado</div> :
                                  trip.completo ? <div className="text-sm">Completo</div> :
                                    <div className="text-small">{trip.pax}/{trip.details.quota}</div>}
                              </Button>
                            )
                          })}
                        </div>

                      </Col>}
                  </Row>
                </div>
              </div>}
            <div className="p-3 border-top border-start border-end d-flex align-items-start flex-wrap" style={{ fontSize: ".8em", color: "#555555", fontWeight: 700 }}>
              <div className="pb-4 flex-grow-1">
                {idayvuelta === "bono" && bono && (
                  <div>
                    <b>BONO: </b>{" "}
                    {`${bono.days} días ${bono.type === "PLY" ? "Playas" : "Playas + Ibiza"} ${bono.formentera ? "+ Formentera" : ""}`}
                  </div>
                )}
                {((idayvuelta === "bono" && bono && bono.formentera && ida) || (idayvuelta !== "bono" && ida)) && (
                  <>
                    <div>
                      <b>IDA: </b>{" "}
                      {ida.details.open
                        ? `${ida.details.origin.name} - ${ida.details.destination.name} OPEN`
                        : `${ida.details.origin.name} - ${ida.details.destination.name
                        } ${ida.details.departure.substring(
                          0,
                          5
                        )} ${format(new Date(ida.date), "dd MMM yyyy")}`}
                    </div>
                    {ida?.details?.stop?.length ?
                      <ul>
                        {ida.details.stop.map(stop => {
                          return (
                            <li>
                              {stop.shuttle ? stop.pickup_info["es"] :
                                stop.swap ? `Trasbordo en ${stop.place.name} a las ${stop.time.substring(0, 5)}` :
                                  `Parada en ${stop.place.name} a las ${stop.time.substring(0, 5)}`}
                            </li>
                          )
                        })}
                      </ul> : ""}
                  </>
                )}
                {((idayvuelta === "bono" && bono && bono.formentera && regreso) || (idayvuelta === "iyv" && regreso)) && (
                  <>
                    <div>
                      <b>REGRESO: </b>{" "}
                      {regreso.details.open
                        ? `${regreso.details.origin.name} - ${regreso.details.destination.name} OPEN`
                        : `${regreso.details.origin.name} - ${regreso.details.destination.name
                        } ${regreso.details.departure.substring(
                          0,
                          5
                        )} ${format(new Date(regreso.date), "dd MMM yyyy")}`}
                    </div>
                    {regreso?.details?.stop?.length ?
                      <ul>
                        {regreso.details.stop.map(stop => {
                          return (
                            <li>
                              {stop.shuttle ? stop.pickup_info["es"] :
                                stop.swap ? `Trasbordo en ${stop.place.name} a las ${stop.time.substring(0, 5)}` :
                                  `Parada en ${stop.place.name} a las ${stop.time.substring(0, 5)}`}
                            </li>
                          )
                        })}
                      </ul> : ""}
                  </>
                )}
              </div>
              <div className="text-end flex-grow-1">
                {totales && (
                  <>
                    <div>
                      {`Adulto ${adults}  x ${totales.tarifa_adult}€ = ${totales.total_adult.toFixed(2)}€`}
                    </div>
                    <div>
                      {`Niño ${children}  x ${totales.tarifa_child}€ = ${totales.total_child.toFixed(2)}€`}
                    </div>
                    {babies > 0 && (
                      <div>
                        {babies} {babies === 1 ? "bebé" : "bebés"}
                      </div>
                    )}
                    {discount > 0 && <div>
                      {`${discount}% de descuento: - ${totales.total_discount.toFixed(2)}€`}
                    </div>}
                    <div className="py-2 ps-4">
                      <h2 className="border-top d-inline">
                        <b>TOTAL {`${totales.total.toFixed(2)} €`}</b>
                      </h2>
                    </div>
                  </>)}
                <div className="d-flex justify-content-end align-items-center">
                  <Bubutton
                    variant="secondary"
                    style={{ borderRadius: "1em 0 0 1em" }}
                    className="p-3 w-50 h-100"
                    onClick={() => clean()}
                    text="Borrar"
                  >
                    <FaTrashAlt size={24} color="white" />
                  </Bubutton>
                  <Bubutton
                    style={{ borderRadius: 0, color: "white" }}
                    className="p-3 w-50 h-100"
                    variant="info"
                    onClick={doShowLinkModal}
                    text="Enlace"
                  >
                    <RiSendPlaneFill size={24} />
                  </Bubutton>
                  {userDetails.user.role.type !== "hotel" ?
                    <Bubutton
                      style={{ borderRadius: "0 1em 1em 0" }}
                      className="p-3 w-100 h-100"
                      onClick={doShowModal}
                      text="Emisión"
                    >
                      <RiShoppingCart2Fill size={24} />
                    </Bubutton>
                    : userDetails.user.blockEmission ? null :
                      <Bubutton
                        style={{ borderRadius: "0 1em 1em 0" }}
                        className="p-3 w-100 h-100"
                        onClick={doShowHotelEmitModal}
                        text="Emisión H"
                      >
                        <RiShoppingCart2Fill size={24} />
                      </Bubutton>}

                </div>
                {emitError.error && <div className="text-danger">{emitError.msg}</div>}
              </div>
            </div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Datos del cliente</Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex flex-wrap mb-2">
                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Nombre">
                      <Form.Control
                        type="text"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </FloatingLabel>
                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Apellidos">
                      <Form.Control
                        type="text"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="d-flex flex-wrap mb-2">
                    <Bubutton
                      className="mb-2 mx-2"
                      onClick={() => setEnviarMail(value => !value)}
                      text={enviarMail ? "Enviar Tarjetas por e-mail" : "No enviar tarjetas por email"}
                      variant={enviarMail ? "success" : "outline-secondary"}>
                      <FaMailBulk />
                    </Bubutton>
                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="e-mail">
                      <Form.Control
                        type="email"
                        id="email"
                        disabled={!enviarMail}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="d-flex flex-wrap mb-2">
                    <Bubutton
                      className="mb-2 mx-2"
                      onClick={() => setSendWA(value => !value)}
                      text={sendWA ? "Enviar WhatsApp" : "No enviar WhatsApp"}
                      variant={sendWA ? "success" : "outline-secondary"}>
                      <FaWhatsapp />
                    </Bubutton>
                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Teléfono">
                      <Form.Control
                        type="tel"
                        id="phone"
                        value={phone}
                        disabled={!sendWA}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="d-flex flex-wrap mb-2">
                    <Bubutton
                      className="mb-2 mx-2"
                      onClick={() => setFacturacompleta(value => !value)}
                      text={facturacompleta ? "Factura Completa" : "Factura Simplificada"}
                      variant={facturacompleta ? "success" : "outline-secondary"}>
                      <FaMailBulk />
                    </Bubutton>

                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Razón Fiscal">
                      <Form.Control
                        type="text"
                        id="razonFiscal"
                        value={razonFiscal}
                        disabled={!facturacompleta}
                        onChange={(e) => setRazonFiscal(e.target.value)}
                      />
                    </FloatingLabel>
                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="CIF">
                      <Form.Control
                        type="text"
                        id="cif"
                        value={cif}
                        disabled={!facturacompleta}
                        onChange={(e) => setCif(e.target.value)}
                      />
                    </FloatingLabel>
                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Dirección">
                      <Form.Control
                        type="text"
                        id="direccion"
                        value={direccion}
                        disabled={!facturacompleta}
                        onChange={(e) => setDireccion(e.target.value)}
                      />
                    </FloatingLabel>
                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Población">
                      <Form.Control
                        type="text"
                        id="poblacion"
                        value={poblacion}
                        disabled={!facturacompleta}
                        onChange={(e) => setPoblacion(e.target.value)}
                      />
                    </FloatingLabel>
                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Código Postal">
                      <Form.Control
                        type="text"
                        id="cp"
                        value={cp}
                        disabled={!facturacompleta}
                        onChange={(e) => setCp(e.target.value)}
                      />
                    </FloatingLabel>
                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Provincia">
                      <Form.Control
                        type="text"
                        id="provincia"
                        value={provincia}
                        disabled={!facturacompleta}
                        onChange={(e) => setProvincia(e.target.value)}
                      />
                    </FloatingLabel>
                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="País">
                      <Form.Control
                        type="text"
                        id="pais"
                        value={pais}
                        disabled={!facturacompleta}
                        onChange={(e) => setPais(e.target.value)}
                      />
                    </FloatingLabel>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {userDetails.user.role.type !== "hotel" && <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Configuración Avanzada</Accordion.Header>
                <Accordion.Body>
                  <ButtonGroup className="mb-3 mx-2 d-flex flex-wrap">
                    <Bubutton
                      style={{ width: "6em" }}
                      onClick={() => setFormadepago("invitacion")}
                      text="Invitación"
                      variant={formadepago === "invitacion" ? "success" : "secondary"}
                    >
                      <RiVipLine />
                    </Bubutton>
                    <Bubutton
                      style={{ width: "6em" }}
                      onClick={() => setFormadepago("partner")}
                      text="Partner"
                      variant={formadepago === "partner" ? "success" : "secondary"}
                    >
                      <GiThreeFriends />
                    </Bubutton>
                  </ButtonGroup>
                  <div className="d-flex flex-wrap mb-2">
                    <Bubutton
                      className="mb-2 mx-2"
                      onClick={() => setGenerarLoc(value => !value)}
                      text={generarLoc ? "Generar Localizador Aleatorio" : "Introducir Localizador Manualmente"}
                      variant={generarLoc ? "success" : "outline-secondary"}>
                      <FaMailBulk />
                    </Bubutton>
                    <FloatingLabel className="mx-2 mb-2 flex-grow-1" label="Localizador">
                      <Form.Control
                        type="text"
                        id="localizador"
                        value={localizador}
                        disabled={generarLoc}
                        onChange={(e) => setLocalizador(e.target.value)}
                      />
                    </FloatingLabel>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>}
          </div>
        )}
      </Menu>
    </>
  );
}

export default Reservar;


const makeLocalizador = (length) => {
  let result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXY0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

