import React, { useRef } from "react";
import styles from "./register.module.css";
import { Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import logo from "./logo.svg"
import axios from "axios";

function Register(props) {
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isError, setIsError] = React.useState(false);
  const { history } = props;
  const performed = useRef(false);

  const doRegister = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get("email");
    const username = formData.get("username");
    const password = formData.get("password");
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth/local/register`;
    setLoading(true);
    setErrorMessage(null);
    try {
      const response = await axios.post(url, { username, password, email })
      console.log("success", response)
      setIsError(false);
      setErrorMessage("Se ha enviado un correo electrónico con un enlace para confirmar tu dirección de correo electrónico.");
      performed.current = true;
      setLoading(false);
      history.push("/login?mail-sent=true")
    } catch (error) {
      console.log("error", error.response.data.message[0].messages[0].message)
      setIsError(true);
      setErrorMessage(error.response?.data?.message?.[0]?.messages?.[0]?.message || "Error desconocido");
      setLoading(false);
    }
  }

  return (
    <div style={{ minHeight: "100vh" }} className="d-flex justify-content-center align-items-center border">
      {loading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            position: "fixed",
            overflow: "hidden",
            zIndex: "3000",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <Spinner
            animation="border"
            role="status"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
            }}
          ></Spinner>
        </div>
      )}
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={logo} style={{ width: "56%" }} alt="logo" />
        <Form onSubmit={doRegister}>
          <Form.Group >
            <FloatingLabel className="mb-3" label="Correo Electrónico">
              <Form.Control
                type="email"
                required
                placeholder="Correo electrónico"
                id="email"
                name="email"
                disabled={loading}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group >
            <FloatingLabel className="mb-3" label="Nombre de usuario">
              <Form.Control
                type="text"
                required
                pattern="[a-zA-Z0-9]{4,20}"
                placeholder="Usuario"
                id="username"
                name="username"
                disabled={loading}
              />
              <Form.Text className="text-muted">Sin espacios, entre 6 y 20 carácteres.</Form.Text>
            </FloatingLabel>

          </Form.Group>
          <Form.Group >
            <FloatingLabel className="mb-3" label="Password">
              <Form.Control
                type="password"
                required
                placeholder="Contraseña"
                id="password"
                name="password"
                disabled={loading}
              />
            </FloatingLabel>
          </Form.Group>
          {errorMessage ? <p className={isError ? styles.error : styles.success}>{errorMessage}</p> : null}

          <Button className="w-100" type="submit" disabled={loading || performed.current}>
            Crear nueva cuenta
          </Button>

          <Button className="w-100 mt-2" variant="light" onClick={() => history.push("/login")} disabled={loading}>Volver</Button>
        </Form>
      </div>
    </div>
  );
}

export default Register;
