import { format } from "date-fns";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FaBook, FaDesktop, FaFilePdf, FaPrint, FaSignOutAlt } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { usePrinterContext, useTpvContext } from "../Context";
import Bubutton from "./Bubutton";

const FacturaModal = (props) => {
    const { factura, showModal, doHideModal } = props
    const history = useHistory();
    const { printer, printFactura, printerMode, btPrintFactura } = usePrinterContext()
    const { proxyPrintFactura } = useTpvContext()

    const thermalPrint = () => {
        if (!printerMode) return
        if (printerMode === "disabled") return
        if (printerMode === "tpv") proxyPrintFactura(factura)
        if (printerMode === "usb" && printer) printFactura(factura.booking, factura)
        if (printerMode === "bt") btPrintFactura(factura.booking, factura)
    }

    if (!factura) return <></>
    return (
        <Modal show={showModal} onHide={doHideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Factura {factura.serie}-{addZeros(factura.numero)} <Button disabled style={{ marginLeft: "1em", marginTop: "-5px" }} variant={factura.serie.slice(-1) === 'S' ? "info" : factura.serie.slice(-1) === 'C' ? "primary" : "danger"}>{factura.serie.slice(-1) === 'S' ? "Simplificada" : factura.serie.slice(-1) === 'C' ? "Completa" : "Rectificativa"}</Button></Modal.Title>
            </Modal.Header>
            <Modal.Body>

                Emisión: 📆 {format(new Date(factura.createdAt), "dd-MM-yyyy 🕓 HH:mm")} <br />
                Importe: {factura.importe} € <br />
                {factura.descuento && <div>Descuento: {factura.descuento} €</div>}
                IVA: {factura.tax} % <br />
                Forma de Pago: {factura.formadepago.formadepago}<br />
                Estado: {factura.contabilizada ? "Contabilizada" : "Pendiente"}<br />
                {factura.transaction && <>
                    Transacción: <Link to={`/transaction/${factura.transaction.id}`}>{factura.transaction.type}-{factura.transaction.transaction_id}</Link><br />
                </>}
                {factura.datosfacturacion ?
                    <>
                        <br />
                        <div>DATOS DE FACTURACIÓN:</div>
                        <div>Razón Fiscal: {factura.datosfacturacion.razonFiscal}</div>
                        <div>CIF: {factura.datosfacturacion.cif}</div>
                        <div>Dirección: {factura.datosfacturacion.direccion}</div>
                        <div>Población: {factura.datosfacturacion.poblacion}</div>
                        <div>Provincia: {factura.datosfacturacion.provincia}</div>
                        <div>Código Postal: {factura.datosfacturacion.cp}</div>
                        <div>País: {factura.datosfacturacion.pais}</div>
                    </>
                    : <></>}

                {factura.booking ?
                    <>
                        <hr></hr>
                        RESERVA:
                        👤 {factura.booking.firstName} {factura.booking.lastName} 🌍 {factura.booking.country} <br />
                        📧 {factura.booking.email} {factura.booking.phone_number && `📱 ${factura.booking.Phone_number}`} <br />
                        {factura.booking.adults} adultos, {factura.booking.children} niños, {factura.booking.babies} bebés <br />
                        <br />
                        {factura.booking.is_bono && <div>⛴ BONO</div>}
                        {factura.booking.ida ? <>{`⛴ ${factura.booking.ida.details.origin.name} - ${factura.booking.ida.details.destination.name} @ ${format(new Date(factura.booking.ida.date), "dd MMM yy")} ${factura.booking.ida.details.departure.substring(0, 5)}`} <br /></> : ""}
                    </> : <></>}
                <br />

            </Modal.Body>
            <Modal.Footer className="d-flex w-100">
                <Bubutton
                    className="m-1 w-20 h-80"
                    onClick={() => history.push(`/booking/${factura.booking.id}`)}
                    text="Reserva"
                    disabled={!factura.booking}
                >
                    <FaBook />
                </Bubutton>
                <Bubutton
                    className="w-20 h-80 pt-3"
                    href={`${process.env.REACT_APP_BACKEND_URL}/printfactura/${factura.id}`}
                    text="Descargar"
                    disabled={!factura.booking}
                >
                    <FaFilePdf />
                </Bubutton>
                <Bubutton
                    className="m-1 w-hh h-80"
                    disabled={printerMode === "disabled" || !factura.booking}
                    onClick={thermalPrint}
                    text="Imprimir"
                >
                    <FaPrint />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    variant="primary"
                    onClick={() => history.push(`/factura/${factura.id}`)}
                    text="Detalles">
                    <FaDesktop />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    variant="secondary"
                    onClick={doHideModal}
                    text="Cerrar">
                    <FaSignOutAlt />
                </Bubutton>
            </Modal.Footer>
        </Modal>
    )
}

export default FacturaModal;

const addZeros = (num) => {
    const zeros = new Array((4 - `${num}`.length) + 1).join("0")
    return `${zeros}${num}`
}