import React from "react";
import { Button } from "react-bootstrap";

const Bubutton = (props) => {
    const { children, text, ...rest } = props;
    return (
        <Button {...rest} style={{ minWidth: "92px" }}>
            <div>
                {children}
            </div>
            <div style={{ fontSize: "0.8em" }}>
                {text}
            </div>
        </Button>
    )
}

export default Bubutton;