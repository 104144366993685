import React from "react";
import { useAuthState, useAuthDispatch, loginUser } from "../../Context";
import styles from "./login.module.css";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import logo from "./logo.svg"
import { Link } from "react-router-dom";
import useSearchParams from "../../hooks/useSearchParams";
import { FaCheck } from "react-icons/fa";
import { RiMailSendLine } from "react-icons/ri";

function Login(props) {
  const { history } = props;

  const dispatch = useAuthDispatch();
  const { loading, errorMessage } = useAuthState();
  const { pushSearchParam, getParam } = useSearchParams()

  const confirmation = getParam("confirmation")
  const mailSent = getParam("mail-sent")

  const handleLogin = async (e) => {
    e.preventDefault();
    const formdata = new FormData(e.target)
    const identifier = formdata.get('identifier')
    const password = formdata.get('password')
    try {
      let response = await loginUser(dispatch, { identifier, password }); //loginUser action makes the request and handles all the neccessary state changes
      if (!response.user) return;
      history.push("/bookings"); //navigate to dashboard on success
    } catch (error) {
      console.log(error);
    }
  };

  return (

    <div style={{ minHeight: "100svh" }} className="d-flex justify-content-center align-items-center">

      {loading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            position: "fixed",
            overflow: "hidden",
            zIndex: "3000",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <Spinner
            animation="border"
            role="status"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
            }}
          ></Spinner>
        </div>
      )}
      <Modal show={confirmation} centered>
        <Modal.Header>
          <Modal.Title className="text-center w-100">¡Correo electrónico confirmado!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p><FaCheck size={50} color="green" /></p>
          <p>Gracias por registrarte en nuestro backoffice.</p>
          <p>Ahora  tenemos que activar tu usuario. Por favor, comunica a nuestro comercial que has completado el proceso de registro. También puedes comunicarlo a través de info@santaeulaliaferry.com o llamando al +34 659 22 18 93</p>
        </Modal.Body>
        <Modal.Footer er className="d-flex justify-content-center">
          <Button variant="primary" name="confirmation" value="" onClick={pushSearchParam}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={mailSent} centered>
        <Modal.Header>
          <Modal.Title className="text-center w-100">Confima tu correo electrónico</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p><RiMailSendLine size={50} color="grey" /></p>
          <p>Hemos enviado un coreo electrónico a la dirección que has utilizado para el registro con un enlace para que la confirmes.</p>
          <p>Si necesitas ayuda contacta con nostros en info@santaeulaliaferry.com o llamando al +34 659 22 18 93</p>
        </Modal.Body>
        <Modal.Footer er className="d-flex justify-content-center">
          <Button variant="primary" name="mail-sent" value="" onClick={pushSearchParam}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={logo} style={{ width: "56%" }} alt="logo" />
        <Form onSubmit={handleLogin}>
          <Form.Group >
            <FloatingLabel className="mb-3" label="Usuario">
              <Form.Control
                type="text"
                placeholder="Usuario"
                id="identifier"
                name="identifier"
                disabled={loading}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group >
            <FloatingLabel className="mb-3" label="Password">
              <Form.Control
                type="password"
                id="password"
                placeholder="Contraseña"
                name="password"
                disabled={loading}
              />
            </FloatingLabel>
          </Form.Group>
          {errorMessage ? <p className={styles.error}>{errorMessage}</p> : null}
          <Button className="w-100" type="submit" disabled={loading}>
            Sign I n
          </Button>
          <div className={styles.forgot_box}>
            <Link className={styles.forgot} to="/reset-password" >He olvidado mi contraseña.</Link>
          </div>
          <div className={styles.forgot_box}>
            <p className={styles.forgot}>

              ¿No tienes cuenta? <Link to="/register" disabled={loading}>Registrarme</Link>
            </p>
          </div>
        </Form>

      </div>
    </div>

  );
}

export default Login;
