import { format } from "date-fns";
import { logo } from "./logo64";
import { comercia_logo } from "./comercia64"


const lineChars = 27
const fontSize = 22
const isBold = false
const isUnderline = false
const align = "CENTER"
const NEW_LINE =
{
    type: "TEXT",
    text: "\n",
    align,
    fontSize,
    isBold,
    isUnderline
}

export function ticket(booking) {
    return template([...logoRows(), ...locRows(booking), ...tripRows(booking), ...passengerRows(booking), ...miniTotalsRows(booking.facturas[0]), ...companyRows(), ...enjoyRows()])
}

export function bono(booking) {
    return template([...logoRows(), ...locRows(booking), ...bonoRows(booking), ...passengerRows(booking), ...miniTotalsRows(booking.facturas[0]), ...companyRows(), ...enjoyRows()])
}

export function bonoFormentera(booking) {
    return template([...logoRows(), ...locRows(booking), ...bonoRows(booking), ...tripRows(booking), ...passengerRows(booking), ...miniTotalsRows(booking.facturas[0]), ...companyRows(), ...enjoyRows()])
}

export function facturaTicket(booking, factura, totals) {
    return template([...logoRows(), ...facturaRows(booking, factura, totals), ...companyRows(), ...thanksRows()])
}

export function liquidacionTicket(liquidacion) {
    return template(liquidacionRows(liquidacion))
}

export function transactionTicket(transaction) {
    return template(transactionRows(transaction))
}

export function errorTicket(error) {
    return template(errorRows(error))
}

function template(rows) {
    return JSON.stringify({
        typeFace: "Droid Sans Mono",
        advancePaper: true,
        letterSpacing: 0,
        grayLevel: 2,
        rows
    })
}

function logoRows() {
    const rows = [
        {
            type: "IMAGE",
            image: logo,
            align,
        },
        NEW_LINE
    ]
    return rows
}

function locRows(booking) {
    return [
        {
            type: "TEXT",
            text: "*".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: "BOARDING PASS \n TARJETA DE EMBARQUE",
            align,
            fontSize: 26,
            isBold: true,
            isUnderline
        },
        {
            type: "TEXT",
            text: booking.localizador,
            align,
            fontSize: 32,
            isBold: true,
            isUnderline
        },
    ]
}


function tripRows(booking) {
    const rows = [
        {
            type: "TEXT",
            text: "-".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        NEW_LINE,
        {
            type: "TEXT",
            text: "OUTBOUND TRIP",
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: "VIAJE DE IDA",
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: `${booking.ida.details.origin.name} - ${booking.ida.details.destination.name}`,
            align,
            fontSize: 22,
            isBold: true,
            isUnderline: true
        },
        {
            type: "TEXT",
            text: `${booking.ida.details.open ? 'OPEN TICKET' : `departure/salida: ${booking.ida.details.departure.substring(0, 5)}`}`,
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: `date/fecha: ${format(new Date(booking.ida.date), 'dd MMM yyyy')}`,
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: "\r",
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "QR",
            content: `pass-${booking.id}`,
            size: 256,
            align: "CENTER",
        },
        NEW_LINE
    ]

    if (booking.idayvuelta === "si") {
        rows.push({
            type: "TEXT",
            text: "ONE WAY TICKET",
            align,
            fontSize,
            isBold: true,
            isUnderline
        },
            {
                type: "TEXT",
                text: "BILLETE SOLO IDA",
                align,
                fontSize,
                isBold: true,
                isUnderline
            })
    } else {
        rows.push(
            {
                type: "TEXT",
                text: "RETURN TRIP",
                align,
                fontSize,
                isBold,
                isUnderline
            },
            {
                type: "TEXT",
                text: "VIAJE DE REGRESO",
                align,
                fontSize,
                isBold,
                isUnderline
            },
            {
                type: "TEXT",
                text: `${booking.vuelta.details.origin.name} - ${booking.vuelta.details.destination.name}`,
                align,
                fontSize: 22,
                isBold: true,
                isUnderline: true
            },
            {
                type: "TEXT",
                text: `${booking.vuelta.details.open ? 'OPEN TICKET' : `departure/salida: ${booking.vuelta.details.departure.substring(0, 5)}`}`,
                align,
                fontSize,
                isBold,
                isUnderline
            },
            {
                type: "TEXT",
                text: `date/fecha: ${format(new Date(booking.vuelta.date), 'dd MMM yyyy')}`,
                align,
                fontSize,
                isBold,
                isUnderline
            },
            NEW_LINE
        )
    }

    return rows
}

export function bonoRows(booking) {
    const rows = [
        {
            type: "TEXT",
            text: "-".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        NEW_LINE,
        {
            type: "TEXT",
            text: "BONO",
            align,
            fontSize: 48,
            isBold: true,
            isUnderline
        },
        {
            type: "TEXT",
            text: `${booking.bono.days} DAYS / DÍAS`,
            align,
            fontSize,
            isBold: true,
            isUnderline
        },
        {
            type: "TEXT",
            text: booking.bono.type === "PLY" ? "PLAYAS" : "IBIZA + PLAYAS",
            align,
            fontSize,
            isBold: true,
            isUnderline
        },
        NEW_LINE,
        {
            type: "QR",
            content: `bono-${booking.id}`,
            size: 256,
            align: "CENTER",
        },
        NEW_LINE,
        {
            type: "TEXT",
            text: "Trayectos / Routes:",
            align,
            fontSize,
            isBold: true,
            isUnderline
        },
        {
            type: "TEXT",
            text: booking.bono.type === "PLY" ? "Es Canar, Cala Pada, Santa Eulalia, Cala Llonga" : "Ibiza Port, Es Canar, Santa Eulalia, Cala Pada,\nCala Llonga",
            align,
            fontSize,
            isBold,
            isUnderline
        },
        NEW_LINE,
    ]
    return rows
}

function passengerRows(booking) {
    const rows = [
        {
            type: "TEXT",
            text: "-".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: "Adult/Adulto",
            rightText: booking.adults,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: "Children/Niños",
            rightText: booking.children,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: "Infant/Bebés",
            rightText: booking.babies,
            fontSize,
            isBold,
            isUnderline
        },
    ]
    if (booking.residente) {
        rows.push(
            {
                type: "TEXT",
                text: "TARIFA RESIDENTE",
                align: "LEFT",
                fontSize: 28,
                isBold: true,
                isUnderline
            },
        )
    }
    return rows
}

function enjoyRows() {
    const rows = [
        {
            type: "TEXT",
            text: "*".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        NEW_LINE,
        {
            type: "TEXT",
            text: "Enjoy your trip.",
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: "Disfrute su viaje.",
            align,
            fontSize,
            isBold,
            isUnderline
        },
        NEW_LINE
    ]
    return rows
}

function thanksRows() {
    const rows = [
        {
            type: "TEXT",
            text: "*".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        NEW_LINE,
        {
            type: "TEXT",
            text: "Gracias por su compra.",
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: "Thanks for yout purchase.",
            align,
            fontSize,
            isBold,
            isUnderline
        },
        NEW_LINE
    ]
    return rows
}

function companyRows() {
    const rows = [
        {
            type: "TEXT",
            text: "-".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: "Naviera Santa Eulalia S.L.",
            align,
            fontSize,
            isBold,
            isUnderline,
        },
        {
            type: "TEXT",
            text: "C/San Jaime n38, bajo",
            align,
            fontSize,
            isBold,
            isUnderline,
        },
        {
            type: "TEXT",
            text: "07840 Santa Eulalia del Río",
            align,
            fontSize,
            isBold,
            isUnderline,
        },
        {
            type: "TEXT",
            text: "C.I.F. B57936064",
            align,
            fontSize,
            isBold,
            isUnderline,
        },
        {
            type: "TEXT",
            text: "www.santaeulaliaferry.com",
            align,
            fontSize,
            isBold: true,
            isUnderline: true,
        },
    ]
    return rows
}

function miniTotalsRows(factura) {
    const rows = [
        {
            type: "TEXT",
            text: "*".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: factura.importe < 0 ? "REFUND/DEVOLUCIÓN" : "INVOICE/FACTURA",
            rightText: `${factura.serie}-${addZeros(factura.numero)}`,
            fontSize,
            isBold: true,
            isUnderline,
        },
        {
            type: "TEXT",
            text: factura.discount ? `Descuento ${factura.discount}%` : "",
            align: "LEFT",
            fontSize,
            isBold,
            isUnderline,
        },
        {
            type: "TEXT",
            text: `TOTAL inc.10% tax`,
            rightText: `${factura.importe.toFixed(2)}€`,
            fontSize,
            isBold,
            isUnderline,
        },
    ]
    return rows
}

function facturaRows(booking, factura, totales) {
    const rows = [
        {
            type: "TEXT",
            text: "*".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: factura.importe < 0 ? "REFUND/DEVOLUCIÓN" : "INVOICE/FACTURA",
            rightText: `${factura.serie}-${addZeros(factura.numero)}`,
            fontSize,
            isBold: true,
            isUnderline,
        },
        {
            type: "TEXT",
            text: `BOOKING/RESERVA: ${booking.localizador}`,
            align: "LEFT",
            fontSize,
            isBold,
            isUnderline,
        }]

    if (booking.is_bono)
        rows.push(
            {
                type: "TEXT",
                text: `BONO ${booking.bono.days} DAYS / DÍAS`,
                align,
                fontSize,
                isBold,
                isUnderline,
            },
            {
                type: "TEXT",
                text: `${booking.bono.type === "PLY" ? "PLAYAS" : "IBIZA + PLAYAS"}`,
                align,
                fontSize,
                isBold,
                isUnderline,
            },
        )
    if (booking.idayvuelta !== "other")
        rows.push(
            {
                type: "TEXT",
                text: `${booking.idayvuelta === "iyv" ? "Ida y vuelta" : "Solo ida"}`,
                align,
                fontSize,
                isBold,
                isUnderline,
            },
            {
                type: "TEXT",
                text: `${booking.ida.details.origin.name} - ${booking.ida.details.destination.name}`,
                align,
                fontSize,
                isBold,
                isUnderline,
            },
        )

    if (factura.importe > 0) {
        rows.push(
            NEW_LINE,
            {
                type: "TEXT",
                text: booking.residente ? "Tarifa Residente:" : "",
                align: "LEFT",
                fontSize,
                isBold,
                isUnderline,
            },
            {
                type: "TEXT",
                text: `Adult ${booking.adults}x${booking.tarifa_adult}`,
                rightText: `${totales.total_adult}€`,
                fontSize,
                isBold,
                isUnderline,
            },
            {
                type: "TEXT",
                text: `Child ${booking.children}x${booking.tarifa_child}`,
                rightText: `${totales.total_child}€`,
                fontSize,
                isBold,
                isUnderline,
            })
        if (factura.discount > 0) {
            rows.push(
                {
                    type: "TEXT",
                    text: `${factura.discount}% descuento =`,
                    rightText: `${totales.total_discount}€ `,
                    fontSize,
                    isBold,
                    isUnderline,
                },
            )
        }
    }
    rows.push(
        {
            type: "TEXT",
            text: `SUBTOTAL:    `,
            rightText: `${totales.subtotal}€ `,
            fontSize,
            isBold,
            isUnderline,
        },
        {
            type: "TEXT",
            text: `TAX/IVA ${factura.tax}%:`,
            rightText: `${totales.total_tax}€ `,
            fontSize,
            isBold,
            isUnderline,
        },
        {
            type: "TEXT",
            text: "_".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: `TOTAL:`,
            rightText: `${totales.total}€ `,
            fontSize: 28,
            isBold: true,
            isUnderline,
        },
        NEW_LINE,
    )
    return rows
}


function liquidacionRows(liquidacion) {
    const rows = [
        {
            type: "TEXT",
            text: "Santa Eulalia Ferry S.23",
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: "*".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: `Liquidación del ${format(new Date(liquidacion.createdAt), "dd MMM yy")}`,
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: `Emitido por ${liquidacion.user.username}`,
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: `a las ${format(new Date(liquidacion.createdAt), "HH:mm")}`,
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: `Nº Ventas: ${liquidacion.facturas.length}`,
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: "*".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        NEW_LINE,
    ]
    liquidacion.subliquidaciones.forEach(sub => {
        rows.push(
            {
                type: "TEXT",
                text: `${sub.formadepago}`,
                align: "LEFT",
                fontSize,
                isBold,
                isUnderline: true,
            },
            {
                type: "TEXT",
                text: `Ventas: `,
                rightText: `${(sub.simple + sub.completa).toFixed(2)}€`,
                fontSize,
                isBold,
                isUnderline,
            },
            {
                type: "TEXT",
                text: `Devoluciones: `,
                rightText: `${(sub.rectificativa).toFixed(2)}€`,
                fontSize,
                isBold,
                isUnderline,
            },
            {
                type: "TEXT",
                text: `Subtotal: `,
                rightText: `${sub.subtotal.toFixed(2)}€`,
                fontSize,
                isBold: true,
                isUnderline,
            },
            NEW_LINE
        )
    })


    if (liquidacion.comision !== 0)
        rows.push(
            {
                type: "TEXT",
                text: "Facturado",
                rightText: `${liquidacion.total.toFixed(2)}€`,
                fontSize,
                isBold,
                isUnderline,
            },
            {
                type: "TEXT",
                text: `${liquidacion.user.porcentaje_comision}% Comsión:`,
                rightText: `-${liquidacion.comision.toFixed(2)}€`,
                fontSize,
                isBold,
                isUnderline,
            })

    rows.push(
        {
            type: "TEXT",
            text: "_".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: `TOTAL`,
            rightText: `${liquidacion.importe_final.toFixed(2)}€`,
            fontSize: 24,
            isBold: true,
            isUnderline,
        },
        NEW_LINE
    )


    return rows
}


function transactionRows(transaction) {
    const fecha = new Date(transaction.ticket.Date.substring(0, 4), transaction.ticket.Date.substring(4, 6), transaction.ticket.Date.substring(6, 8), transaction.ticket.Time.substring(0, 2), transaction.ticket.Time.substring(2, 4), "0")
    return [
        {
            type: "IMAGE",
            image: comercia_logo,
            align,
        },
        NEW_LINE,
        {
            type: "TEXT",
            text: `${transaction.ticket.Type}`,
            align,
            fontSize: 24,
            isBold: true,
            isUnderline,
        },
        {
            type: "TEXT",
            text: `${transaction.ticket.Amount.substring(0, transaction.ticket.Amount.length - 2) + "." + transaction.ticket.Amount.substring(transaction.ticket.Amount.length - 2, transaction.ticket.Amount.lenght) + " " + transaction.ticket.Currency}`,
            align,
            fontSize: 24,
            isBold: true,
            isUnderline,
        },
        {
            type: "TEXT",
            text: `${transaction.ticket.CardIssuer}`,
            align,
            fontSize,
            isBold,
            isUnderline,
        },
        {
            type: "TEXT",
            text: `${format(fecha, "dd-MM-yyyy HH:mm")}`,
            align,
            fontSize,
            isBold,
            isUnderline,
        },
        NEW_LINE,
        {
            type: "TEXT",
            text: `Comercio:`,
            rightText: `${transaction.ticket.MerchantId}`,
            fontSize,
            isBold,
            isUnderline,
        },
        {
            type: "TEXT",
            text: `Terminal:`,
            rightText: `${transaction.ticket.TerminalId}`,
            fontSize,
            isBold,
            isUnderline,
        },
        {
            type: "TEXT",
            text: `Autorizacion:`,
            rightText: `${transaction.ticket.Authorization}`,
            fontSize,
            isBold,
            isUnderline,
        },
        {
            type: "TEXT",
            text: `Referencia:`,
            rightText: `${transaction.ticket.Id}`,
            fontSize,
            isBold,
            isUnderline,
        },
        {
            type: "TEXT",
            text: `Card N.:`,
            rightText: `${transaction.ticket.CardNumber}`,
            fontSize,
            isBold,
            isUnderline,
        },
        NEW_LINE
    ]
}


function errorRows(error) {
    return [
        {
            type: "TEXT",
            text: "=".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: `${error.title}`,
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: `${error.msg}`,
            align,
            fontSize,
            isBold,
            isUnderline
        },
        {
            type: "TEXT",
            text: "=".repeat(lineChars),
            align,
            fontSize,
            isBold,
            isUnderline
        }
    ]
}

const addZeros = (num) => {
    const zeros = new Array((4 - `${num}`.length) + 1).join("0")
    return `${zeros}${num}`
}