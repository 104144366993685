import React, { useReducer, useState, useEffect } from "react";
import { AuthReducer, initialState } from "./reducer";
import EscPosEncoder from "esc-pos-encoder";
import { format } from "date-fns";
import https from 'https'
import { bono, bonoFormentera, errorTicket, facturaTicket, liquidacionTicket, ticket, transactionTicket } from "./comerciaHelpers";


const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();
const PrinterContext = React.createContext();
const TpvContext = React.createContext()

export const usePrinterContext = () => {
  const context = React.useContext(PrinterContext);
  if (context === undefined) {
    throw new Error("usePrinterContext must be used within a PrinterProvider");
  }

  return context;
}

export const useTpvContext = () => {
  const context = React.useContext(TpvContext);
  if (context === undefined) {
    throw new Error("useTpvContext must be used within a TpvProvider");
  }

  return context;
}



export function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }

  return context;
}

export const AuthProvider = ({ children }) => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const PrinterProvider = ({ children }) => {
  const [printer, setPrinter] = useState();
  const [printerState, setPrinterState] = useState("No encontrada");
  const [printerMode, setPrinterMode] = useState()
  const [printCharacteristic, setPrintCharacteristic] = useState()
  const [linkedBtDevice, setLinkedBtDevice] = useState(false)

  let encoder = new EscPosEncoder();

  useEffect(() => {
    setLinkedBtDevice(JSON.parse(localStorage.getItem("linkedBtDevice")))
    setPrinterMode(localStorage.getItem("printer-mode"))
  }, []) //eslint-disable-line

  useEffect(() => {
    localStorage.setItem("printer-mode", printerMode)
    if (printerMode === "disabled") {
      setPrinter(undefined)
      setLinkedBtDevice(false)
    } else if (printerMode === "usb") {
      setLinkedBtDevice(false)
      findDevices();
    } else if (printerMode === "bt") {
      findBtDevices()
    }
  }, [printerMode]) //eslint-disable-line

  const findDevices = async () => {
    console.log("Find devices");
    if (!navigator.usb) {
      setPrinterState("Non WebUsb Browser");
    } else {
      let devices = await navigator.usb.getDevices();
      if (devices && devices.length && printer) {
        console.log("Devices", devices);
        devices.forEach((device) => {
          console.log("DEVICE : ", device);
          if (device.vendorId === 1155) {
            setPrinter(device);
            setPrinterState("MUIBYN POS-80");
          } else if (device.vendorId === 1208) {
            setPrinter(device);
            setPrinterState("EPSON TMII-20");
          } else {
            setPrinterState("Impresora no compatible");
          }
        });
      } else {
        printerConnection();
      }
    }
  };

  async function getPermittedBluetoothDevices() {
    let devices = await navigator.bluetooth.getDevices();
    for (let device of devices) {
      console.log("BT Permitted device forund ", device)
      // Start a scan for each device before connecting to check that they're in
      // range.
      let abortController = new AbortController();
      await device.watchAdvertisements({ signal: abortController.signal });
      device.addEventListener('advertisementreceived', async (evt) => {
        // Stop the scan to conserve power on mobile devices.
        abortController.abort();

        // At this point, we know that the device is in range, and we can attempt
        // to connect to it.
        const server = await evt.device.gatt.connect();
        setPrinterState("MUIBYN POS-80 via. Bluetooth");
        const service = await server.getPrimaryService("49535343-fe7d-4ae5-8fa9-9fafd205e455")
        const charactt = await service.getCharacteristic("49535343-8841-43f4-a8d4-ecbe34729bb3")
        console.log("Bluetooth Printer Connected!")
        setPrintCharacteristic(charactt);
      });
    }
  }

  const findBtDevices = () => {
    console.log("Find Bluetooth devices");
    if (!navigator.bluetooth) {
      setPrinterState("Non Web-Bluetooth compatible Browser");
      return
    }

    if (linkedBtDevice) {
      console.log("Getting BT devices")
      try {
        getPermittedBluetoothDevices()
      } catch {
        console.log("Failed to Connect to Permited Bt devices")
        setPrintCharacteristic(undefined)
        localStorage.setItem("linkedBtDevice", false)
      }
    } else {
      console.log("Requesting BT devices")
      navigator.bluetooth.requestDevice({
        filters: [{ name: "Printer001" }],
        optionalServices: ["49535343-fe7d-4ae5-8fa9-9fafd205e455"],
      })
        .then(device => {
          // Human-readable name of the device.
          console.log("Found BT device: ", device);
          setLinkedBtDevice(true)
          localStorage.setItem("linkedBtDevice", true)
          console.log("Connecting to GATT server...")
          return device.gatt.connect();
        })
        .then(server => {
          setPrinterState("MUIBYN POS-80 via. Bluetooth");
          return server.getPrimaryService("49535343-fe7d-4ae5-8fa9-9fafd205e455")
        })
        .then(service => service.getCharacteristic("49535343-8841-43f4-a8d4-ecbe34729bb3"))
        .then(characteristic => {
          // Cache the characteristic
          console.log("Bluetooth Printer Connected!")
          setPrintCharacteristic(characteristic);
        })
        .catch((err) => {
          console.log("BT FAILED", err)
          setPrintCharacteristic(undefined)
          localStorage.setItem("linkedBtDevice", false)
        });
    }
  }


  const printerConnection = async () => {
    console.log("Printer connection");
    await requestDevices();
    let devices = await navigator.usb.getDevices();
    if (devices) {
      console.log("DEVICES FOUND: ", devices);
      devices.forEach((device) => {
        console.log("DEVICE : ", device);
        if (device.vendorId === 1155) {
          setPrinter(device);
          setPrinterState("MUIBYN POS-80");
        } else if (device.vendorId === 1208) {
          setPrinter(device);
          setPrinterState("EPSON TMII-20");
        } else {
          setPrinter(device);
          setPrinterState("Impresora no implementada, podría no funcionar correctamente.");
        }
      });
    } else {
      console.log("No devices");
      setPrinterState("No encontrada");
    }
  };

  const requestDevices = async () => {
    console.log("Requiest devices");
    try {
      await navigator.usb.requestDevice({
        filters: [{}],
      });
    } catch (err) {
      console.log("NO DEVCE WAS SELECTED");
    }
  };


  const chunk = 20
  let idx = 0
  const printBtBatches = async (data) => {
    console.log("data length", data.length)
    if (idx + chunk < data.length) {
      await printCharacteristic.writeValue(data.subarray(idx, idx + chunk))
      idx += chunk
      await printBtBatches(data)
    } else {
      await printCharacteristic.writeValue(data.slice(idx, data.length))
    }
  }

  const btPrintTicket = async (booking) => {
    idx = 0
    const escpos = await encodeTicket(booking)
    await printBtBatches(escpos)
  }


  const printTicket = async (booking) => {
    const escpos = await encodeTicket(booking)
    await printer.open();
    await printer.selectConfiguration(1);
    await printer.claimInterface(0);
    await printer.transferOut(1, escpos);
    await printer.close();
  }

  const encodeTicket = async (booking) => {
    console.log("PRINTINT BOOKING", booking)
    const img = await loadImage('/logo_tiny.jpg')

    encoder.codepage('cp437')
      .align("center")
      .image(img, 480, 224)
      .newline()
      .line("*".repeat(48))
      .line(`Boarding Pass / Tarjeta de embarque`)
      .newline()
      .bold().raw([[0x1b, 0x21, 0x30]]).line(`${booking.localizador}`).bold().raw([0x1b, 0x21, 0x00])
      .line("-".repeat(48))
      .newline()

    if (booking.is_bono)
      encoder.bold().raw([[0x1b, 0x21, 0x30]]).line("BONO").bold().raw([0x1b, 0x21, 0x00])
        .line(`${booking.bono.days} Days/Días`)
        .line(booking.bono.type === "PLY" ? "BEACHES/PLAYAS" : "IBIZA + BEACHES/PLAYAS")
        .qrcode(`bono-${booking.id}`, 2, 8, "h")
        .newline()
        .line("Routes/Trayectos:")
        .line(booking.bono.type === "PLY" ? "Es Canar, Cala Pada,\n Santa Eulalia, Cala Llonga" : "Ibiza Port, Es Canar, Santa Eulalia,\n Cala Pada, Cala Llonga")
        .newline()
        .line("-".repeat(48))

    if (booking.idayvuelta !== "other")
      encoder
        .newline()
        .line('OUTBOUND TRIP')
        .line('VIAJE DE IDA')
        .bold().raw([0x1b, 0x21, 0x10]).line(`${booking.ida.details.origin.name} - ${booking.ida.details.destination.name}`).bold().raw([0x1b, 0x21, 0x00])
        .line(`${booking.ida.details.open ? 'OPEN TICKET' : `departure/salida: ${booking.ida.details.departure.substring(0, 5)}`}`)
        .line(`date/fecha: ${format(new Date(booking.ida.date), 'dd MMM yyyy')}`)
        .qrcode(`${booking.is_bono ? "bonf-" : "trip-"}${booking.id}`, 2, 8, "h")
        .newline();


    if (booking.idayvuelta === "iyv")
      encoder
        .line('RETURN TRIP')
        .line('VIAJE DE REGRESO')
        .bold().raw([0x1b, 0x21, 0x10]).line(`${booking.vuelta.details.origin.name} - ${booking.vuelta.details.destination.name}`).bold().raw([0x1b, 0x21, 0x00])
        .line(`${booking.vuelta.details.open ? 'OPEN TICKET' : `departure/salida: ${booking.vuelta.details.departure.substring(0, 5)}`}`)
        .line(`date/fecha: ${format(new Date(booking.vuelta.date), 'dd MMM yyyy')}`)
        .newline()
        .line("-".repeat(48))
    else if (booking.idayvuelta === 'si') encoder.line('ONE WAY TICKET').line('BILLETE SOLO IDA').line("-".repeat(48)).newline();


    encoder
      .newline().line(`${booking.adults} Adult/Adulto`);
    if (booking.children > 0) encoder.line(`${booking.children} Child/Niños`)
    if (booking.babies > 0) encoder.line(`${booking.babies} Baby/Bebés`)
    if (booking.firstName) encoder.line(`${booking.firstName} ${booking.lastName ? booking.lastName : ''}`);
    if (booking.residente) encoder.line('RESIDENTE');

    const factura = booking.facturas?.[0]
    if (factura) {
      encoder.newline().line("*".repeat(48))
        .line(factura.importe < 0 ? `REFUND / DEVOLUCIÓN ${factura.serie}-${addZeros(factura.numero)}` : `INVOICE / FACTURA ${factura.serie}-${addZeros(factura.numero)}`)
      if (factura.discount)
        encoder.line(`DISCOUNT/DESCUENTO ${factura.discount}%`)
      encoder.text('TOTAL')
        .size('small')
        .text("(inc.10%TAX)")
        .size('normal')
        .text(":")
        .bold()
        .text(`   ${factura.importe.toFixed(2)}`)
        .bold()
        .raw([0xEE])
        .newline()
        .line("-".repeat(48))
        .size('small')
        .line("www.santaeulaliaferry.com info@santaeulaliaferry.com")
        .line("Naviera Santa Eulalia S.L. - CIF:B57936064")
        .line("C/San Jaime n38, 07830 Santa Eulalia del Río, Baleares")
        .size('normal')
    }

    encoder.line("*".repeat(48)).newline()
      .line('Enjoy your trip.')
      .line('Disfrute su viaje.').newline().newline().newline().newline().newline().cut();

    return encoder.encode()
  }


  const loadImage = async (src) => {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      img.src = src
    })
  }

  const printFactura = async (b, factura) => {
    const escpos = await encodeFactura(b, factura)
    await printer.open();
    await printer.selectConfiguration(1);
    await printer.claimInterface(0);
    await printer.transferOut(1, escpos);
    await printer.close();
  }

  const printLiquidacion = async (liquidacion) => {
    const escpos = await encodeLiquidacion(liquidacion)
    await printer.open();
    await printer.selectConfiguration(1);
    await printer.claimInterface(0);
    await printer.transferOut(1, escpos);
    await printer.close();
  }


  const btPrintFactura = async (b, factura) => {
    idx = 0
    const escpos = await encodeFactura(b, factura)
    await printBtBatches(escpos)
  }

  const btPrintLiquidacion = async (liquidacion) => {
    idx = 0
    const escpos = await encodeLiquidacion(liquidacion)
    await printBtBatches(escpos)
  }


  const encodeFactura = async (b, factura) => {
    console.log("PRINTING FACTURA", factura)
    const totales = totalizacion(b, factura);
    console.log("Totalización", totales);
    encoder.codepage('cp437')
    if (factura.formadepago.formadepago !== "comercia") {
      encoder
        .newline()
        .align("left")
        .line(`Forma de Pago: ${factura.formadepago.formadepago}`)
    } else if (factura.transaction?.type === "comercia") {
      const transaction = factura.transaction.transaction
      console.log("PRINTING TRANSACTION", transaction)
      const fecha = new Date(transaction.ticket.Date.substring(0, 4), transaction.ticket.Date.substring(4, 6), transaction.ticket.Date.substring(6, 8), transaction.ticket.Time.substring(0, 2), transaction.ticket.Time.substring(2, 4), "0")
      const img = await loadImage('/comercia_logo.png')
      encoder
        .image(img, 368, 176)
        .newline()
        .align("center")
        .bold().line(`${transaction.ticket.Type}`).bold()
        .bold().line(`${transaction.ticket.Amount.substring(0, transaction.ticket.Amount.length - 2) + "." + transaction.ticket.Amount.substring(transaction.ticket.Amount.length - 2, transaction.ticket.Amount.lenght) + " " + transaction.ticket.Currency}`).bold()
        .line(transaction.ticket.CardIssuer)
        .line(format(fecha, "dd-MM-yyyy HH:mm"))
        .newline()
        .line(`N.Autorizacion: ${transaction.ticket.Authorization}`)
        .line(`N.Referencia: ${transaction.ticket.Id}`)
        .line(`Card N. ${transaction.ticket.CardNumber}`)
        .newline()

    }

    encoder
      .line("=".repeat(48))
      .newline()
      .align('left')
      .bold().line(`${factura.importe > 0 ? 'INVOICE / FACTURA:' : 'REFUND / DEVOLUCIÓN:'} ${factura.serie}-${addZeros(factura.numero)}`).bold()
      .line("Naviera Santa Eulalia S.L.")
      .line("C/ San Jaime n38, bajos")
      .line("07840, Santa Eulalia del Río")
      .line("C.I.F. B57936064")
      .newline()
      .line(`Date/Fecha: ${format(new Date(factura.createdAt), "dd-MM-yyyy HH:mm")}`)
      .line(`Booking Nº: ${b.localizador}`)
      .newline();
    if (b.is_bono)
      encoder
        .align("center")
        .line("BONO")
        .line(`${b.bono.days} Days / Días`)
        .line(b.bono.type === "PLY" ? "PLAYAS" : "IBIZA + PLAYAS")
        .line(b.bono.fomentera ? "+" : "")
    if (b.idayvuelta !== "other")
      encoder
        .line(b.idayvuelta === "iyv" ? "Ida y vuelta" : "Solo ida")
        .line(`${b.ida.details.origin.name} - ${b.ida.details.destination.name}`)
    encoder.newline();

    if (factura.importe > 0) {
      const adultLeft = `${b.residente ? "Adult (R) " : "Adult "}${b.adults} x ${b.tarifa_adult}`
      const adultRight = `${totales.total_adult}`
      const adultSpace = 48 - 4 - adultLeft.length - adultRight.length
      encoder.text(`${adultLeft}`).raw([0xEE]).text(" =").text(`${" ".repeat(adultSpace)}${adultRight}`).raw([0xEE]).newline()
      const childLeft = `${b.residente ? "Child (R) " : "Child "}${b.children} x ${b.tarifa_child}`
      const childRight = `${totales.total_child}`
      const childSpace = 48 - 4 - childLeft.length - childRight.length
      encoder.text(`${childLeft}`).raw([0xEE]).text(" =").text(`${" ".repeat(childSpace)}${childRight}`).raw([0xEE]).newline()
    }
    if (factura.discount > 0) {
      const discountLeft = `${factura.discount}% descuento = `
      const discountRight = `-${totales.total_discount}`
      const discountSpace = 48 - 1 - discountLeft.length - discountRight.length
      encoder.text(`${discountLeft}${" ".repeat(discountSpace)}${discountRight}`).raw([0xEE]).newline()
    }
    const subtotalLeft = `SUBTOTAL =`
    const subtotalRight = `${totales.subtotal}`
    const subtotalSpace = 48 - 1 - subtotalLeft.length - subtotalRight.length
    encoder.text(`${subtotalLeft}${" ".repeat(subtotalSpace)}${subtotalRight}`).raw([0xEE]).newline()
    const ivaLeft = `IVA ${factura.tax}% =`
    const ivaRight = `${totales.total_tax}`
    const ivaSpace = 48 - 1 - ivaLeft.length - ivaRight.length
    encoder.text(`${ivaLeft}${" ".repeat(ivaSpace)}${ivaRight}`).raw([0xEE]).newline().line("-".repeat(48))
    const totalLeft = `TOTAL =`
    const totalRight = `${totales.total.toFixed(2)}`
    const totalSpace = 48 - 1 - totalLeft.length - totalRight.length
    encoder.text(`${totalLeft}${" ".repeat(totalSpace)}${totalRight}`).raw([0xEE]).newline()
    encoder
      .newline()
      .line("=".repeat(48))
      .align("center")
      .line('Thanks for your purchase.')
      .line('Gracias por su compra.')
      .newline()
      .newline()
      .newline()
      .newline()
      .newline()
      .newline()
      .cut()

    return encoder.encode()
  }

  const encodeLiquidacion = async (liquidacion) => {
    console.log("PRINTING LIQUIDACION")
    encoder.codepage('cp437')

    encoder
      .align("center")
      .line("Santa Eulalia Ferry S.23")
      .line("*".repeat(48))
      .line(`Liquidacion del ${format(new Date(liquidacion.createdAt), "dd MMM yy")}`)
      .line(`Emitido por ${liquidacion.user.username}`)
      .line(`a las ${format(new Date(liquidacion.createdAt), "HH:mm")}`)
      .line(`Nº Ventas: ${liquidacion.facturas.length}`)
      .line("*".repeat(48))
      .newline()

    liquidacion.subliquidaciones.forEach(sub => {
      encoder
        .align("right")
        .underline().line(`${sub.formadepago}`).underline()
        .text(`Ventas: ${(sub.simple + sub.completa).toFixed(2)}`).raw([0xEE]).newline()
        .text(`Devoluciones: ${(sub.rectificativa).toFixed(2)}`).raw([0xEE]).newline()
        .text(`Subtotal: ${sub.subtotal.toFixed(2)}`).raw([0xEE]).newline()
        .newline()
    })

    if (liquidacion.comision !== 0)
      encoder
        .text(`Facturado ${liquidacion.total.toFixed(2)}`).raw([0xEE]).newline()
        .text(`${liquidacion.user.porcentaje_comision}% Comsión: -${liquidacion.comision.toFixed(2)}`).raw([0xEE]).newline()

    encoder
      .line("_".repeat(48))
      .text(`TOTAL: ${liquidacion.importe_final.toFixed(2)}`).raw([0xEE]).newline()
      .newline()
      .newline()
      .newline()
      .newline()
      .newline()
      .newline()
      .newline()
      .cut()

    return encoder.encode()
  }

  return (
    <PrinterContext.Provider value={{ printer, printerState, findDevices, setPrinterMode, printerMode, printTicket, printFactura, printLiquidacion, btPrintTicket, btPrintFactura, btPrintLiquidacion, printCharacteristic }}>
      {children}
    </PrinterContext.Provider>
  );
};


export const TpvProvider = ({ children }) => {
  const [ipAddress, setIpAddress] = useState("")
  const [port, setPort] = useState("")
  const [status, setStatus] = useState()
  const [printTrans, setPrintTrans] = useState(false)


  const doInitialization = async () => {
    setStatus("loading")
    const url = `https://${ipAddress}:${port}/v1/transactions/init`
    const body = JSON.stringify({ user: "user", pass: "pass" })
    console.log("Comercia Payment Services initialization at ...", url)
    setStatus(`Comercia Payment Services initialization at ${url}`)
    try {
      const response = await fetch(url, {
        httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        method: "POST",
        headers: { "X-SOURCE": "COMERCIA" },
        body
      })
      if (!response.ok) throw new Error("Failed to initialize")
      const data = await response.json()
      console.log("Init response: ", data)
      if (data.resultCode !== 1000) {
        console.log("Initialization Failed")
        setStatus("Initialization Failed")
      } else {
        console.log("Initialization Succeed")
        setStatus("initialized")
      }
    } catch (err) {
      console.log("Initialization Failed")
      setStatus("Initialization Failed")
    }
  }

  useEffect(() => {
    if (ipAddress) return
    setIpAddress(localStorage.getItem("tpv-ip-address", "localhost"))
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!ipAddress || !port) return
    if (ipAddress.length > 9) doInitialization()
  }, [ipAddress, port]) //eslint-disable-line

  useEffect(() => {
    localStorage.setItem("tpv-ip-address", ipAddress)
  }, [ipAddress]) //eslint-disable-line

  useEffect(() => {
    if (port) return
    setPort(localStorage.getItem("tpv-port-address", "2001"))
  }, []) //eslint-disable-line

  useEffect(() => {
    if (port) return
    setPort(localStorage.getItem("tpv-port-address", "2001"))
  }, []) //eslint-disable-line

  useEffect(() => {
    localStorage.setItem("tpv-port-address", port)
  }, [port]) //eslint-disable-line

  useEffect(() => {
    setPrintTrans(JSON.parse(localStorage.getItem("tpv-print", "true")))
  }, []) //eslint-disable-line

  useEffect(() => {
    localStorage.setItem("tpv-print", JSON.stringify(printTrans))
  }, [printTrans]) //eslint-disable-line


  const cancelComercia = async () => {
    const url = `https://${ipAddress}:${port}/v1/transactions/cancel`
    try {
      const res = await fetch(url, {
        httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        method: "GET",
        headers: { "X-SOURCE": "COMERCIA" },
      })
      const data = await res.json()
      console.log("Comercia Cancell", data)
    } catch (err) {
      console.log("Comercia Cancell Error", err)
    }
  }

  const scanComercia = async () => {
    const url = `https://${ipAddress}:${port}/v1/device/scanner`
    try {
      const res = await fetch(url, {
        httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        method: "POST",
        headers: { "X-SOURCE": "COMERCIA" },
        body: JSON.stringify({ width: 300, heigth: 500 })
      })
      const data = await res.json()
      console.log("Comercia Scanner", data)
      if (data.resultCode === 0) {
        return data.data
      } else throw new Error("Failed scanning")
    } catch (err) {
      console.log("Comercia Scanner Error", err)
    }

  }


  const emitirComercia = async (totales, localizador) => {
    const url = `https://${ipAddress}:${port}/v1/transactions/payment`
    const body = JSON.stringify({ orderId: uuidv4(), amount: parseFloat(totales.total), description: `${localizador}` })
    console.log("Comercia Payment Services Payemnt ...", url, body)
    try {
      const res = await fetch(url, {
        httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        method: "POST",
        headers: { "X-SOURCE": "COMERCIA" },
        body
      })
      const data = await res.json()
      if (data.resultCode === 0) {
        console.log("TRANS", data)
        if (printTrans) await comerciaPrint(transactionTicket(data))
        return { ok: true, transaction: data }
      } else {
        console.log("DENEGADO", data)
        await comerciaPrint(errorTicket({ title: "DENEGADO", msg: data.resultMessage }))
        return { ok: false, transaction: data }
      }
    } catch (err) {
      await comerciaPrint(errorTicket({ title: "ERROR", msg: err.message?.substring(0, 60) }))
      console.log("PAYMENT ERROR", err)
      return { ok: false, transaction: { resultMessage: "Conexion Error" } }
    }
  }

  const comerciaRefund = async (transactionId, refundAmount, orderId, description) => {
    const url = `https://${ipAddress}:${port}/v1/transactions/refund`
    const body = JSON.stringify({ transactionId, amount: parseFloat(refundAmount), orderId, description })
    console.log("Comercia Payment Services REFUND ...", url, body)
    try {
      const res = await fetch(url, {
        httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        method: "POST",
        headers: { "X-SOURCE": "COMERCIA" },
        body
      })
      const data = await res.json()
      if (data.resultCode === 0) {
        console.log("TRANS", data)
        if (printTrans) await comerciaPrint(transactionTicket(data))
        return { ok: true, transaction: data }
      } else {
        console.log("DENEGADO", data)
        if (printTrans) await comerciaPrint(errorTicket({ title: "DENEGADO", msg: data.resultMessage }))
        return { ok: false, transaction: data }
      }
    } catch (err) {
      if (printTrans) await comerciaPrint(errorTicket({ title: "ERROR", msg: err.message?.substring(0, 60) }))
      console.log("REFUND ERROR", err)
      return { ok: false, transaction: { resultMessage: "Fatal Error" } }
    }
  }

  const comerciaLastTrans = async () => {
    const url = `https://${ipAddress}:${port}/v1/transactions/last`
    console.log("Comercia Payment Services RETRIEVE LAS TRANSACTION ...", url)
    try {
      const res = await fetch(url, {
        httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        method: "GET",
        headers: { "X-SOURCE": "COMERCIA" }
      })
      const data = await res.json()
      if (data.resultCode === 0) {
        console.log("TRANS", data)
        if (printTrans) await comerciaPrint(transactionTicket(data))
        return { ok: true, transaction: data }
      } else {
        console.log("DENEGADO", data)
        if (printTrans) await comerciaPrint(errorTicket({ title: "DENEGADO", msg: data.resultMessage }))
        return { ok: false, transaction: data }
      }
    } catch (err) {
      if (printTrans) await comerciaPrint(errorTicket({ title: "ERROR", msg: err.message?.substring(0, 60) }))
      console.log("LAST TRANS ERROR", err)
      return { ok: false, transaction: { resultMessage: "Fatal Error" } }
    }
  }

  const proxyPrintTickets = async (booking) => {
    //comercia print
    let text;
    if (booking.is_bono) {
      if (booking.bono.formentera) text = bonoFormentera(booking)
      else text = bono(booking)
    } else {
      text = ticket(booking)
    }
    await comerciaPrint(text)
  }

  const proxyPrintFactura = async (factura) => {
    const totals = totalizacion(factura.booking, factura)
    const text = facturaTicket(factura.booking, factura, totals)
    await comerciaPrint(text)
  }

  const proxyPrintLiquidacion = async (liquidacion) => {
    console.log("proxy print liquidacion", liquidacion)
    const text = liquidacionTicket(liquidacion)
    await comerciaPrint(text)
  }

  const [printerLock, setPrinterLock] = useState(false)
  const comerciaPrint = async (body) => {
    if (printerLock) return { ok: false }
    const url = `https://${ipAddress}:${port}/v1/device/printer`
    try {
      setPrinterLock(true)
      const res = await fetch(url, {
        httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        method: "POST",
        headers: { "X-SOURCE": "COMERCIA" },
        body,
      })
      console.log("printer res", res)
      if (!res.ok) throw new Error("Comercia Proxy REFUND failed")
      const data = await res.json()
      console.log("printer res.json:", data)

    } catch (err) {
      console.log("printer err", err)

    } finally { setPrinterLock(false) }
  }

  return (
    <TpvContext.Provider value={{ ipAddress, setIpAddress, port, setPort, status, doInitialization, emitirComercia, comerciaRefund, comerciaLastTrans, cancelComercia, proxyPrintTickets, proxyPrintFactura, proxyPrintLiquidacion, printTrans, setPrintTrans, scanComercia }}>
      {children}
    </TpvContext.Provider>
  );
};

const totalizacion = (booking, factura) => {
  const total_adult = booking.adults * booking.tarifa_adult
  const total_child = booking.children * booking.tarifa_child
  const total_discount = ((total_child + total_adult) * factura.discount) / 100;
  const total_tax = (factura.importe * factura.tax) / 100;
  return {
    total_adult: total_adult.toFixed(2),
    total_child: total_child.toFixed(2),
    total_discount: total_discount.toFixed(2),
    subtotal: (factura.importe - total_tax).toFixed(2),
    total_tax: total_tax.toFixed(2),
    total: factura.importe,
  };
}

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );
};


const addZeros = (numero) => {
  const num = parseInt(numero)
  if (num < 10) return `0000${num}`
  if (num < 100) return `000${num}`
  if (num < 1000) return `00${num}`
  if (num < 10000) return `0${num}`
  else return `${num}`
}