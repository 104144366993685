import { useLocation, useHistory } from "react-router-dom";

const useSearchParams = () => {

    const location = useLocation()
    const history = useHistory()

    const pushSearchParam = (event) => {
        const { name, value } = event?.target;
        const params = new URLSearchParams(location.search);
        if (params.has(name)) {
            if (value === "") params.delete(name)
            else params.set(name, value)
        } else {
            if (value !== "") params.append(name, value);
        }
        history.replace({ pathname: location.pathname, search: params.toString() });
    }

    const getParam = (name) => new URLSearchParams(location.search).get(name)

    return { pushSearchParam, getParam }
}

export default useSearchParams; 