import React from "react";
import { useParams, Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import { useAuthState, usePrinterContext, useTpvContext } from "../../Context";
import { Button, Spinner, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import axios from "axios";
import { format } from "date-fns";
import { FaFilePdf, FaPrint } from "react-icons/fa";
import Bubutton from "../../Components/Bubutton";

function Factura() {
  const userDetails = useAuthState();
  const id = useParams().id;
  const { printer, printFactura, printerMode, btPrintFactura } = usePrinterContext()
  const { proxyPrintFactura } = useTpvContext()


  const fetchFactura = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/facturas/${id}`;
    const { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    });

    console.log("FACTURA", data)
    return data
  };
  const factura = useQuery(['factura', id], fetchFactura)



  const thermalPrint = () => {
    if (!printerMode) return
    if (printerMode === "disabled") return
    if (printerMode === "tpv") proxyPrintFactura(factura.data)
    if (printerMode === "usb" && printer) printFactura(factura.data.booking, factura.data)
    if (printerMode === "bt") btPrintFactura(factura.data.booking, factura.data)
  }


  return (
    <>
      <Menu>
        {(factura.isLoading) ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              position: "fixed",
              overflow: "hidden",
              zIndex: "3000",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
              }}
            ></Spinner>
          </div>
        ) : (
          factura.isSuccess && (
            <div className="p-2">
              <h1>
                FACTURA {" "}
                {factura.data.serie}-{addZeros(factura.data.numero)}
                <Button
                  disabled
                  style={{ marginLeft: "1em", marginTop: "-5px" }}
                  variant={factura.data.serie.slice(-1) === 'S' ? "info" : factura.data.serie.slice(-1) === 'C' ? "primary" : "danger"}>
                  {factura.data.serie.slice(-1) === 'S' ? "Simplificada" : factura.data.serie.slice(-1) === 'C' ? "Completa" : "Rectificativa"}
                </Button>
              </h1>
              <Row>
                <div className="border p-2 col-lg-3 col-md-4 col-sm-6">
                  <h4>Detalle:</h4>

                  <div>
                    <b>Serie: </b>
                    {factura.data.serie}
                  </div>
                  <div>
                    <b>Número: </b>
                    {factura.data.numero}
                  </div>
                  <div>
                    <b>Emisión: </b>
                    <div className="mx-3">
                      {format(new Date(factura.data.createdAt), "📆 dd MMM yyyy")} <br />
                      {format(new Date(factura.data.createdAt), "🕓 HH:mm:ss")}
                    </div>
                  </div>
                  <div>
                    <b>Importe: </b>
                    {factura.data.importe}€
                  </div>
                  <div>
                    <b>IVA: </b>
                    {factura.data.tax}%
                  </div>
                  <div>
                    <b>Descuento: </b>
                    {factura.data.discount}%
                  </div>
                  <div>
                    <b>Forma de pago: </b>
                    {factura.data.formadepago.formadepago}
                  </div>
                  {factura.data.user &&
                    <div>
                      <b>Vendedor: </b>
                      {factura.data.user.username}
                    </div>}
                  <div>
                    <b>Estado: </b>
                    {factura.data.contabilizada ? "Contabilizada" : "Pendiente"}
                  </div>
                  {factura.data.liquidacion &&
                    <div>
                      <b>Liquidación: </b>
                      <Link to={`/liquidacion/${factura.data.liquidacion}`}>
                        {`por ${factura.data.user.username}`}
                      </Link>
                    </div>}
                  {factura.data.booking && <div>
                    <b>Reserva: </b>
                    <Link to={`/booking/${factura.data.booking.id}`}>
                      {factura.data.booking.localizador}
                    </Link>
                  </div>}
                  {factura.data.rectificada &&
                    <div>
                      <b className="text-danger">Rectificada por: </b>
                      <Link className="text-danger" to={`/factura/${factura.data.rectificada.id}`}>
                        {factura.data.rectificada.serie}-{addZeros(factura.data.rectificada.numero)}
                      </Link>
                    </div>}
                  {factura.data.rectifica &&
                    <div>
                      <b>Rectifica a: </b>
                      <Link to={`/factura/${factura.data.rectifica.id}`}>
                        {factura.data.rectifica.serie}-{addZeros(factura.data.rectifica.numero)}
                      </Link>
                    </div>}
                  {factura.data.transaction &&
                    <div>
                      <b>Transacción: </b>
                      <Link to={`/transaction/${factura.data.transaction.id}`}>
                        {factura.data.transaction.type} {factura.data.transaction.transaction_id}
                      </Link>
                    </div>}
                </div>
                {factura.data.datosfacturacion && (
                  <div className="border p-2 col-lg-3 col-md-4 col-sm-6">
                    <h4>Datos del cliente:</h4>
                    <div>Razón Fiscal: {factura.data.datosfacturacion.razonFiscal}</div>
                    <div>CIF: {factura.data.datosfacturacion.cif}</div>
                    <div>Dirección: {factura.data.datosfacturacion.direccion}</div>
                    <div>Población: {factura.data.datosfacturacion.poblacion}</div>
                    <div>Provincia: {factura.data.datosfacturacion.provincia}</div>
                    <div>Código Postal: {factura.data.datosfacturacion.cp}</div>
                    <div>País: {factura.data.datosfacturacion.pais}</div>
                  </div>
                )}

                <div className="border p-2 col-lg-3 col-md-4 col-sm-6">
                  <h4>Operaciones:</h4>
                  <Bubutton
                    className="m-1 w-hh h-80 pt-3"
                    href={`${process.env.REACT_APP_BACKEND_URL}/printfactura/${factura.data.id}`}
                    text="Descargar"
                    disabled={!factura.data.booking}
                  >
                    <FaFilePdf />
                  </Bubutton>
                  <Bubutton
                    className="m-1 w-hh h-80"
                    disabled={printerMode === "disabled" || !factura.data.booking}
                    onClick={thermalPrint}
                    text="Imprimir"
                  >
                    <FaPrint />
                  </Bubutton>
                </div>
              </Row>
            </div>
          )
        )}
      </Menu>
    </>
  );
}

export default Factura;


const addZeros = (num) => {
  const zeros = new Array((4 - `${num}`.length) + 1).join("0")
  return `${zeros}${num}`
}