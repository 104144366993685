import React from "react";
import {
    Table,
    Button,
} from "react-bootstrap";
import { format } from "date-fns";


const BookingsTable = ({ bookings, sorting, sortWay, sortBy, currentPage, setCurrentPage, doShowModal, PER_PAGE }) => {
    return (
        <Table responsive>
            <thead>
                <tr>
                    <th>Loc.</th>
                    <th>Status</th>
                    <th>e-mail</th>
                    <th>Nombre</th>
                    <th style={{ cursor: "pointer" }} onClick={() => sortBy("createdAt")}>Compra {sorting === "createdAt" ? sortWay === "DESC" ? "▼" : "▲" : ""}</th>
                    <th>Ruta</th>
                    <th style={{ cursor: "pointer" }} onClick={() => sortBy("ida.date")}>Ida {sorting === "ida.date" ? sortWay === "DESC" ? "▼" : "▲" : ""}</th>
                    <th>Regr.</th>
                    <th>👪</th>
                </tr>
            </thead>
            <tbody>
                {bookings.map((booking, index) => {
                    if (index < PER_PAGE)
                        return (
                            <tr key={index} onClick={() => doShowModal(index)} style={{ cursor: "pointer" }}>
                                <td data-cell="Localizador" className="loc-data-cell">{booking.localizador}</td>
                                <td data-cell="Status">
                                    <Button disabled style={{ marginLeft: "1em", marginTop: "-5px", paddingBlock: "0px" }} variant={booking.status === "confirmed" ? "success" : booking.status === "modified" ? "info" : booking.status === "failed" ? "secondary" : booking.status === "cancelled" ? "warning" : booking.status === "refunded" ? "danger" : "light"}>{booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}</Button>
                                </td>
                                <td data-cell="e-mail" className="mail-data-cell">
                                    {booking.email}
                                </td>
                                <td data-cell="Nombre" className="name-data-cell">
                                    {booking.firstName} {booking.lastName}
                                </td>
                                <td data-cell="Compra">
                                    {format(
                                        new Date(booking.createdAt),
                                        "dd-MM-yyyy 🕓 HH:mm"
                                    )}
                                </td>
                                <td data-cell="Ruta">
                                    {booking.is_bono ? `${booking.bono.days}D-${booking.bono.type}${booking.bono.formentera ? "+F" : ""}` : booking.ida ? `${booking.ida.details.origin.code} - ${booking.ida.details.destination.code}` : ""}
                                </td>
                                <td data-cell="Viaje">
                                    {booking.ida && `${format(
                                        new Date(booking.ida.date),
                                        "dd-MM-yyyy"
                                    )} ${booking.ida.details.open ? "OPEN" : booking.ida.details.departure.substring(0, 5)}`}
                                </td>
                                <td data-cell="Regr.">
                                    {booking.vuelta && `${format(
                                        new Date(booking.vuelta.date),
                                        "dd-MM-yyyy"
                                    )} ${booking.vuelta.details.open ? "OPEN" : booking.vuelta.details.departure.substring(0, 5)}`}
                                </td>
                                <td data-cell="Pax">{booking.adults + booking.children + booking.babies}</td>

                            </tr>
                        )
                    else return null
                })}
            </tbody>

            <tfoot>
                <tr>
                    <td colSpan={12} style={{ textAlign: "center" }}>
                        <Button
                            disabled={currentPage === 0}
                            onClick={() => setCurrentPage(value => value - 1)}
                        >
                            {"<"}
                        </Button>
                        <Button disabled variant="secondary" className="mx-1">
                            {`Pag. ${currentPage + 1}`}
                        </Button>
                        <Button
                            onClick={() => setCurrentPage(value => value + 1)}
                            disabled={bookings.length <= PER_PAGE}
                        >
                            {">"}
                        </Button>
                    </td>
                </tr>
            </tfoot>
        </Table>
    )
}

export default BookingsTable;
