import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import { useAuthState, usePrinterContext, useTpvContext } from "../../Context";
import { Spinner, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import axios from "axios";
import { format } from "date-fns";
import Bubutton from "../../Components/Bubutton";
import { FaBalanceScaleLeft, FaFilePdf, FaPrint } from "react-icons/fa";

function Liquidacion() {
  const userDetails = useAuthState();
  const { printer, printLiquidacion, printerMode, btPrintLiquidacion } = usePrinterContext();
  const { proxyPrintLiquidacion } = useTpvContext()
  const [loading, setLoading] = useState(false);


  const id = useParams().id;

  const fetchLiquidacion = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/liquidacions/${id}`;
    const { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    });

    console.log("liquidacion", data)
    return data
  };



  const liquidacion = useQuery(['liquidacion', id], fetchLiquidacion)

  const print = () => {
    if (!printerMode) return
    if (printerMode === "disabled") return
    if (printerMode === "tpv") proxyPrintLiquidacion(liquidacion.data)
    if (printerMode === "usb" && printer) printLiquidacion(liquidacion.data)
    if (printerMode === "bt") btPrintLiquidacion(liquidacion.data)
  }

  const doContabilizar = async () => {
    setLoading(true)
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/contabilizar/${liquidacion.data.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userDetails.token}`,
        }
      }
    );
    liquidacion.refetch()
    setLoading(false)
  };

  const doDesContabilizar = async () => {
    setLoading(true)
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/descontabilizar/${liquidacion.data.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userDetails.token}`,
        }
      }
    );
    liquidacion.refetch()
    setLoading(false)
  };


  return (
    <>
      <Menu>
        {(liquidacion.isLoading) ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              position: "fixed",
              overflow: "hidden",
              zIndex: "3000",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
              }}
            ></Spinner>
          </div>
        ) : (
          liquidacion.isSuccess && (
            <div className="p-2">
              <h1>
                LIQUIDACIÓN {format(new Date(liquidacion.data.createdAt), "dd MMM yyyy")}
              </h1>
              <Row>
                <div className="border p-2 col-md-4 col-sm-12">
                  <h4>Detalle:</h4>

                  <div>
                    <b>Total: </b>
                    {liquidacion.data.total.toFixed(2)}€
                  </div>
                  <div>
                    <b>Comisión: </b>
                    {liquidacion.data.user.porcentaje_comision}%
                  </div>
                  <div>
                    <b>Importe Comisión: </b>
                    {liquidacion.data.comision.toFixed(2)}€
                  </div>
                  <div>
                    <b>Importe liquidado: </b>
                    {liquidacion.data.importe_final.toFixed(2)}€
                  </div>
                  <div>
                    <b>Vendedor: </b>
                    {liquidacion.data.user.username}
                  </div>
                  <div>
                    <b>Contabilizada: </b>
                    {liquidacion.data.contabilizada ? "Si" : "No"}
                  </div>
                  <div>
                    <b>Emisión: </b>
                    <div className="mx-3">
                      {format(new Date(liquidacion.data.createdAt), "📆 dd MMM yyyy")} <br />
                      {format(new Date(liquidacion.data.createdAt), "🕓 HH:mm:ss")}
                    </div>
                  </div>
                </div>
                {liquidacion.data.subliquidaciones.length &&
                  <div className="border p-2 col-md-4 col-sm-12 text-end">
                    <h4 className="text-start">Desglose: </h4>
                    {liquidacion.data.subliquidaciones.map((subliquidacion, index) =>
                      <div key={index}>
                        <div><b>{subliquidacion.formadepago}:</b></div>
                        <div>Ventas: {(subliquidacion.simple + subliquidacion.completa).toFixed(2)} €</div>
                        <div>Devoluciones: {(subliquidacion.rectificativa).toFixed(2)} €</div>
                        <div className="border-top">Total: {(subliquidacion.simple + subliquidacion.completa + subliquidacion.rectificativa).toFixed(2)} €</div>
                        <br />
                      </div>)}
                  </div>
                }
                {liquidacion.data.facturas.length &&
                  <div className="border p-2 col-md-4 col-sm-12">
                    <h4>Facturas: </h4>
                    <ul>
                      {liquidacion.data.facturas.map((factura, index) =>
                        <li key={index}>
                          {factura.formadepago.formadepago} {factura.importe.toFixed(2)}€ {factura.discount !== 0 ? `(-${factura.discount}%)` : ""} <Link to={`/factura/${factura.id}`}>{factura.serie}-{addZeros(factura.numero)}</Link>
                        </li>)}
                    </ul>
                  </div>
                }
                <div className="border p-2 col-lg-6 col-md-8 col-sm-12 d-flex flex-wrap">

                  <Bubutton
                    className="m-1 w-20 h-80 pt-3"
                    variant="primary"
                    href={`${process.env.REACT_APP_BACKEND_URL}/printliquidacion/${liquidacion.data.id}`}
                    text="Descargar"
                  >
                    <FaFilePdf />
                  </Bubutton>
                  <Bubutton
                    className="m-1 w-20 h-80"
                    variant="primary"
                    disabled={printerMode === "disabled"}
                    onClick={print}
                    text="Imprimir">
                    <FaPrint />
                  </Bubutton>
                  <Bubutton
                    className="m-1 w-20 h-80"
                    variant={liquidacion.data.contabilizada ? "danger" : "primary"}
                    disabled={userDetails.user.username !== "toni" || loading}
                    onClick={liquidacion.data.contabilizada ? doDesContabilizar : doContabilizar}
                    text={liquidacion.data.contabilizada ? "Des-Contabilizar" : "Contabilizar"}>
                    <FaBalanceScaleLeft hidden={loading} /><Spinner animation="border" size="sm" hidden={!loading} />
                  </Bubutton>
                </div>
              </Row>
            </div>
          )
        )}
      </Menu>
    </>
  );
}

export default Liquidacion;


const addZeros = (num) => {
  const zeros = new Array((4 - `${num}`.length) + 1).join("0")
  return `${zeros}${num}`
}