import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useAuthState } from "../Context";
import LoadingSpinner from "./LoadingSpinner";

const EmailTicketsModal = (props) => {
    const userDetails = useAuthState()
    const { booking, showEmailModal, setShowEmailModal } = props;
    const [email, setEmail] = useState("")
    const [sendingMailStatus, setSendingMailStatus] = useState("none")

    useEffect(() => {
        if (!showEmailModal) return
        console.log("EMAIL MODAL", booking)
        setEmail(booking.email)
        setSendingMailStatus("none")
    }, [showEmailModal]) // eslint-disable-line

    const closeEmailModal = () => {
        setShowEmailModal(false)
    }

    const sendEmail = async (booking) => {
        setSendingMailStatus("loading");
        const url = `${process.env.REACT_APP_BACKEND_URL}/sendEmail?id=${booking.id}&email=${email}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userDetails.token}`,
            },
        });
        if (response.status === 200) setSendingMailStatus("success");
        else setSendingMailStatus("fail")
    };

    return (
        <Modal show={showEmailModal} onHide={closeEmailModal}>
            <Modal.Header closeButton>
                <Modal.Title>Enviar tarjetas de embarque al e-mail:</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                ></input>
                {sendingMailStatus === "success" ? <p className="text-success">✔ Email enviado exitosamente.</p> : sendingMailStatus === "fail" ? <p className="text-danger">✖ No se ha podido enviar el email.</p> : sendingMailStatus === "loading" ? <LoadingSpinner /> : ""}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={closeEmailModal} variant="secondary">
                    Close
                </Button>
                <Button variant="danger" disabled={sendingMailStatus === "success"} onClick={() => sendEmail(booking)}>
                    Enviar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EmailTicketsModal;