
export const combinations = [
    {
        name: "Santa Eulallia",
        code: "STA",
        destinations: [
            {
                name: "Formentera",
                code: "FOR"
            },
            {
                name: "Ibiza",
                code: "IBZ"
            },
            {
                name: "Es Canar",
                code: "ESC"
            },
            {
                name: "Cala Llonga",
                code: "CLL"
            },
            {
                name: "Cala Pada",
                code: "CPD"
            },
            {
                name: "Es Figueral",
                code: "FIG"
            },
        ]
    },
    {
        name: "Es Canar",
        code: "ESC",
        destinations: [
            {
                name: "Formentera",
                code: "FOR"
            },
            {
                name: "Ibiza",
                code: "IBZ"
            },
            {
                name: "Santa Eulalia",
                code: "STA"
            },
            {
                name: "Cala Llonga",
                code: "CLL"
            },
            {
                name: "Cala Pada",
                code: "CPD"
            },
        ]
    },
    {
        name: "Cala Pada",
        code: "CPD",
        destinations: [
            {
                name: "Formentera",
                code: "FOR"
            },
            {
                name: "Ibiza",
                code: "IBZ"
            },
            {
                name: "Santa Eulalia",
                code: "STA"
            },
            {
                name: "Es Canar",
                code: "ESC"
            },
            {
                name: "Cala Llonga",
                code: "CLL"
            },
        ]
    },
    {
        name: "Cala Llonga",
        code: "CLL",
        destinations: [
            {
                name: "Formentera",
                code: "FOR"
            },
            {
                name: "Ibiza",
                code: "IBZ"
            },
            {
                name: "Santa Eulalia",
                code: "STA"
            },
            {
                name: "Es Canar",
                code: "ESC"
            },
            {
                name: "Cala Pada",
                code: "CPD"
            },
        ]
    },
    {
        name: "Ibiza",
        code: "IBZ",
        destinations: [
            {
                name: "Cala Llonga",
                code: "CLL"
            },
            {
                name: "Santa Eulalia",
                code: "STA"
            },
            {
                name: "Es Canar",
                code: "ESC"
            },
            {
                name: "Cala Pada",
                code: "CPD"
            },
            {
                name: "Es Figueral",
                code: "FIG"
            },
        ]
    },
    {
        name: "Formentera",
        code: "FOR",
        destinations: [
            {
                name: "Cala Llonga",
                code: "CLL"
            },
            {
                name: "Santa Eulalia",
                code: "STA"
            },
            {
                name: "Es Canar",
                code: "ESC"
            },
            {
                name: "Cala Pada",
                code: "CPD"
            },
            {
                name: "Es Figueral",
                code: "FIG"
            },
        ]
    },
    {
        name: "Es Figueral",
        code: "FIG",
        destinations: [
            {
                name: "Santa Eulalia",
                code: "STA"
            },
            {
                name: "Ibiza",
                code: "IBZ"
            },
            {
                name: "Formentera",
                code: "FOR"
            },
        ]
    },
]