import React from "react";
import { format } from "date-fns";
import { Button, Modal } from "react-bootstrap";
import { FaCalendarDay, FaDesktop, FaFilePdf, FaMailBulk, FaPrint, FaRegMoneyBillAlt, FaSignOutAlt, FaWhatsapp, FaTimesCircle } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { usePrinterContext, useTpvContext } from "../Context";
import Bubutton from "./Bubutton";

const BookingModal = (props) => {
    const { booking, showModal, doHideModal, doShowMailModal, doShowCancelModal, doShowRefundModal, doShowModificationModal, doShowWhatsAppTicketsModal } = props
    const { printer, printTicket, printerMode, btPrintTicket } = usePrinterContext();
    const { proxyPrintTickets } = useTpvContext()
    const history = useHistory();


    const printTickets = () => {
        if (!printerMode) return
        if (printerMode === "disabled") return
        if (printerMode === "tpv") proxyPrintTickets(booking)
        if (printerMode === "usb" && printer) printTicket(booking)
        if (printerMode === "bt") btPrintTicket(booking)
    }

    if (!booking) return <></>
    return (
        <Modal show={showModal} onHide={doHideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Reserva {booking.localizador} <Button disabled style={{ marginLeft: "1em", marginTop: "-5px" }} variant={booking.status === "confirmed" ? "success" : booking.status === "modified" ? "info" : booking.status === "failed" ? "secondary" : booking.status === "cancelled" ? "warning" : booking.status === "refunded" ? "danger" : "light"}>{booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}</Button></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {booking.firstName ? <div>{`👤 ${booking.firstName} ${booking.lastName}`}</div> : ""} {booking.country !== "NC" ? `🌍 ${booking.country}` : ""}
                {booking.email ? <div>📧 {booking.email}</div> : <></>} {booking.phone_number && <div>📱 {booking.phone_number}</div>}
                {booking.adults} adultos, {booking.children} niños, {booking.babies} bebés <br />
                <br />
                {booking.is_bono ? <>{`🚢 BONO ${booking.bono.type === "PLY" ? "PLAYAS" : "PLAYAS + IBIZA"} ${booking.bono.days} DÍAS ${booking.bono.formentera ? "+ FORMENTERA" : ""}`} <br /></> : ""}
                {booking.ida ? <>{`🚢 ${booking.ida.details.origin.name} - ${booking.ida.details.destination.name} @ ${format(new Date(booking.ida.date), "dd MMM yy")} ${booking.ida.details.open ? "OPEN" : booking.ida.details.departure.substring(0, 5)}`} <br /></> : ""}
                {booking.ida?.details?.stop?.length ?
                    <ul>
                        {booking.ida.details.stop.map(stop => {
                            return (
                                <li>
                                    {stop.shuttle ? stop.pickup_info["es"] :
                                        stop.swap ? `Trasbordo en ${stop.place.name} a las ${stop.time.substring(0, 5)}` :
                                            `Parada en ${stop.place.name} a las ${stop.time.substring(0, 5)}`}
                                </li>
                            )
                        })}
                    </ul> : ""}
                {booking.vuelta ? <>{`🚢 ${booking.vuelta.details.origin.name} - ${booking.vuelta.details.destination.name} @ ${format(new Date(booking.vuelta.date), "dd MMM yy")} ${booking.vuelta.details.open ? "OPEN" : booking.vuelta.details.departure.substring(0, 5)}`} <br /></> : ""}
                {booking.vuelta?.details?.stop?.length ?
                    <ul>
                        {booking.vuelta.details.stop.map(stop => {
                            return (
                                <li>
                                    {stop.shuttle ? stop.pickup_info["es"] :
                                        stop.swap ? `Trasbordo en ${stop.place.name} a las ${stop.time.substring(0, 5)}` :
                                            `Parada en ${stop.place.name} a las ${stop.time.substring(0, 5)}`}
                                </li>
                            )
                        })}
                    </ul> : ""}
                <br />
                Emisión: 📆 {format(new Date(booking.createdAt), "dd-MM-yyyy 🕓 HH:mm")} <br />
                <div className="d-flex">
                    {booking.facturas.length ? <div>Facturas:</div> : ""}
                    <div className="mx-2">{booking.facturas.map((factura, index) => (
                        <div key={index}>
                            <Link to={`/factura/${factura.id}`}>
                                {factura.serie}
                                {factura.numero}
                            </Link>
                            {` ${factura.formadepago.formadepago} ${factura.importe}€ ${factura.discount ? `(${factura.discount}% descuento)` : ""}`}
                        </div>
                    ))}
                    </div>
                </div>
                <br />
                {booking.checkinida ? (
                    <div>
                        {`Viaje de Ida Consumido por ${booking.checkinida.user.username} el ${format(
                            new Date(booking.checkinida.time),
                            "dd MMM 'a las' HH:mm"
                        )}`}
                    </div>
                ) : ""}
                {booking.checkinvuelta ? (
                    <div>
                        {`Viaje de Regreso Consumido por ${booking.checkinvuelta.user.username} el ${format(
                            new Date(booking.checkinvuelta.time),
                            "dd MMM 'a las' HH:mm"
                        )}`}
                    </div>
                ) : ""}
                {booking.bonocheckin.length ? `BONO-QR escaneado ${booking.bonocheckin.length} veces` : ""}
            </Modal.Body>
            <Modal.Footer className="d-flex w-100">
                <Bubutton
                    className="m-1 w-20 h-80"
                    disabled={booking.status !== "confirmed"}
                    onClick={doShowMailModal}
                    text="E-Mail"
                >
                    <FaMailBulk />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    disabled={booking.status !== "confirmed"}
                    onClick={doShowWhatsAppTicketsModal}
                    text="WhatsApp"
                >
                    <FaWhatsapp />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    disabled={booking.status !== "confirmed" && booking.status !== "cancelled" && booking.status !== "refunded"}
                    onClick={doShowModificationModal}
                    text="Modificar"
                >
                    <FaCalendarDay />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    disabled={booking.status !== "confirmed"}
                    onClick={doShowCancelModal}
                    text="Cancelar"
                >
                    <FaTimesCircle />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    disabled={booking.status !== "cancelled" && booking.status !== "confirmed"}
                    onClick={doShowRefundModal}
                    text="Devolución"
                >
                    <FaRegMoneyBillAlt />
                </Bubutton>
                <Bubutton
                    className="w-20 h-80 pt-3"
                    disabled={booking.status !== "confirmed"}
                    href={`${process.env.REACT_APP_BACKEND_URL}/boardingpass/${booking.id}?media=print`}
                    text="Tarjeta Print"
                >
                    <FaFilePdf />
                </Bubutton>
                <Bubutton
                    className="w-20 h-80 pt-3"
                    disabled={booking.status !== "confirmed"}
                    href={`${process.env.REACT_APP_BACKEND_URL}/boardingpass/${booking.id}?media=mobile`}
                    text="Tajeta Móvil"
                >
                    <FaFilePdf />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    variant="primary"
                    disabled={booking.status !== "confirmed" || printerMode === "disabled"}
                    onClick={printTickets}
                    text="Imprimir">
                    <FaPrint />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    variant="primary"
                    onClick={() => history.push(`/booking/${booking.id}`)}
                    text="Detalles">
                    <FaDesktop />
                </Bubutton>
                <Bubutton
                    className="m-1 w-20 h-80"
                    variant="secondary"
                    onClick={doHideModal}
                    text="Cerrar">
                    <FaSignOutAlt />
                </Bubutton>
            </Modal.Footer>
        </Modal>
    )
}

export default BookingModal;