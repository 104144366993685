import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { useTpvContext } from "../Context";
import { IoWarning } from "react-icons/io5";


const ComerciaLoading = ({ setLoading }) => {
    const { cancelComercia } = useTpvContext()
    const interrupt = () => {
        cancelComercia()
        setLoading(false)
    }
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                position: "fixed",
                overflow: "hidden",
                zIndex: "3000",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
        >
            <div style={{
                position: "fixed",
                backgroundColor: "white",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                border: "1px solid grey",
                borderRadius: "4px",
                padding: "1em 2em",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "2em"
            }}>
                <div style={{
                    display: "flex",
                    gap: "1em",
                    alignItems: "center",
                }}>
                    <Spinner
                        animation="border"
                        role="status"
                    ></Spinner>
                    Esperando tpv...
                </div>
                <div>
                    <Button variant="warning" onClick={interrupt}>Interrumpir</Button>
                </div>
                <div className="d-flex align-items-center justify-content-center text-warning">
                    <IoWarning className="me-2" /> No interrumpir si el cliente ya ha pasado la tarjeta.
                </div>
            </div>
        </div>
    )
}

export default ComerciaLoading