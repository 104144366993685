import React from "react";
import { useParams, Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import { useAuthState } from "../../Context";
import { Spinner, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import axios from "axios";
import { format } from "date-fns";

function Transaction() {
  const userDetails = useAuthState();
  const id = useParams().id;

  const fetchTransaction = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/transactions/${id}`;
    const { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    });

    console.log("transaction", data)
    return data
  };

  const transaction = useQuery(['transaction', id], fetchTransaction)

  return (
    <>
      <Menu>
        {(transaction.isLoading) ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              position: "fixed",
              overflow: "hidden",
              zIndex: "3000",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
              }}
            ></Spinner>
          </div>
        ) : (
          transaction.isSuccess && (
            <div className="p-2">
              <h1>
                TRANSACTION {" "}
                {transaction.data.type} - {transaction.data.transaction_id}
              </h1>
              <Row>
                <div className="border p-2 col-lg-3 col-md-12 col-sm-12">
                  <h4>Detalle:</h4>

                  <div>
                    <b>ID:</b>
                    {transaction.data.transaction_id}
                  </div>
                  <div>
                    <b>TYPE:</b>
                    {transaction.data.type}
                  </div>
                  <div>
                    <b>Emisión: </b>
                    <div className="mx-3">
                      {format(new Date(transaction.data.createdAt), "📆 dd MMM yyyy")} <br />
                      {format(new Date(transaction.data.createdAt), "🕓 HH:mm:ss")}
                    </div>
                  </div>

                  {transaction.data.factura &&
                    <div>
                      <b>Factura: </b>
                      <Link to={`/factura/${transaction.data.factura.id}`}>{transaction.data.factura.serie}-{addZeros(transaction.data.factura.numero)}</Link>
                    </div>}
                  {transaction.data.booking &&
                    <div>
                      <b>Reserva: </b>
                      <Link to={`/booking/${transaction.data.booking.id}`}>Booking {transaction.data.booking.localizador}</Link>
                    </div>}
                </div>

                <div className="border p-2 col-lg-9 col-md-12 col-sm-12">
                  <h4>Transacción:</h4>
                  <pre>{JSON.stringify(transaction.data.transaction, null, 2)}</pre>
                </div>
              </Row>
            </div>
          )
        )}
      </Menu>
    </>
  );
}

export default Transaction;


const addZeros = (num) => {
  const zeros = new Array((4 - `${num}`.length) + 1).join("0")
  return `${zeros}${num}`
}